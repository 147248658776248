import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RegistrationService } from '../../services/registration.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-device-registered-indicator',
    templateUrl: './device-registered-indicator.component.html',
    styleUrls: ['./device-registered-indicator.component.scss'],
    standalone: true,
    imports: [CommonModule, MatIconModule],
})
export class DeviceRegisteredIndicatorComponent implements OnInit {
    public isRegistered: boolean = false;
    private registrationSub!: Subscription;
    private allSubs: Subscription[] = [];

    constructor(private registrationService: RegistrationService) {}

    async ngOnInit(): Promise<void> {
        // Abonniere den Status, sobald dieser verfügbar ist
        this.registrationSub = this.registrationService.registrationStatus$.subscribe((status) => {
            this.isRegistered = status;
        });

        this.allSubs.push(this.registrationSub);
    }

    /**
     * Cleans up subscriptions when the component is destroyed.
     */
    public ngOnDestroy(): void {
        this.allSubs.forEach((s: Subscription) => {
            s.unsubscribe();
        });
        this.allSubs = [];
    }
}
