import { VolumeViewport } from '@cornerstonejs/core';
function isVolumeSegmentation(operationData, viewport) {
    const { imageIdReferenceMap } = operationData;
    const { volumeId } = operationData;
    if (volumeId && !imageIdReferenceMap) {
        return true;
    }
    if (imageIdReferenceMap && !volumeId) {
        return false;
    }
    if (volumeId && imageIdReferenceMap && !viewport) {
        throw new Error('isVolumeSegmentation: viewport is required when both volumeId and imageIdReferenceMap are provided');
    }
    return viewport instanceof VolumeViewport;
}
export { isVolumeSegmentation };
