<div class="mat-elevation-z8">
    <table
        [dataSource]="dataSource"
        class="mat-elevation-z8"
        mat-table
        matSort
        multiTemplateDataRows
    >
        @for (column of displayedColumns; track column) {
            <ng-container matColumnDef="{{ column }}">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ "COMPONENT.PAGE_ORGA." + column | translate }}
                </th>

                <td mat-cell *matCellDef="let element" class="table-text">
                    <ng-container [ngSwitch]="column">
                        <ng-container *ngSwitchCase="'table_header_basic_info'">
                            <!-- <span
                                (click)="onNameClick(element.id)"
                                [ngClass]="{
                                    'column-fullname-pointer ': isWorkflowRoute
                                }"
                            >
                                <strong>
                                    {{ element[column].split(",")[0] }},
                                    {{ element[column].split(",")[1] }}</strong
                                >, {{ element[column].split(",")[2] }},{{
                                    element[column].split(",")[3]
                                }}</span
                            > -->
                            <!--<app-home-patient-basic-info
                                class="patient-list-basic"
                                [patient]="element[column]"
                                [disableDiagnosis]="true"
                            ></app-home-patient-basic-info>-->
                        </ng-container>
                        <ng-container *ngSwitchCase="'description'">
                        <span
                            (click)="
                                onClickOnElement('form_comments.json', [element])
                            "
                            class="column-description"
                        >
                            {{ element[column] }}</span
                        >
                        </ng-container>
                        <ng-container *ngSwitchCase="'dob'">
                            {{
                                element[column]
                                    | date : ("GENERAL.date_format" | translate)
                            }}{{
                                column == "dob"
                                    ? "(" + (element[column] | calAge) + ")"
                                    : ""
                            }}
                        </ng-container>

                        <ng-container *ngSwitchCase="'gender'">
                            {{ "GENERAL." + element[column] | translate }}
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            {{ element[column] }}
                        </ng-container>
                    </ng-container>
                </td>
            </ng-container>
        }

        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr
            *matRowDef="let row; columns: displayedColumns; let i = index"
            mat-row
        ></tr>
    </table>
</div>
