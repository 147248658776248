import { AccessFacadeService } from './../../../services/facades/access-facade.service';
import { Cp2ApiService } from './../../../services/cp2-api.service';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
    Case_Overview,
    getNewViewContent,
    VC_CommunicationMessage,
} from '../../../models/view-content.models/view-content.model';
import { CommonModule } from '@angular/common';
import { FormioForm } from '../../../models/formio.model';
import { firstValueFrom } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CP2_User } from '../../../models/view-content.models/view-content-personal-domain.model';
import { CommunicationMessage } from '../../../models/communication.model';
import dayjs from 'dayjs';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import {
    ConversationTreeNode,
    ConversationTreeNodeComponent,
} from './conversation-tree-node/conversation-tree-node.component';
import { CommunicationMessageModalComponent } from '../../../modals/communication-message-modal/communication-message-modal.component';
import { User } from '../../../models/cp2-api.model';
import { v4 as uuidv4 } from 'uuid';

@Component({
    selector: 'app-communication',
    templateUrl: './communication.component.html',
    styleUrls: ['./communication.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        MatExpansionModule,
        ConversationTreeNodeComponent,
    ],
})
export class CommunicationComponent implements OnChanges {
    @Input() caseOverview: Case_Overview | undefined;
    @Input() messages: VC_CommunicationMessage[] = [];
    @Input() user: CP2_User | undefined;

    @Output() sendMessage = new EventEmitter<VC_CommunicationMessage>();

    public conversationTrees: ConversationTreeNode[] = [];
    public startConversationForm: FormioForm | undefined;

    public constructor(
        private accessFacade: AccessFacadeService,
        private api: Cp2ApiService,
        public dialog: MatDialog
    ) {}

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes['messages']) {
            this.buildConversationTrees();
        }
    }

    //#region Listeners
    public async onClickOnSend(): Promise<void> {
        if (!this.caseOverview) return;
        if (!this.user) return;

        const recipientList = await this.getRecipientList();
        const dialogConfig: MatDialogConfig = {
            data: {
                form_file_name: 'form_communication_message.json',
                recipientList,
            },
            width: '80%',
        };
        const dialogRef = this.dialog.open(CommunicationMessageModalComponent, dialogConfig);
        const res = await firstValueFrom(dialogRef.afterClosed());

        if (!res || res.role === 'cancel') return;

        const data = {} as any;
        res.data.forEach((e: { key: string; value: string }) => (data[e.key] = e.value));
        data.done = !!data.done;
        data.goalDateOn = dayjs(data['goalDateOn']).toISOString();
        data.editor = this.user.id;
        const c = this.caseOverview.case;
        const vc = getNewViewContent<CommunicationMessage>({
            id: -1,
            locator: `case.communication.tasks.${c.related_case_id}.${uuidv4()}`,
            related_patient_id: c.related_patient_id,
            related_case_id: c.related_case_id,
            data,
            owners: [],
            created_at: dayjs().toISOString(),
        });

        this.sendMessage.emit(vc);
    }

    public async onClickOnRespondMessage($event: ConversationTreeNode): Promise<void> {
        if (!this.caseOverview) return;
        if (!this.user) return;

        const recipientId = $event.m.data.editor.user_id;
        const recipientList = await this.getRecipientList();
        const recipient = recipientList.find((e) => e.user_id == recipientId);
        const dialogConfig: MatDialogConfig = {
            data: {
                form_file_name: 'form_communication_response.json',
                data: {},
                answersTo: $event.m,
                recipientList: [recipient],
            },
            width: '80%',
        };
        const dialogRef = this.dialog.open(CommunicationMessageModalComponent, dialogConfig);
        const res = await firstValueFrom(dialogRef.afterClosed());

        if (!res || res.role === 'cancel') return;

        const data = {} as any;
        res.data.forEach((e: { key: string; value: string }) => (data[e.key] = e.value));
        data.goalDateOn = dayjs(data['goalDateOn']).format('YYYY-MM-DD');
        data.done = !!data['done'];
        data.editor = this.user.uuid;
        data.answersTo = $event.m.locator;
        const c = this.caseOverview.case;
        const vc = getNewViewContent<CommunicationMessage>({
            id: -1,
            locator: `case.communication.tasks.${c.related_case_id}.${uuidv4()}`,
            related_patient_id: c.related_patient_id,
            related_case_id: c.related_case_id,
            data,
            owners: [],
        });

        this.sendMessage.emit(vc);
    }
    //#endregion

    public isOwnMessage(m: VC_CommunicationMessage): boolean {
        return m.data.editor?.toString() == this.user?.id.toString();
    }

    private buildConversationTrees() {
        const masterNodes =
            this.messages
                .filter((e) => !e.data.answersTo)
                .map<ConversationTreeNode>((e) => ({ m: e, responses: [] }))
                .sort((e1, e2) => e2.m.created_at?.localeCompare(e1.m.created_at)) ?? 0;
        masterNodes.forEach((e) => this.buildTree(e, this.messages));
        this.conversationTrees = masterNodes;
    }

    private buildTree(e: ConversationTreeNode, messages: VC_CommunicationMessage[]): void {
        const responses = messages
            .filter((msg) => msg.data.answersTo === e.m.id.toString())
            .map<ConversationTreeNode>((msg) => ({ m: msg, responses: [] }));
        e.responses = responses;

        e.responses.forEach((r) => this.buildTree(r, this.messages));
    }

    private async getRecipientList(): Promise<User[]> {
        const userToken = await firstValueFrom(this.accessFacade.userTokens$);
        const token = userToken.token?.access_token;
        if (!token) return [];

        const res = await this.api.getAllUsersPage(token);
        return res.results;
    }
}
