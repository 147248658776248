<div *ngIf="patient" class="lbFlex lbCol patient-basic-info">
    <!-- Patient Name, gender, birth date and age -->
    <div matLine>
        <span class="patient-basic-info-name table-name">
            {{ patient?.patient_details?.patient?.data?.surname }},
            {{ patient?.patient_details?.patient?.data?.name }},
        </span>

        <span class="table-text">
            {{
                "GENERAL." + patient?.patient_details?.patient?.data?.gender
                    | translate
            }},
            {{
                patient?.patient_details?.patient?.data?.birth_date
                    | date : ("GENERAL.date_format" | translate)
            }}
            ({{ patient?.patient_details?.patient?.data?.birth_date | calAge }})
        </span>
    </div>

    <!-- Station, Room, Bed, Admission date and discharge Date -->
    <div>
        <span class="table-text">
            <span class="patient-basic-info-station-room">
                {{ patient?.patient_details?.current_place?.data?.room?.service_unit?.short_text ?? '' }}
                {{ "COMPONENT.PAGE_PATIENT_LIST.room" | translate }}
                {{ patient?.patient_details?.current_place?.data?.room?.long_text ?? '' }}
                {{ patient?.patient_details?.current_place?.data?.shortName }}
            </span>
            A:
            {{
                patient?.patient_details?.case?.data?.admission_date
                    | date : ("GENERAL.date_format" | translate)
            }}&nbsp;({{
                patient?.patient_details?.case?.data?.admission_date | dayAgo
            }}),
            {{
                "COMPONENT.PAGE_PATIENT_LIST.planned_discharge_date"
                    | translate
            }}:
            {{
                patient?.patient_details?.case?.data?.discharge_date
                    | date : ("GENERAL.date_format" | translate)
            }}
            ({{
                patient?.patient_details?.case?.data?.planned_discharge_date
                    | dayAgo
            }})
        </span>
    </div>

    <!-- Patient Diagnosis -->
    <div *ngIf="!disableDiagnosis" class="table-subtext">
        <ng-container>
            {{ patient?.patient_details?.last_diagnosis?.data?.diagnose }}
        </ng-container>
    </div>
</div>
