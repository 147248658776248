import { utilities } from '@cornerstonejs/core';
import { config as segmentationConfig, state as segmentationState, segmentLocking, segmentIndex as segmentIndexController, activeSegmentation, } from '../../stateManagement/segmentation';
import { SegmentationRepresentations } from '../../enums';
import ContourBaseTool from './ContourBaseTool';
import { triggerSegmentationDataModified } from '../../stateManagement/segmentation/triggerSegmentationEvents';
import { InterpolationManager } from '../../utilities/contours/interpolation';
import { addContourSegmentationAnnotation, removeContourSegmentationAnnotation, } from '../../utilities/contourSegmentation';
import { getToolGroupIdsWithSegmentation } from '../../stateManagement/segmentation/segmentationState';
import { triggerAnnotationRenderForToolGroupIds } from '../../utilities';
class ContourSegmentationBaseTool extends ContourBaseTool {
    constructor(toolProps, defaultToolProps) {
        super(toolProps, defaultToolProps);
        if (this.configuration.interpolation?.enabled) {
            InterpolationManager.addTool(this.getToolName());
        }
    }
    isContourSegmentationTool() {
        return true;
    }
    createAnnotation(evt) {
        const { toolGroupId } = this;
        const contourAnnotation = super.createAnnotation(evt);
        if (!this.isContourSegmentationTool()) {
            return contourAnnotation;
        }
        const activeSegmentationRepresentation = activeSegmentation.getActiveSegmentationRepresentation(toolGroupId);
        if (!activeSegmentationRepresentation) {
            throw new Error('No active segmentation detected, create one before using scissors tool');
        }
        const { type: segmentationType } = activeSegmentationRepresentation;
        if (segmentationType !== SegmentationRepresentations.Contour) {
            throw new Error(`A contour segmentation must be active`);
        }
        const { segmentationId } = activeSegmentationRepresentation;
        const segmentIndex = segmentIndexController.getActiveSegmentIndex(segmentationId);
        return utilities.deepMerge(contourAnnotation, {
            data: {
                segmentation: {
                    segmentationId,
                    segmentIndex,
                },
            },
        });
    }
    addAnnotation(annotation, element) {
        const annotationUID = super.addAnnotation(annotation, element);
        if (this.isContourSegmentationTool()) {
            const contourSegAnnotation = annotation;
            addContourSegmentationAnnotation(contourSegAnnotation);
        }
        return annotationUID;
    }
    cancelAnnotation(annotation) {
        if (this.isContourSegmentationTool()) {
            removeContourSegmentationAnnotation(annotation);
        }
        super.cancelAnnotation(annotation);
    }
    getAnnotationStyle(context) {
        const annotationStyle = super.getAnnotationStyle(context);
        if (!this.isContourSegmentationTool()) {
            return annotationStyle;
        }
        const contourSegmentationStyle = this._getContourSegmentationStyle(context);
        return utilities.deepMerge(annotationStyle, contourSegmentationStyle);
    }
    renderAnnotationInstance(renderContext) {
        const { annotation } = renderContext;
        const { invalidated } = annotation;
        const renderResult = super.renderAnnotationInstance(renderContext);
        if (invalidated && this.isContourSegmentationTool()) {
            const { segmentationId } = (annotation).data.segmentation;
            triggerSegmentationDataModified(segmentationId);
            const toolGroupIds = getToolGroupIdsWithSegmentation(segmentationId);
            triggerAnnotationRenderForToolGroupIds(toolGroupIds);
        }
        return renderResult;
    }
    _getContourSegmentationStyle(context) {
        const { toolGroupId } = this;
        const annotation = context.annotation;
        const { segmentationId, segmentIndex } = annotation.data.segmentation;
        const segmentation = segmentationState.getSegmentation(segmentationId);
        const segmentationRepresentation = this._getSegmentationRepresentation(segmentationId);
        if (!segmentationRepresentation) {
            return {};
        }
        const { segmentationRepresentationUID } = segmentationRepresentation;
        const { active } = segmentationRepresentation;
        const { autoGenerated } = annotation;
        const segmentsLocked = segmentLocking.getLockedSegments(segmentationId);
        const annotationLocked = segmentsLocked.includes(segmentIndex);
        const segmentColor = segmentationConfig.color.getColorForSegmentIndex(toolGroupId, segmentationRepresentationUID, segmentIndex);
        const segmentationVisible = segmentationConfig.visibility.getSegmentationVisibility(toolGroupId, segmentationRepresentationUID);
        const globalConfig = segmentationConfig.getGlobalConfig();
        const toolGroupConfig = segmentationConfig.getToolGroupSpecificConfig(toolGroupId);
        const segmentationRepresentationConfig = segmentationConfig.getSegmentationRepresentationSpecificConfig(toolGroupId, segmentationRepresentationUID);
        const segmentConfig = segmentationConfig.getSegmentSpecificConfig(toolGroupId, segmentationRepresentationUID, segmentIndex);
        const segmentVisible = segmentationConfig.visibility.getSegmentVisibility(toolGroupId, segmentationRepresentationUID, segmentIndex);
        const mergedConfig = Object.assign({}, globalConfig?.representations?.CONTOUR ?? {}, toolGroupConfig?.representations?.CONTOUR ?? {}, segmentationRepresentationConfig?.CONTOUR ?? {}, segmentConfig?.CONTOUR ?? {});
        let lineWidth = 1;
        let lineDash = undefined;
        let lineOpacity = 1;
        let fillOpacity = 0;
        if (autoGenerated) {
            lineWidth = mergedConfig.outlineWidthAutoGenerated ?? lineWidth;
            lineDash = mergedConfig.outlineDashAutoGenerated ?? lineDash;
            lineOpacity = mergedConfig.outlineOpacity ?? lineOpacity;
            fillOpacity = mergedConfig.fillAlphaAutoGenerated ?? fillOpacity;
        }
        else if (active) {
            lineWidth = mergedConfig.outlineWidthActive ?? lineWidth;
            lineDash = mergedConfig.outlineDashActive ?? lineDash;
            lineOpacity = mergedConfig.outlineOpacity ?? lineOpacity;
            fillOpacity = mergedConfig.fillAlpha ?? fillOpacity;
        }
        else {
            lineWidth = mergedConfig.outlineWidthInactive ?? lineWidth;
            lineDash = mergedConfig.outlineDashInactive ?? lineDash;
            lineOpacity = mergedConfig.outlineOpacityInactive ?? lineOpacity;
            fillOpacity = mergedConfig.fillAlphaInactive ?? fillOpacity;
        }
        if (segmentation.activeSegmentIndex === segmentIndex) {
            lineWidth += mergedConfig.activeSegmentOutlineWidthDelta;
        }
        lineWidth = mergedConfig.renderOutline ? lineWidth : 0;
        fillOpacity = mergedConfig.renderFill ? fillOpacity : 0;
        const color = `rgba(${segmentColor[0]}, ${segmentColor[1]}, ${segmentColor[2]}, ${lineOpacity})`;
        const fillColor = `rgb(${segmentColor[0]}, ${segmentColor[1]}, ${segmentColor[2]})`;
        return {
            color,
            fillColor,
            lineWidth,
            fillOpacity,
            lineDash,
            textbox: {
                color,
            },
            visibility: segmentationVisible && segmentVisible,
            locked: annotationLocked,
        };
    }
    _getSegmentationRepresentation(segmentationId) {
        const segmentationRepresentations = segmentationState.getSegmentationRepresentations(this.toolGroupId);
        const validSegmentationRepresentations = segmentationRepresentations.filter((representation) => representation.segmentationId === segmentationId);
        if (!validSegmentationRepresentations) {
            console.warn(`No segmentation representation found for toolGroupId: ${this.toolGroupId}`);
            return;
        }
        if (segmentationState.getSegmentationRepresentations(this.toolGroupId)
            .length > 1) {
            console.warn('Multiple segmentation representations detected for this tool group. The first one will be used.');
        }
        return validSegmentationRepresentations[0];
    }
}
export { ContourSegmentationBaseTool as default, ContourSegmentationBaseTool };
