import { metaData } from '@cornerstonejs/core';

declare const cornerstoneDICOMImageLoader: any;

/**
 * preloads imageIds metadata in memory
 **/
async function prefetchMetadataInformation(imageIdsToPrefetch: any) {
    for (let i = 0; i < imageIdsToPrefetch.length; i++) {
        await cornerstoneDICOMImageLoader.wadouri.loadImage(
            imageIdsToPrefetch[i]
        ).promise;
    }
}
function getFrameInformation(imageId: any) {
    if (imageId.includes('wadors:')) {
        const frameIndex = imageId.indexOf('/frames/');
        const imageIdFrameless =
            frameIndex > 0 ? imageId.slice(0, frameIndex + 8) : imageId;
        return {
            frameIndex,
            imageIdFrameless,
        };
    } else {
        const frameIndex = imageId.indexOf('&frame=');
        let imageIdFrameless =
            frameIndex > 0 ? imageId.slice(0, frameIndex + 7) : imageId;
        if (!imageIdFrameless.includes('&frame=')) {
            imageIdFrameless = imageIdFrameless + '&frame=';
        }
        return {
            frameIndex,
            imageIdFrameless,
        };
    }
}

function convertMultiframeImageIds(imageIds: any) {
    const newImageIds: any[] = [];
    imageIds.forEach((imageId: any) => {
        const { imageIdFrameless } = getFrameInformation(imageId);
        const instanceMetaData = metaData?.get('multiframeModule', imageId);
        if (
            instanceMetaData &&
            instanceMetaData.NumberOfFrames &&
            instanceMetaData.NumberOfFrames > 1
        ) {
            const NumberOfFrames = instanceMetaData.NumberOfFrames;
            for (let i = 0; i < NumberOfFrames; i++) {
                const newImageId = imageIdFrameless + (i + 1);
                newImageIds.push(newImageId);
            }
        } else {
            newImageIds.push(imageId);
        }
    });
    return newImageIds;
}

export { convertMultiframeImageIds, prefetchMetadataInformation };
