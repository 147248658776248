import { getEnabledElementByIds, } from '@cornerstonejs/core';
import Representations from '../../../enums/SegmentationRepresentations';
import * as SegmentationState from '../../../stateManagement/segmentation/segmentationState';
import { getToolGroup } from '../../../store/ToolGroupManager';
import removeContourFromElement from './removeContourFromElement';
import { deleteConfigCache } from './contourHandler/contourConfigCache';
import { polySeg } from '../../../stateManagement/segmentation';
import { handleContourSegmentation } from './contourHandler/handleContourSegmentation';
let polySegConversionInProgress = false;
function removeSegmentationRepresentation(toolGroupId, segmentationRepresentationUID, renderImmediate = false) {
    _removeContourFromToolGroupViewports(toolGroupId, segmentationRepresentationUID);
    SegmentationState.removeSegmentationRepresentation(toolGroupId, segmentationRepresentationUID);
    deleteConfigCache(segmentationRepresentationUID);
    if (renderImmediate) {
        const viewportsInfo = getToolGroup(toolGroupId).getViewportsInfo();
        viewportsInfo.forEach(({ viewportId, renderingEngineId }) => {
            const enabledElement = getEnabledElementByIds(viewportId, renderingEngineId);
            enabledElement.viewport.render();
        });
    }
}
async function render(viewport, representationConfig, toolGroupConfig) {
    const { segmentationId } = representationConfig;
    const segmentation = SegmentationState.getSegmentation(segmentationId);
    if (!segmentation) {
        return;
    }
    let contourData = segmentation.representationData[Representations.Contour];
    if (!contourData &&
        polySeg.canComputeRequestedRepresentation(representationConfig.segmentationRepresentationUID) &&
        !polySegConversionInProgress) {
        polySegConversionInProgress = true;
        contourData = await polySeg.computeAndAddContourRepresentation(segmentationId, {
            segmentationRepresentationUID: representationConfig.segmentationRepresentationUID,
            viewport,
        });
    }
    if (!contourData) {
        return;
    }
    if (contourData?.geometryIds?.length) {
        handleContourSegmentation(viewport, contourData.geometryIds, contourData.annotationUIDsMap, representationConfig, toolGroupConfig);
    }
}
function _removeContourFromToolGroupViewports(toolGroupId, segmentationRepresentationUID) {
    const toolGroup = getToolGroup(toolGroupId);
    if (toolGroup === undefined) {
        throw new Error(`ToolGroup with ToolGroupId ${toolGroupId} does not exist`);
    }
    const { viewportsInfo } = toolGroup;
    for (const viewportInfo of viewportsInfo) {
        const { viewportId, renderingEngineId } = viewportInfo;
        const enabledElement = getEnabledElementByIds(viewportId, renderingEngineId);
        removeContourFromElement(segmentationRepresentationUID, toolGroupId);
    }
}
export default {
    render,
    removeSegmentationRepresentation,
};
