import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LabResult } from '../../../models/view-content.models/view-content-clinic-domain.model';
import { CommonModule } from '@angular/common';
import dayjs from 'dayjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatChipsModule } from '@angular/material/chips';
import { StringReplacePipe } from '../../../pipes/string-replace.pipe';
import { MatTooltip } from '@angular/material/tooltip';

interface TableData {
    groups: TableGroup[];
}

interface TableGroup {
    label: string;
    text_short: string;
    icon_name: string;
    sort_order: number;
    color: string;
    rows: TableRow[];
}

interface TableRow {
    label: string;
    code: string;
    long_text: string;
    limits: string;
    isImportant: boolean;
    unit: string;
    cells: TableCell[];
}

interface TableCell {
    observation_date_time: string;
    value?: string;
    limits: {
        maxValue?: number;
        minValue?: number;
    };
}

@Component({
    selector: 'app-lab-table-viewer',
    templateUrl: './lab-table-viewer.component.html',
    styleUrls: ['./lab-table-viewer.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatChipsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatToolbarModule,
        StringReplacePipe,
        MatTooltip,
    ],
})
export class LabTableViewerComponent implements OnInit {
    @Input() labResults: LabResult[] = [];
    @Input() showLimits = true;
    @Input() showLimitsChange = new EventEmitter<boolean>();
    @Input() showLimitsToggleButton = false;
    @Input() numberOfColumnsToShow = 5;

    @Output() clickOnFullScreen = new EventEmitter<void>();

    public tableData: TableData = { groups: [] };
    public datesArray: string[] = [];
    public lowerIndexToShow = 0;
    public datesArrayToShow: string[] = [];
    public filterOption: 'all' | 'important' | string = 'all';
    public referenceDate = dayjs().toISOString().substring(0, 10);

    public constructor() {}

    public ngOnInit() {
        this.refresh();
    }

    //#region Listeners
    public onClickOnShowLimitsToggle(): void {
        this.showLimits = !this.showLimits;
        this.showLimitsChange.emit(this.showLimits);
    }

    public onClickOnMoveIndex(n: number): void {
        this.lowerIndexToShow += n;
        // Keep current index into the limits of a valid index of this.datesArray
        if (this.lowerIndexToShow < 0) this.lowerIndexToShow = 0;
        if (this.lowerIndexToShow + this.numberOfColumnsToShow > this.datesArray.length)
            this.lowerIndexToShow = this.datesArray.length - this.numberOfColumnsToShow;
        this.refresh();
    }

    public onClickOnResetIndex(): void {
        this.lowerIndexToShow = 0;
        this.refresh();
    }

    //#endregion

    public getClassesForCell(cell: TableCell): string | undefined {
        if (!cell.value) return;

        const cleanValue = cell.value?.trim().replaceAll(',', '.');
        const parsedValue = Number.parseFloat(cell.value);

        // Most common scenario: the value is a parseable float
        if (!isNaN(parsedValue)) {
            if (cell.limits.minValue != null && parsedValue < cell.limits.minValue) return 'color-below-limit';
            if (cell.limits.maxValue != null && parsedValue > cell.limits.maxValue) return 'color-above-limit';
        }

        // Other scenario: the value is an estimation like "<0.005" or ">10.0"
        if (cleanValue.charAt(0) === '<') {
            const n = Number.parseFloat(cleanValue.substring(1));
            if (cell.limits.minValue != null && n < cell.limits.minValue) return 'color-below-limit';
        }

        if (cleanValue.charAt(0) === '>') {
            const n = Number.parseFloat(cleanValue.substring(1));
            if (cell.limits.minValue != null && n > cell.limits.minValue) return 'color-above-limit';
        }

        return undefined;
    }

    /** Will determine whether to show or hide a group depending on the selected filter and whether group is empty */
    public isShowGroup(group: TableGroup): boolean {
        if (group.rows.every((r) => this.isEmptyRow(r))) return false;

        // TODO: Filter the important
        if (this.filterOption === 'all' || this.filterOption === 'important') return true;

        if (this.filterOption === group.label) return true;

        return false;
    }

    public isEmptyRow(r: TableRow): boolean {
        return !r.cells.some((c) => c.value);
    }

    private refresh(): void {
        // Build table data
        this.datesArray = this.labResults
            .map((e) => dayjs(e.observation_date_time).toISOString())
            .sort((d1, d2) => d1.localeCompare(d2));

        const l = this.datesArray.length;
        const lastIndex = l - this.lowerIndexToShow >= 0 ? l - this.lowerIndexToShow : 0;
        const firstIndex = lastIndex - this.numberOfColumnsToShow >= 0 ? lastIndex - this.numberOfColumnsToShow : 0;
        this.datesArrayToShow = this.datesArray.slice(firstIndex, lastIndex);

        this.tableData = { groups: [] };

        // Build the table without values
        for (const result of this.labResults) {
            for (const group of result.groups) {
                const existingGroup = this.tableData.groups.find((e) => e.label === group.text_long);

                const rows: TableRow[] = group.values.map((v) => ({
                    label: v.method.short_text,
                    code: v.method.code,
                    long_text: v.method.long_text,
                    limits: v.limits,
                    isImportant: true, // TODO: implement this
                    unit: v.unit.code,
                    // For each row build an array
                    cells: this.datesArrayToShow.map((date) => ({
                        observation_date_time: date,
                        limits: this.parseLimits(v.limits),
                    })),
                }));

                if (existingGroup) {
                    const missingRows = rows.filter((e) => !existingGroup.rows.some((r) => r.code === e.code));
                    existingGroup.rows = existingGroup.rows.concat(...missingRows);
                } else {
                    const tableGroup: TableGroup = {
                        label: group.text_long,
                        text_short: group.text_short,
                        icon_name: group.icon_name,
                        sort_order: group.sort_order,
                        color: group.color,
                        rows,
                    };
                    this.tableData.groups.push(tableGroup);
                }
            }
        }

        // Fill the values in the table
        for (const g of this.tableData.groups) {
            for (const r of g.rows) {
                for (const c of r.cells) {
                    c.value = this.getValueForCell(g, r, c);
                }
            }
        }
    }

    private getValueForCell(g: TableGroup, r: TableRow, c: TableCell): string | undefined {
        const result = this.labResults.find(
            (e) => dayjs(e.observation_date_time).toISOString() === c.observation_date_time
        );
        const group = result?.groups.find((e) => e.text_long === g.label);
        return group?.values.find((e) => e.method.code === r.code)?.value;
    }

    private parseLimits(limits: string): {
        maxValue?: number;
        minValue?: number;
    } {
        const fixedLimits = limits.trim().replaceAll(',', '.');
        let max: number = NaN;
        let min: number = NaN;

        const oneLimit = /([<>])\s*(-?\d+(\.\d+)?)/.exec(fixedLimits);
        if (oneLimit) {
            // oneLimit[1] is the "<" or ">", oneLimit[2] is the number
            const operator = oneLimit[1];
            const value = parseFloat(oneLimit[2]);

            if (operator === '<') {
                max = value; // It's an upper limit
            } else if (operator === '>') {
                min = value; // It's a lower limit
            }
        } else {
            const l = fixedLimits.split(' - ');
            min = Number.parseFloat(l[0]);
            max = Number.parseFloat(l[1]);
        }

        return {
            minValue: isNaN(min) ? undefined : min,
            maxValue: isNaN(max) ? undefined : max,
        };
    }
}
