import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stringReplace',
    standalone: true,
})
export class StringReplacePipe implements PipeTransform {
    transform(value: string | undefined, ...args: string[]): string {
        if (!value) return '';

        return value.replaceAll(args[0], args[1]);
    }
}
