import { Component, Input, OnInit } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { CommonModule } from '@angular/common';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { CalAgePipe } from '../../../pipes/cal-age.pipe';
import { DayAgoPipe } from '../../../pipes/day-ago.pipe';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ShowGenderPipe } from '../../../pipes/show-gender.pipe';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { TranslateModule } from '@ngx-translate/core';
import { VcPatientListItem } from '../../../models/view-content.models/view-content.model';

dayjs.extend(utc);

@Component({
    selector: 'app-home-patient-basic-info',
    templateUrl: './home-patient-basic-info.component.html',
    styleUrls: ['./home-patient-basic-info.component.scss'],
    standalone: true,
    imports: [
        CalAgePipe,
        CommonModule,
        DayAgoPipe,
        FormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIcon,
        MatIconModule,
        MatListModule,
        MatListModule,
        ShowGenderPipe,
        TranslateModule,
    ],
})
export class HomePatientBasicInfoComponent implements OnInit {
    @Input() patient: VcPatientListItem | undefined;
    @Input() disableDiagnosis = false;

    constructor() {}

    ngOnInit(): void {
    }
}
