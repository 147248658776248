<div class="flex flex-col h-full w-full">
    <!-- Patient Details -->
    <div>
        <!-- Breadcrumbs -->
        <app-patient-info-details [vcPatientDetails]="vcPatientDetails"></app-patient-info-details>
    </div>

    <!-- Tabs and tab contents -->
    <div class="flex-grow flex flex-col bg-white shadow-md overflow-hidden">
        <mat-tab-group
            (selectedTabChange)="changeTab($event)"
            [selectedIndex]="currentTab"
            animationDuration="0ms"
            class="h-full flex flex-col"
        >
            <!-- #region Overview Tab -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex gap-1 items-center">
                        <mat-icon *ngIf="currentTab === 0">done</mat-icon>
                        <span>{{ 'COMPONENT.PATIENT_DETAILS.overview' | translate }}</span>
                        &nbsp;
                        <mat-spinner
                            *ngIf="loadingOverview"
                            [ngClass]="{ 'patient-details-selected-tab-spinner': currentTab === 0 }"
                            class="tab-progress-spinner"
                            diameter="20"
                        ></mat-spinner>
                    </div>
                </ng-template>

                <div class="h-full overflow-auto relative">
                    <app-patient-overview
                        (changedOverviewData)="onOverviewDataChange($event)"
                        *ngIf="currentTab === 0 && vcPatientDetails"
                        [caseOverview]="vcCaseOverview"
                        [patientDetailsI18n]="vcPatientDetails?.patient?.i18n"
                        [vcPatientDetails]="vcPatientDetails"
                    ></app-patient-overview>

                    <div *ngIf="loadingOverview" class="patient-details-tab-spinner-cover">
                        <mat-spinner class="tab-progress-spinner"></mat-spinner>
                    </div>
                </div>
            </mat-tab>
            <!-- #endregion -->

            <!-- #region Curve Tab -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex gap-1 items-center">
                        <mat-icon *ngIf="currentTab === 1">done</mat-icon>
                        <span>{{ 'COMPONENT.PATIENT_DETAILS.curve' | translate }}</span>
                        &nbsp;
                        <mat-spinner
                            *ngIf="loadingCurve"
                            [ngClass]="{ 'patient-details-selected-tab-spinner': currentTab === 1 }"
                            class="tab-progress-spinner"
                            diameter="20"
                        ></mat-spinner>
                    </div>
                </ng-template>

                <div class="h-full relative">
                    <app-patient-curve
                        (groupsChange)="onCurveGroupsChange($event)"
                        *ngIf="currentTab === 1"
                        [caseDetails]="caseDetails"
                        [patientNameAndDob]="patientNameAndDob"
                        [vcGroups]="vc_CurveGroups"
                    ></app-patient-curve>

                    <div *ngIf="loadingCurve" class="patient-details-tab-spinner-cover">
                        <mat-spinner class="tab-progress-spinner"></mat-spinner>
                    </div>
                </div>
            </mat-tab>
            <!-- #endregion -->

            <!-- #region Record Tab -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex gap-1 items-center">
                        <mat-icon *ngIf="currentTab === 2">done</mat-icon>
                        <span>{{ 'COMPONENT.PATIENT_DETAILS.file' | translate }}</span>
                        &nbsp;
                        <mat-spinner
                            *ngIf="loadingRecord"
                            [ngClass]="{ 'patient-details-selected-tab-spinner': currentTab === 2 }"
                            class="tab-progress-spinner"
                            diameter="20"
                        ></mat-spinner>
                    </div>
                </ng-template>

                <div class="h-full relative">
                    <app-med-view *ngIf="currentTab === 2" [patientRecordData]="patientRecordData"></app-med-view>
                    <!--<patient-files
                        (clickOnFullScreen)="onClickOnFullScreen($event)"
                        (deletedFinding)="deleteFinding($event)"
                        (isMetaDataViewChanged)="onMetaDataViewOpenedChanged($event)"
                        (isPaintingToolOpenedChanged)="onPaintingToolOpenedChanged($event)"
                        (newTextDocument)="onNewTextDocument($event)"
                        *ngIf="currentTab === 2"
                        [areas]="regions"
                        [findings]="findings"
                        [isFullscreenForImageEnabled]="isFullscreenForImageEnabled()"
                        [isMetaDataViewOpened]="isMetaDataViewOpened()"
                        [isPaintingToolOpened]="isPaintingToolOpened()"
                        [patientNameAndDob]="patientNameAndDob"
                        [records]="records"
                        [selectedRecord]="selectedRecord"
                        [subAreas]="subRegions"
                        [vcFindings]="vcFindings"
                        [vcLabor]="vcLabor"
                    ></patient-files>-->

                    <div *ngIf="loadingRecord" class="patient-details-tab-spinner-cover">
                        <mat-spinner class="tab-progress-spinner"></mat-spinner>
                    </div>
                </div>
            </mat-tab>
            <!-- #endregion -->

            <!-- #region Workflow Tab -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex gap-1 items-center">
                        <mat-icon *ngIf="currentTab === 3">done</mat-icon>
                        <span>{{ 'COMPONENT.PATIENT_DETAILS.workflow' | translate }}</span>
                        &nbsp;
                        <mat-spinner
                            *ngIf="loadingWorkflow"
                            [ngClass]="{ 'patient-details-selected-tab-spinner': currentTab === 3 }"
                            class="tab-progress-spinner"
                            diameter="20"
                        ></mat-spinner>
                    </div>
                </ng-template>

                <div class="h-full relative">
                    <app-workflow-tabs
                        (viewContentChange)="onViewContentChange($event)"
                        *ngIf="currentTab === 3"
                        [filterValue]="filterValue"
                        [patientDetails]="vcPatientDetails"
                        [tasks]="vc_CommunicationMessages ?? []"
                    ></app-workflow-tabs>

                    <div *ngIf="loadingWorkflow" class="patient-details-tab-spinner-cover">
                        <mat-spinner class="tab-progress-spinner"></mat-spinner>
                    </div>
                </div>
            </mat-tab>
            <!-- #endregion -->

            <!-- #region Communication Tab -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex gap-1 items-center">
                        <mat-icon *ngIf="currentTab === 4">done</mat-icon>
                        <span>{{ 'COMPONENT.PATIENT_DETAILS.communication' | translate }}</span>
                        &nbsp;
                        <mat-spinner
                            *ngIf="loadingCommunication"
                            [ngClass]="{ 'patient-details-selected-tab-spinner': currentTab === 4 }"
                            class="tab-progress-spinner"
                            diameter="20"
                        ></mat-spinner>
                    </div>
                </ng-template>

                <div class="h-full relative">
                    <app-communication
                        (sendMessage)="onCommunicationSendMessage($event)"
                        [caseOverview]="vcCaseOverview"
                        [messages]="vc_CommunicationMessages"
                        [user]="user"
                    ></app-communication>

                    <div *ngIf="loadingCommunication" class="patient-details-tab-spinner-cover">
                        <mat-spinner class="tab-progress-spinner"></mat-spinner>
                    </div>
                </div>
            </mat-tab>
            <!-- #endregion -->
        </mat-tab-group>
    </div>
</div>
