import { Injectable } from '@angular/core';
import * as fabric from 'fabric';
import { Canvas } from 'fabric';
import * as pdfjsLib from 'pdfjs-dist';
import { ExtendedCanvasJson } from '../components/image-doc-drawer/image-doc-drawer.component';
import { ExtendedFinding, FindingItem } from '../med-view.component';

@Injectable({
    providedIn: 'root',
})
export class CanvasRendererService {
    private fabricCanvas: Canvas | undefined;
    private isCanvasInitialized: boolean = false; // Initialisierungsstatus

    constructor() {
        pdfjsLib.GlobalWorkerOptions.workerSrc = 'assets/pdf.worker.mjs';
    }

    /**
     * Initialisiert das Canvas mit Fabric.js
     */
    public async initCanvas(
        canvasElementId: string,
        wrapperId: string,
        selectedFinding: FindingItem<ExtendedFinding>,
        annotations: ExtendedCanvasJson | null,
        isEditable: boolean
    ): Promise<fabric.Canvas | undefined> {
        this.isCanvasInitialized = false;
        const canvasElement = await this.waitForElement(canvasElementId);
        if (!canvasElement) {
            console.error('Canvas-Element konnte nicht gefunden werden.');
            return undefined;
        }

        // Bereinige bestehende Canvas-Instanz
        if (this.fabricCanvas) {
            await this.fabricCanvas.dispose();
            this.fabricCanvas = undefined;
        }

        // Neue Fabric-Canvas-Instanz erstellen
        this.fabricCanvas = new fabric.Canvas(canvasElement, {
            enableRetinaScaling: true,
            renderOnAddRemove: true,
        });

        let format: string | undefined;
        switch (selectedFinding?.data?.documentType) {
            case 'pdf':
                format = 'data:application/pdf;base64,';
                break;
            case 'image':
                format = 'data:image/png;base64,';
                break;
        }

        if (selectedFinding?.data?.base64) {
            await this.loadDocument(format + selectedFinding.data.base64, annotations, wrapperId, isEditable);
        }

        return this.fabricCanvas;
    }

    /**
     * Gibt zurück, ob das Canvas initialisiert wurde
     */
    public getCanvasInitializationStatus(): boolean {
        return this.isCanvasInitialized;
    }

    /**
     * Lädt das Dokument (PDF oder Bild) ins Canvas
     */
    private async loadDocument(
        base64: string,
        annotations: ExtendedCanvasJson | null,
        wrapperId: string,
        isEditable: boolean
    ): Promise<void> {
        const wrapper = document.getElementById(wrapperId);
        if (!wrapper || !this.fabricCanvas) return;

        const wrapperWidth = wrapper.clientWidth;

        if (base64.startsWith('data:application/pdf')) {
            await this.processPdf(base64, wrapperWidth);
        } else if (base64.startsWith('data:image/png') || base64.startsWith('data:image/jpeg')) {
            await this.processImage(base64, wrapperWidth);
        } else {
            console.error('Nicht unterstütztes Format!');
        }

        if (annotations?.objects?.length) {
            this.addAnnotations(annotations, annotations.originalWidth, annotations.originalHeight, isEditable);
        } else {
            this.fabricCanvas.renderAll();
        }

        this.isCanvasInitialized = true;
    }

    /**
     * Fügt Annotationen zum Canvas hinzu
     */
    private addAnnotations(
        annotations: ExtendedCanvasJson,
        savedCanvasWidth: number,
        savedCanvasHeight: number,
        isEditable: boolean
    ): void {
        if (!this.fabricCanvas) return;

        const currentCanvasWidth = this.fabricCanvas.getWidth();
        const currentCanvasHeight = this.fabricCanvas.getHeight();

        // Berechnung der globalen Skalierungsfaktoren
        const scaleXGlobal = currentCanvasWidth / savedCanvasWidth;
        const scaleYGlobal = currentCanvasHeight / savedCanvasHeight;
        annotations.objects.forEach((annotation: any) => {
            if (!this.fabricCanvas) return;
            let fabricObject: fabric.Object | null = null;

            const absLeft = annotation.left * currentCanvasWidth;
            const absTop = annotation.top * currentCanvasHeight;
            const absWidth = annotation.width * currentCanvasWidth;
            const absHeight = annotation.height * currentCanvasHeight;

            switch (annotation.type.toLowerCase()) {
                case 'itext':
                    const scaleX = annotation.scaleX ?? 1;
                    const scaleY = annotation.scaleY ?? 1;

                    fabricObject = new fabric.IText(annotation.text, {
                        left: absLeft,
                        top: absTop,
                        fontSize: (annotation.fontSize * currentCanvasWidth) / savedCanvasWidth,
                        fill: annotation.fill,
                        fontFamily: annotation.fontFamily || 'Arial',
                        scaleX: scaleX,
                        scaleY: scaleY,
                        selectable: isEditable,
                        evented: isEditable,
                    });
                    break;

                case 'circle':
                    const circleScaleX = annotation.scaleX ?? 1;
                    const circleScaleY = annotation.scaleY ?? 1;

                    fabricObject = new fabric.Circle({
                        left: absLeft,
                        top: absTop,
                        radius: (annotation.radius * currentCanvasWidth) / savedCanvasWidth,
                        fill: annotation.fill,
                        stroke: annotation.stroke,
                        strokeWidth: (annotation.strokeWidth * currentCanvasWidth) / savedCanvasWidth,
                        scaleX: circleScaleX,
                        scaleY: circleScaleY,
                        selectable: isEditable,
                        evented: isEditable,
                    });
                    break;

                case 'rect':
                    const rectScaleX = annotation.scaleX ?? 1;
                    const rectScaleY = annotation.scaleY ?? 1;

                    fabricObject = new fabric.Rect({
                        left: absLeft,
                        top: absTop,
                        width: absWidth,
                        height: absHeight,
                        fill: annotation.fill,
                        stroke: annotation.stroke,
                        strokeWidth: (annotation.strokeWidth * currentCanvasWidth) / savedCanvasWidth,
                        scaleX: rectScaleX,
                        scaleY: rectScaleY,
                        selectable: isEditable,
                        evented: isEditable,
                    });
                    break;

                case 'path':
                    const pathScaleX = annotation.scaleX ?? 1;
                    const pathScaleY = annotation.scaleY ?? 1;

                    fabricObject = new fabric.Path(annotation.path, {
                        evented: isEditable,
                        fill: annotation.fill,
                        left: absLeft,
                        scaleX: (pathScaleX * currentCanvasWidth) / savedCanvasWidth,
                        scaleY: (pathScaleY * currentCanvasHeight) / savedCanvasHeight,
                        selectable: isEditable,
                        stroke: annotation.stroke,
                        strokeLineCap: annotation.strokeLineCap,
                        strokeLineJoin: annotation.strokeLineJoin,
                        strokeWidth: (annotation.strokeWidth * currentCanvasWidth) / savedCanvasWidth,
                        top: absTop,
                    });
                    break;

                default:
                    console.warn(`Unsupported type: ${annotation.type}`);
            }

            if (fabricObject) {
                this.fabricCanvas.add(fabricObject);
            }
        });

        this.fabricCanvas.renderAll();
    }

    /**
     * Wartet, bis ein Element mit der angegebenen ID verfügbar ist
     */
    private async waitForElement(elementId: string): Promise<HTMLCanvasElement | null> {
        return new Promise((resolve) => {
            const element = document.getElementById(elementId) as HTMLCanvasElement;
            if (element) {
                resolve(element);
            } else {
                const observer = new MutationObserver(() => {
                    const el = document.getElementById(elementId) as HTMLCanvasElement;
                    if (el) {
                        observer.disconnect();
                        resolve(el);
                    }
                });
                observer.observe(document.body, { childList: true, subtree: true });
            }
        });
    }

    private async processPdf(base64: string, wrapperWidth: number): Promise<void> {
        if (!this.fabricCanvas) return;

        const pdfData = atob(base64.split(',')[1]);
        const loadingTask = pdfjsLib.getDocument({ data: pdfData });
        const pdf = await loadingTask.promise;

        let totalHeight = 0;

        for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
            const page = await pdf.getPage(pageNum);
            const viewportOriginal = page.getViewport({ scale: 1 });
            const scale = wrapperWidth / viewportOriginal.width;
            const viewport = page.getViewport({ scale: scale * 2 }); // Doppelte Auflösung für bessere Qualität

            const tempCanvas = document.createElement('canvas');
            const context = tempCanvas.getContext('2d', { alpha: false }); // Alpha auf false für bessere Leistung
            if (!context) return;

            tempCanvas.width = viewport.width;
            tempCanvas.height = viewport.height;

            const renderContext = {
                canvasContext: context,
                viewport: viewport,
                enableWebGL: true, // WebGL für bessere Leistung, falls verfügbar
                renderInteractiveForms: true,
            };

            await page.render(renderContext).promise;

            const base64Image = tempCanvas.toDataURL('image/png');
            const img = await fabric.Image.fromURL(base64Image, { crossOrigin: 'anonymous' });

            img.set({
                left: 0,
                top: totalHeight,
                scaleX: 0.5, // Skalierung anpassen, da wir die doppelte Auflösung verwendet haben
                scaleY: 0.5,
                selectable: false,
                evented: false,
            });

            this.fabricCanvas.add(img);
            totalHeight += viewport.height * 0.5; // Anpassung der Höhe aufgrund der Skalierung
        }

        this.fabricCanvas.setDimensions({ width: wrapperWidth, height: totalHeight });
        this.fabricCanvas.requestRenderAll();
    }

    private async processImage(base64: string, wrapperWidth: number): Promise<void> {
        if (!this.fabricCanvas) return;

        const img = await fabric.Image.fromURL(base64, { crossOrigin: 'anonymous' });

        img.set({
            left: 0,
            top: 0,
            scaleX: wrapperWidth / img.width,
            scaleY: wrapperWidth / img.width,
            selectable: false,
            evented: false,
        });

        this.fabricCanvas.add(img);
        this.fabricCanvas.setWidth(wrapperWidth);
        this.fabricCanvas.setHeight((img.height || 0) * (wrapperWidth / img.width));
    }
}
