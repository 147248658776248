import triggerSegmentationRender from '../../utilities/segmentation/triggerSegmentationRender';
import SegmentationRepresentations from '../../enums/SegmentationRepresentations';
import * as SegmentationState from '../../stateManagement/segmentation/segmentationState';
import onLabelmapSegmentationDataModified from './labelmap/onLabelmapSegmentationDataModified';
const onSegmentationDataModified = function (evt) {
    const { segmentationId } = evt.detail;
    const { type } = SegmentationState.getSegmentation(segmentationId);
    const toolGroupIds = SegmentationState.getToolGroupIdsWithSegmentation(segmentationId);
    if (type === SegmentationRepresentations.Labelmap) {
        onLabelmapSegmentationDataModified(evt);
    }
    toolGroupIds.forEach((toolGroupId) => {
        triggerSegmentationRender(toolGroupId);
    });
};
export default onSegmentationDataModified;
