<div class="text-editor-bridge-container">
    <!-- Header (area, subarea, title) -->
    <div class="flex justify-center gap-2 m-2">
        <mat-select [(ngModel)]="selectedArea" placeholder="Bereich">
            <mat-option *ngFor="let area of areas" [value]="area">{{
                    area.long_text ?? area.short_text
                }}
            </mat-option>
        </mat-select>

        <mat-select [(ngModel)]="selectedSubArea" placeholder="Unterbereich">
            <mat-option *ngFor="let subArea of filterRelatedSubAreas()" [value]="subArea">{{
                    subArea.long_text ?? subArea.short_text
                }}
            </mat-option>
        </mat-select>

        <input [(ngModel)]="documentTitle"
               matInput
               placeholder="Dokumenttitel"
               style="flex: 2"
        />
    </div>

    <!-- Text editor -->
    <luic-text-editor (contentChange)="content = $event"
                      [contentType]="'html'"
                      [showSaveButton]="false"
                      class="texteditor-height"
    />

    <!-- Footer (action buttons)-->
    <div class="flex flex-row justify-end pt-2 gap-2">
        <button (click)="onClickOnCancel()" mat-stroked-button>
            Abbrechen
        </button>
        <button (click)="onClickOnSave()" mat-flat-button>Speichern</button>
    </div>
</div>
