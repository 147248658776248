export interface Settings {
    language: string;
    activeAnonymous: boolean;
    colorScheme:string;
    simulateOffline: boolean;
}

export const DEFAULT_SETTINGS: Settings = {
    language: 'de',
    activeAnonymous: false,
    colorScheme:"default",
    simulateOffline: false
};
