<!-- Close button -->
<button class="!absolute right-4 top-4 z-50" mat-dialog-close mat-mini-fab>
    <mat-icon>cancel</mat-icon>
</button>

<mat-dialog-content>
    <app-pdf-viewer
        *ngIf="data.data.documentType === 'pdf'"
        [src]="data.data?.documentBase64"
        class="w-full h-full"
    ></app-pdf-viewer>

    <app-dicom-viewer
        *ngIf="data.data.documentType === 'dicom'"
        [base64]="data.data?.documentBase64"
        [fullscreen]="data.fullScreen"
        class="w-full h-full"
    ></app-dicom-viewer>

    <img
        *ngIf="data.data.documentType === 'image'"
        [src]="'data:image;base64,' + data.data?.documentBase64"
        alt=""
        class="h-full w-auto mx-auto object-contain"
    />

    <app-lab-table-viewer
        *ngIf="data.data.documentType === 'labResults'"
        [labResults]="data.data.labResults"
        [numberOfColumnsToShow]="7"
        [showLimitsToggleButton]="true"
        class="block"
    ></app-lab-table-viewer>
</mat-dialog-content>
