const singleRetrieveStages = [
    {
        id: 'initialImages',
        retrieveType: 'single',
    },
    {
        id: 'errorRetrieve',
    },
];
export default singleRetrieveStages;
