// Wenn der Value min unterschreitet oder max überschreitet, wird der Wert auf den entsprechenden Wert von min oder max gesetzt.
export const getMinMaxValue = (
    value: number,
    min: number,
    max: number,
    maxOffset: number,
    offset: number
): number => {
    if (value < min) {
        return min;
    } else if (value >= maxOffset) {
        return max + offset;
    }
    return value;
};
