import {Routes} from '@angular/router';
import {ErrorComponent} from './pages/base/error/error.component';
import {SettingsComponent} from './pages/settings/settings.component';
import {TestSandboxComponent} from './pages/base/test-sandbox/test-sandbox.component';
import {PatientDetailsComponent} from './pages/patient-details/patient-details.component';
import {LoginCallbackComponent} from './components/utility/login-callback/login-callback.component';
import {LoginPageComponent} from './pages/base/login-page/login-page.component';
import {HomeComponent} from './pages/home/home.component';
import {WorkflowPageComponent} from './pages/workflow-page/workflow-page.component';
import {authGuard} from './guards/auth.guard';
import { DicomViewerComponent } from "./components/dicom-viewer/dicom-viewer.component";
import {OrganizationPageComponent} from './pages/organization-page/organization-page.component';

export const routes: Routes = [
    {
        path: 'login',
        children: [
            {
                path: '',
                component: LoginPageComponent
            },
            {
                path: 'redirect',
                component: LoginCallbackComponent
            }
        ]
    },
    {
        path: 'dicom',
        component: DicomViewerComponent
    },
    {
        path: '',
        canActivate: [authGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: '/start'
            },
            {
                path: 'start',
                component: HomeComponent
            },
            {
                path: 'patient-details/:id',
                component: PatientDetailsComponent
            },
            {
                path: 'patient-details/:id/:tabIndex',
                component: PatientDetailsComponent
            },
            {
                path: 'admin',
                loadChildren: () =>
                    import('./pages/admin/admin.module').then(
                        (m) => m.AdminModule
                    )
            },
            {
                path: 'about',
                loadComponent: () =>
                    import('./pages/about/about.component').then(
                        (mod) => mod.AboutComponent
                    )
            },
            {
                path: 'test-sandbox',
                component: TestSandboxComponent
            },
            {
                path: 'settings',
                component: SettingsComponent
            },
            {
                path: 'organization',
                component: OrganizationPageComponent
            },
            {
                path: 'workflow',
                component: WorkflowPageComponent
            },
            {
                path: '404',
                component: ErrorComponent
            },
            {
                path: '**',
                redirectTo: '404'
            }
        ]
    }
];

