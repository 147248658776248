export function extractContourData(polyDataCache, segmentIndexMap) {
    const rawResults = new Map();
    for (const [cacheId, intersectionInfo] of polyDataCache) {
        const surfaceId = cacheId.split('_')[1];
        for (const [_, result] of intersectionInfo) {
            if (!result) {
                continue;
            }
            const segmentIndex = Number(surfaceId) || segmentIndexMap?.get(surfaceId);
            if (!segmentIndex) {
                continue;
            }
            if (!rawResults.has(segmentIndex)) {
                rawResults.set(segmentIndex, []);
            }
            rawResults.get(segmentIndex).push(result);
        }
    }
    return rawResults;
}
