import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {
    CapacitorSQLite,
    SQLiteConnection,
    SQLiteDBConnection,
    CapacitorSQLitePlugin,
    capSQLiteUpgradeOptions,
} from '@capacitor-community/sqlite';

@Injectable()
export class SQLiteService {
    sqliteConnection!: SQLiteConnection;
    isService: boolean = false;
    platform!: string;
    sqlitePlugin!: CapacitorSQLitePlugin;
    native: boolean = false;

    constructor() {}

    /**
     * Initializes the SQLite plugin based on the platform the app is running on.
     * @returns {Promise<boolean>} A promise that resolves to true when the plugin is initialized.
     */
    async initializePlugin(): Promise<boolean> {
        // Detects the platform (web, iOS, Android) the app is running on.
        this.platform = Capacitor.getPlatform();

        // Checks if the app is running on iOS or Android to enable native functionalities.
        if (this.platform === 'ios' || this.platform === 'android')
            this.native = true;

        // Initializes SQLite plugin and connection handler.
        this.sqlitePlugin = CapacitorSQLite;
        this.sqliteConnection = new SQLiteConnection(this.sqlitePlugin);
        this.isService = true;
        return true;
    }

    /**
     * Initializes the web store for web platform usage.
     * @returns {Promise<void>} A promise that resolves when the web store is initialized or rejects with an error message.
     */
    async initWebStore(): Promise<void> {
        try {
            // Initializes the web store for storing data in the browser.
            await this.sqliteConnection.initWebStore();
        } catch (err: any) {
            const msg = err.message ? err.message : err;
            return Promise.reject(`initWebStore: ${err}`);
        }
    }

    /**
     * Opens a database connection.
     * @param {string} dbName The name of the database.
     * @param {boolean} encrypted Whether the database is encrypted.
     * @param {string} mode The mode of the database (no-encryption, encryption).
     * @param {number} version The version of the database.
     * @param {boolean} readonly Whether the database is opened in read-only mode.
     * @returns {Promise<SQLiteDBConnection>} A promise that resolves to the database connection.
     */
    async openDatabase(
        dbName: string,
        encrypted: boolean,
        mode: string,
        version: number,
        readonly: boolean
    ): Promise<SQLiteDBConnection> {
        let db: SQLiteDBConnection;

        // Checks the consistency of existing connections and whether a connection for the dbName already exists.
        const retCC = (
            await this.sqliteConnection.checkConnectionsConsistency()
        ).result;
        let isConn = (
            await this.sqliteConnection.isConnection(dbName, readonly)
        ).result;

        if (retCC && isConn) {
            // Retrieves an existing connection if one exists.
            db = await this.sqliteConnection.retrieveConnection(
                dbName,
                readonly
            );
        } else {
            // Creates a new connection if none exists.
            db = await this.sqliteConnection.createConnection(
                dbName,
                encrypted,
                mode,
                version,
                readonly
            );
        }
        // Opens the database.
        await db.open();
        return db;
    }

    /**
     * Retrieves an existing database connection.
     * @param {string} dbName The name of the database.
     * @param {boolean} readonly Whether the connection is read-only.
     * @returns {Promise<SQLiteDBConnection>} A promise that resolves to the retrieved database connection.
     */
    async retrieveConnection(
        dbName: string,
        readonly: boolean
    ): Promise<SQLiteDBConnection> {
        return await this.sqliteConnection.retrieveConnection(dbName, readonly);
    }

    /**
     * Closes the specified database connection.
     * @param {string} database The name of the database to close.
     * @param {boolean} [readonly] Specifies if the connection to close is read-only.
     * @returns {Promise<void>} A promise that resolves when the connection is closed.
     */
    async closeConnection(database: string, readonly?: boolean): Promise<void> {
        const readOnly = readonly ? readonly : false;
        return await this.sqliteConnection.closeConnection(database, readOnly);
    }

    /**
     * Adds SQL statements to be executed during a database version upgrade.
     * @param {capSQLiteUpgradeOptions} options The options including the database name, version, and upgrade statements.
     * @returns {Promise<void>} A promise that resolves when the upgrade statements are added.
     */
    async addUpgradeStatement(options: capSQLiteUpgradeOptions): Promise<void> {
        await this.sqlitePlugin.addUpgradeStatement(options);
        return;
    }

    /**
     * Saves changes to the store, applicable in web environments.
     * @param {string} database The name of the database to save changes for.
     * @returns {Promise<void>} A promise that resolves when the database is saved to the store.
     */
    async saveToStore(database: string): Promise<void> {
        return await this.sqliteConnection.saveToStore(database);
    }

    public async deleteDatabase(dbName: string): Promise<any> {
        return await this.sqlitePlugin.deleteDatabase({ database: dbName });
    }
}
