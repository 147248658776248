import {createReducer, on} from '@ngrx/store';
import * as ViewContentActions from '../actions/viewcontent.action';
import {ViewContent} from '../../models/view-content.models/view-content.model';
import {CP2_User} from '../../models/view-content.models/view-content-personal-domain.model';

export interface ViewContentState {
    viewContent: ViewContent<any>;
    user: CP2_User | null; // User hinzugefügt
    loading: boolean;
    error: any;
}

export const initialState: ViewContentState = {
    viewContent: {} as ViewContent<any>,
    user: null, // Initialisierung des Users
    loading: false,
    error: null
};

export const viewContentReducer = createReducer(
    initialState,
    on(ViewContentActions.setViewContent, (state, {viewContent, user}) => ({
        ...state,
        viewContent,
        user, // User wird in den Zustand übernommen
        loading: true
    })),
    on(ViewContentActions.setViewContentSuccess, (state, {viewContent, user}) => ({
        ...state,
        viewContent,
        user, // Erfolgreich gespeicherter Benutzer
        loading: false,
        error: null
    })),
    on(ViewContentActions.setViewContentFail, (state, {error}) => ({
        ...state,
        loading: false,
        error
    })),
    on(ViewContentActions.loadViewContentSuccess, (state, {viewContent}) => ({
        ...state,
        viewContent,  // Ein einzelnes ViewContent-Objekt wird gespeichert
        loading: false,
        error: null
    })),
    on(ViewContentActions.loadViewContentFail, (state, {error}) => ({
        ...state,
        loading: false,
        error
    }))
);
