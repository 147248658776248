import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DeviceFacadeService } from '../../services/facades/device-facade.service';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-device-registered-indicator',
    templateUrl: './device-registered-indicator.component.html',
    styleUrls: ['./device-registered-indicator.component.scss'],
    standalone: true,
    imports: [CommonModule, MatIconModule],
})
export class DeviceRegisteredIndicatorComponent {
    public isRegistered = false;
    public device$ = this.deviceFacade.device$;

    public constructor(private deviceFacade: DeviceFacadeService) {}
}
