import {createAction, props} from '@ngrx/store';
import {ViewContent} from '../../models/view-content.models/view-content.model';
import {CP2_User} from '../../models/view-content.models/view-content-personal-domain.model';

export const loadViewContentState = createAction(
    '[ViewContent] Load ViewContent',
    props<{ locator: string }>() // Locator wird als Prop hinzugefügt
);

export const setViewContent = createAction(
    '[ViewContent] Set ViewContent',
    props<{ viewContent: ViewContent<any>, user: CP2_User }>() // User hinzugefügt
);

export const setViewContentSuccess = createAction(
    '[ViewContent] Set ViewContent Successful',
    props<{ viewContent: ViewContent<any>, user: CP2_User }>() // User hinzugefügt
);

export const setViewContentFail = createAction(
    '[ViewContent] Set ViewContent Fail',
    props<{ error: any }>()
);

export const loadViewContentSuccess = createAction(
    '[ViewContent] Load ViewContent Successful',
    props<{ viewContent: ViewContent<any> }>() // Ein einzelnes ViewContent-Objekt
);

export const loadViewContentFail = createAction(
    '[ViewContent] Load ViewContent Fail',
    props<{ error: any }>()
);
