import { capSQLiteChanges, DBSQLiteValues, SQLiteDBConnection } from '@capacitor-community/sqlite';
import { Injectable } from '@angular/core';
import { SQLiteService } from './sqlite.service';
import { DbnameVersionService } from './dbname-version.service';
import { MigrationUpgradeStatements } from './migrations.statement';

/*
 * Add your migrations in the `upgradeMigrations` array of `MigrationUpgradeStatements` (migrations.statements.ts)
 */
@Injectable()
export class DbService {
    private static readonly TAG = 'DbService';
    private static debugModus = false;

    private databaseName: string = '';
    private migrationsStatements = new MigrationUpgradeStatements();
    private upgradeMigrations;
    private loadToVersion;
    private db!: SQLiteDBConnection;

    public constructor(
        private sqliteService: SQLiteService,
        private dbVerService: DbnameVersionService
    ) {
        this.upgradeMigrations = this.migrationsStatements.upgradeMigrations;
        this.loadToVersion = this.upgradeMigrations[this.upgradeMigrations.length - 1].toVersion;
    }

    public async initializeDatabase(dbName: string) {
        this.databaseName = dbName;
        // create upgrade statements
        await this.sqliteService.addUpgradeStatement({
            database: this.databaseName,
            upgrade: this.upgradeMigrations,
        });
        // create and/or open the database
        this.db = await this.sqliteService.openDatabase(
            this.databaseName,
            false,
            'no-encryption',
            this.loadToVersion,
            false
        );
        this.dbVerService.set(this.databaseName, this.loadToVersion);
    }

    //#region Admin functions
    public async deleteDatabase(): Promise<any> {
        return await this.sqliteService.deleteDatabase(this.databaseName);
    }

    //#endregion

    //#region ViewContent CRUD functions
    public async createViewContent(
        locator: string,
        owners: string,
        owner_departments: string,
        main_owner_job_type: string,
        created_at: string,
        status: string,
        related_patient_id: string,
        related_case_id: string,
        data_id: number,
        form: string,
        i18n: string
    ): Promise<capSQLiteChanges> {
        const sql = `
            INSERT INTO view_content (
                locator,
                owners,
                owner_departments,
                main_owner_job_type,
                created_at,
                status,
                related_patient_id,
                related_case_id,
                data_id,
                form,
                i18n
            ) VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?);
        `;

        const values = [
            locator,
            owners,
            owner_departments,
            main_owner_job_type,
            created_at,
            status,
            related_patient_id,
            related_case_id,
            data_id,
            form,
            i18n,
        ];

        const dbRes = await this.db.run(sql, values);

        if (DbService.debugModus) {
            console.log('createViewContent', { sql, values, dbRes });
        }

        return dbRes;
    }

    public async getViewContentByLocator(locator: string, like: boolean = false): Promise<DBSQLiteValues> {
        let sql;
        if (like) {
            sql = `SELECT * FROM view_content WHERE locator LIKE ?;`;
            locator = `%${locator}%`; // Adjust locator for LIKE query
        } else {
            sql = `SELECT * FROM view_content WHERE locator = ?;`;
        }

        const dbRes = await this.db.query(sql, [locator]);

        if (DbService.debugModus) {
            console.log('getViewContentByLocator', { sql, locator, dbRes });
        }

        return dbRes;
    }

    public async getViewContentBatchByLocator(locator: string): Promise<DBSQLiteValues> {
        const sql = `SELECT * FROM view_content WHERE locator LIKE ?;`;
        const values = [locator + '%']

        const dbRes = await this.db.query(sql, values);

        if (DbService.debugModus) {
            console.log('getViewContentBatchByLocator', { sql, locator, dbRes });
        }

        return dbRes;
    }

    public async getViewContentById(id: number): Promise<DBSQLiteValues> {
        const sql = `SELECT * FROM view_content WHERE id = ?;`;

        const dbRes = await this.db.query(sql, [id]);

        if (DbService.debugModus) {
            console.log('getViewContentById', { sql, id, dbRes });
        }

        return dbRes;
    }

    public async getAllViewContentForCase(caseId: string): Promise<any> {
        const sql = `SELECT * FROM view_content WHERE related_case_id = ?;`;

        const dbRes = await this.db.query(sql, [caseId]);

        if (DbService.debugModus) {
            console.log('getAllViewContentForCase', { sql, caseId, dbRes });
        }

        return dbRes;
    }

    public async getAllLocatorsForCase(caseId: string): Promise<DBSQLiteValues> {
        const sql = `SELECT locator FROM view_content WHERE related_case_id = ?;`;

        const dbRes = await this.db.query(sql, [caseId]);

        if (DbService.debugModus) {
            console.log('getAllLocatorsForCase', { sql, caseId, dbRes });
        }

        return dbRes;
    }

    public async getAllDifferentCaseId(): Promise<DBSQLiteValues> {
        const sql = `SELECT DISTINCT related_case_id FROM view_content;`;

        const dbRes = await this.db.query(sql, []);

        if (DbService.debugModus) {
            console.log('getAllLocatorsForCase', { sql, dbRes });
        }

        return dbRes;
    }

    // TODO: Work with the locator rather than with the id, as locator is safer when working with API data and local data.
    public async updateViewContent(
        id: number,
        locator: string,
        owners: string,
        owner_departments: string,
        main_owner_job_type: string,
        created_at: string,
        status: string,
        related_patient_id: string,
        related_case_id: string,
        data_id: number,
        form: string,
        i18n: string
    ): Promise<capSQLiteChanges> {
        const sql = `
            UPDATE view_content
            SET
                locator = ?,
                owners = ?,
                owner_departments = ?,
                main_owner_job_type = ?,
                created_at = ?,
                status = ?,
                related_patient_id = ?,
                related_case_id = ?,
                data_id = ?,
                form = ?,
                i18n = ?
            WHERE id = ?;
        `;

        const values = [
            locator,
            owners,
            owner_departments,
            main_owner_job_type,
            created_at,
            status,
            related_patient_id,
            related_case_id,
            data_id,
            form,
            i18n,
            id,
        ];

        const dbRes = await this.db.run(sql, values);

        if (DbService.debugModus) {
            console.log('updateViewContent', { sql, values, dbRes });
        }

        return dbRes;
    }

    public async deleteViewContent(id: number): Promise<any> {
        const sql = `DELETE FROM view_content WHERE id = ?;`;
        const values = [id];

        const dbRes = await this.db.run(sql, values);

        if (DbService.debugModus) {
            console.log('deleteViewContent', { sql, values, dbRes });
        }

        return dbRes;
    }

    public async getAllViewContent(): Promise<any> {
        const sql = `SELECT * FROM view_content;`;

        const dbRes = await this.db.query(sql, []);

        if (DbService.debugModus) {
            console.log('getAllViewContent', { sql, dbRes });
        }

        return dbRes;
    }

    public async createVcData(data: string): Promise<capSQLiteChanges> {
        const sql = `INSERT INTO view_content_data (data) VALUES (?);`;
        const values = [data];

        const dbRes = await this.db.run(sql, values);

        if (DbService.debugModus) {
            console.log('createVcData', { sql, values, dbRes });
        }

        return dbRes;
    }

    public async getVcDataForId(id: number): Promise<DBSQLiteValues> {
        const sql = `SELECT * FROM view_content_data WHERE id = ?;`;
        const values = [id];

        const dbRes = await this.db.query(sql, values);

        if (DbService.debugModus) {
            console.log('getVcDataForId', { sql, values, dbRes });
        }

        return dbRes;
    }

    public async updateVcData(id: number, data: string): Promise<capSQLiteChanges> {
        const sql = `UPDATE view_content_data SET data = ? WHERE id = ?;`; // Fixed the SQL syntax
        const values = [data, id];

        const dbRes = await this.db.run(sql, values);

        if (DbService.debugModus) {
            console.log('updateVcData', { sql, values, dbRes });
        }

        return dbRes;
    }

    public async createViewContentHistory(
        view_content_id: number,
        view_content_data_id: number,
        datetime: string,
        editor: string
    ): Promise<capSQLiteChanges> {
        const sql = `INSERT INTO view_content_history (view_content_id, view_content_data_id, datetime, editor ) VALUES (?, ?, ?, ?);`;
        const values = [view_content_id, view_content_data_id, datetime, editor];

        const dbRes = await this.db.run(sql, values);

        if (DbService.debugModus) {
            console.log('createViewContentHistory', { sql, values, dbRes });
        }

        return dbRes;
    }

    public async getHistoryForViewContent(vcId: number): Promise<DBSQLiteValues> {
        const sql = `
            SELECT view_content_history.*, view_content_data.data
            FROM view_content_history
            INNER JOIN view_content_data ON view_content_history.view_content_data_id = view_content_data.id
            WHERE view_content_history.view_content_id = ?;
        `;
        const values = [vcId];

        const dbRes = await this.db.query(sql, values);

        if (DbService.debugModus) {
            console.log('getHistoryForViewContent', { sql, values, dbRes });
        }

        return dbRes;
    }

    //#endregion

    //#region CP2_user
    /** Inserts one registry in the cp2_user table.
     * @param {boolean} safe (default = true) If true the clause "OR IGNORE" will be added to the SQL sentence, preventing an Error in case of collision
     */
    public async createCp2User(
        uuid: string,
        surname: string,
        name: string,
        validSince: string,
        validUntil: string,
        safe = true
    ): Promise<capSQLiteChanges> {
        const sql = `
            INSERT ${safe ? 'OR IGNORE' : ''} INTO cp2_user (
                uuid,
                surname,
                name,
                validSince,
                validUntil
            ) VALUES (?, ?, ?, ?, ?);
        `;
        const values = [uuid, surname, name, validSince, validUntil];

        return await this.db.run(sql, values);
    }

    public async getCp2UserById(userId: string): Promise<DBSQLiteValues> {
        const sql = `SELECT * FROM cp2_user WHERE userId = ?;`;
        return await this.db.query(sql, [userId]);
    }

    public async getAllCp2Users(): Promise<DBSQLiteValues> {
        const sql = `SELECT * FROM cp2_user;`;
        return await this.db.query(sql, []);
    }

    public async updateCp2User(
        userId: string,
        surname: string,
        name: string,
        validSince: string,
        validUntil: string
    ): Promise<capSQLiteChanges> {
        const sql = `
            UPDATE cp2_user
            SET
                surname = ?,
                name = ?,
                validSince = ?,
                validUntil = ?
            WHERE userId = ?;
        `;

        const values = [surname, name, validSince, validUntil, userId];

        return await this.db.run(sql, values);
    }

    public async deleteCp2User(userId: string): Promise<capSQLiteChanges> {
        const sql = `DELETE FROM cp2_user WHERE userId = ?;`;
        return await this.db.run(sql, [userId]);
    }

    //#endregion

    //#region Pending VC PUT operations
    public async createPendingPutVc(vcLocator: string, userJson: string, timestamp: string): Promise<capSQLiteChanges> {
        const sql = `INSERT INTO vc_cache_pending_put ( userJson, vcLocator, timestamp ) VALUES (?, ?, ?);`;

        const values = [userJson, vcLocator, timestamp];

        const dbRes = await this.db.run(sql, values);

        if (DbService.debugModus) {
            console.log('createPendingPut', { sql, values, dbRes });
        }

        return dbRes;
    }

    public async deletePendingPutVc(id: number): Promise<capSQLiteChanges> {
        const sql = `DELETE FROM vc_cache_pending_put WHERE id = ?;`;
        const values = [id];

        const dbRes = await this.db.run(sql, values);

        if (DbService.debugModus) {
            console.log('deletePendingPut', { sql, values, dbRes });
        }

        return dbRes;
    }

    public async getAllPendingPutVc(): Promise<DBSQLiteValues> {
        const sql = `SELECT * FROM vc_cache_pending_put;`;

        const dbRes = await this.db.query(sql);

        if (DbService.debugModus) {
            console.log('getAllPendingPut', { sql, dbRes });
        }

        return dbRes;
    }

    //#endregion

    //#region Pending Document PUT operations
    public async createPendingPutDocument(
        area: string,
        subarea: string,
        case_id: string,
        documenttext: string,
        filename: string,
        timestamp: string
    ): Promise<capSQLiteChanges> {
        const sql = `INSERT INTO record_document_cache_pending_put ( area, subarea, case_id, documenttext, filename, timestamp ) VALUES (?, ?, ?, ?, ?, ?);`;

        const values = [area, subarea, case_id, documenttext, filename, timestamp];

        const dbRes = await this.db.run(sql, values);

        if (DbService.debugModus) {
            console.log('createPendingPutDocument', { sql, values, dbRes });
        }

        return dbRes;
    }

    public async deletePendingPutDocument(id: number): Promise<capSQLiteChanges> {
        const sql = `DELETE FROM record_document_cache_pending_put WHERE id = ?;`;
        const values = [id];

        const dbRes = await this.db.run(sql, values);

        if (DbService.debugModus) {
            console.log('deletePendingPutDocument', { sql, values, dbRes });
        }

        return dbRes;
    }

    public async getAllPendingPutDocument(): Promise<DBSQLiteValues> {
        const sql = `SELECT * FROM record_document_cache_pending_put;`;

        const dbRes = await this.db.query(sql);

        if (DbService.debugModus) {
            console.log('getAllPendingPutDocument', { sql, dbRes });
        }

        return dbRes;
    }

    //#endregion

    //#region Case list
    public async createCaseListEntry(
        name: string,
        list_type: string
    ): Promise<capSQLiteChanges> {
        const sql = `INSERT INTO case_list (name, list_type) VALUES (?, ?);`;
        const values = [name, list_type];

        const dbRes = await this.db.run(sql, values);

        if (DbService.debugModus) {
            console.log('createCaseListEntry', { sql, values, dbRes });
        }

        return dbRes;
    }

    public async getCaseListEntriesForNameType(name: string, type: string): Promise<DBSQLiteValues> {
        const sql = `SELECT * FROM case_list WHERE name=? AND list_type=?;`;
        const values = [name, type];

        const dbRes = await this.db.query(sql, values);

        if (DbService.debugModus) {
            console.log('getAllCaseListEntries', { sql, dbRes });
        }

        return dbRes;
    }

    public async getAllCaseListEntries(): Promise<DBSQLiteValues> {
        const sql = `SELECT * FROM case_list;`;

        const dbRes = await this.db.query(sql);

        if (DbService.debugModus) {
            console.log('getAllCaseListEntries', { sql, dbRes });
        }

        return dbRes;
    }

    public async updateCaseListEntry(
        id: number,
        name: string,
        list_type: string
    ): Promise<capSQLiteChanges> {
        const sql = `UPDATE case_list SET name = ?, list_type = ? WHERE id = ?;`;
        const values = [name, list_type, id];

        const dbRes = await this.db.run(sql, values);

        if (DbService.debugModus) {
            console.log('updateCaseListEntry', { sql, values, dbRes });
        }

        return dbRes;
    }

    public async deleteCaseListEntry(id: number): Promise<capSQLiteChanges> {
        const sql = `DELETE FROM case_list WHERE id = ?;`;
        const values = [id];

        const dbRes = await this.db.run(sql, values);

        if (DbService.debugModus) {
            console.log('deleteCaseListEntry', { sql, values, dbRes });
        }

        return dbRes;
    }

    public async getListIdForCase(case_id: string): Promise<DBSQLiteValues> {
        const sql = `SELECT list_id FROM case_to_list WHERE case_id = ?;`;
        const values = [case_id];

        const dbRes = await this.db.query(sql, values);

        if (DbService.debugModus) {
            console.log('getListIdForCase', { sql, values, dbRes });
        }

        return dbRes;
    }

    public async includeCaseInList(case_id: string, list_id: number): Promise<capSQLiteChanges> {
        const sql = `INSERT INTO case_to_list (case_id, list_id) VALUES (?, ?);`;
        const values = [case_id, list_id];

        const dbRes = await this.db.run(sql, values);

        if (DbService.debugModus) {
            console.log('includeCaseInList', { sql, values, dbRes });
        }

        return dbRes;
    }

    public async removeCaseFromList(case_id: string, list_id: number): Promise<capSQLiteChanges> {
        const sql = `DELETE FROM case_to_list WHERE case_id = ? AND list_id = ?;`;
        const values = [case_id, list_id];

        const dbRes = await this.db.run(sql, values);

        if (DbService.debugModus) {
            console.log('includeCaseInList', { sql, values, dbRes });
        }

        return dbRes;
    }
    //#endregion
}
