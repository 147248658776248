<div class="flex flex-col h-full w-full">
    <!-- #region Patient Details -->
    <div>
        <!-- Breadcrumbs -->
        <p *ngIf="vcPatientDetails" class="text-gray-600 font-extralight">
            <app-breadcrumbs
                [place]="vcPatientDetails.current_place"
                (clickOnBreadcrumb)="onClickOnBreadcrumb($event)"
            ></app-breadcrumbs>
        </p>

        <div *ngIf="vcPatientDetails" class="flex flex-col">
            <div class="flex flex-col">
                <p>
                    <span class="font-bold">
                        {{ vcPatientDetails?.patient?.data?.surname ?? '' }},
                        {{ vcPatientDetails?.patient?.data?.name ?? '' }},
                    </span>
                    <span> {{ 'GENERAL.' + (vcPatientDetails?.patient?.data?.gender ?? '') | translate }}, </span>
                    <span>
                        {{ vcPatientDetails?.patient?.data?.birth_date | date: ('GENERAL.date_format' | translate) }}
                    </span>
                    <span> ({{ vcPatientDetails?.patient?.data?.birth_date | calAge }}), </span>
                    <span>
                        A:{{
                            vcPatientDetails?.case?.data?.admission_date | date: ('GENERAL.date_format' | translate)
                        }},
                    </span>
                    <span> {{ 'COMPONENT.PAGE_PATIENT_LIST.planned_discharge_date' | translate }}: </span>
                    <span>
                        {{ vcPatientDetails?.case?.data?.discharge_date | date: ('GENERAL.date_format' | translate) }}
                    </span>
                    <span> ({{ vcPatientDetails?.case?.data?.discharge_date | dayAgo }}) </span>
                </p>
                <p class="font-extralight diagnosis-title">
                    {{ vcPatientDetails?.last_diagnosis?.data?.diagnose ?? '' }}
                </p>
            </div>
        </div>
    </div>
    <!-- #endregion -->

    <!-- #region Tab Group -->
    <div class="flex-grow flex flex-col bg-white shadow-md overflow-hidden">
        <mat-tab-group
            (selectedTabChange)="changeTab($event)"
            [selectedIndex]="currentTab"
            animationDuration="0ms"
            class="h-full flex flex-col"
        >
            <!-- #region Overview Tab -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex gap-1 items-center">
                        <mat-icon *ngIf="currentTab === 0">done</mat-icon>
                        <span>{{ 'COMPONENT.PATIENT_DETAILS.overview' | translate }}</span>
                    </div>
                </ng-template>
                <div class="h-full overflow-auto">
                    <app-patient-overview
                        (changedOverviewData)="onOverviewDataChange($event)"
                        *ngIf="currentTab === 0 && vcPatientDetails"
                        [caseOverview]="vcCaseOverview"
                        [patientDetailsI18n]="vcPatientDetails?.patient?.i18n"
                        [patientNameAndDob]="patientNameAndDob"
                    ></app-patient-overview>
                </div>
            </mat-tab>
            <!-- #endregion -->

            <!-- #region Curve Tab -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex gap-1 items-center">
                        <mat-icon *ngIf="currentTab === 1">done</mat-icon>
                        <span>{{ 'COMPONENT.PATIENT_DETAILS.curve' | translate }}</span>
                    </div>
                </ng-template>

                <div class="h-full">
                    <app-patient-curve
                        (groupsChange)="onCurveGroupsChange($event)"
                        *ngIf="currentTab === 1"
                        [patientNameAndDob]="patientNameAndDob"
                        [vcGroups]="vc_CurveGroups"
                    ></app-patient-curve>
                </div>
            </mat-tab>
            <!-- #endregion -->

            <!-- #region Record Tab -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex gap-1 items-center">
                        <mat-icon *ngIf="currentTab === 2">done</mat-icon>
                        <span>{{ 'COMPONENT.PATIENT_DETAILS.file' | translate }}</span>
                    </div>
                </ng-template>
                <div class="h-full">
                    <!--<app-patient-records [patientRecords]="findings"></app-patient-records>-->

                    <patient-files
                        (clickOnFullScreen)="onClickOnFullScreen($event)"
                        (deletedFinding)="deleteFinding($event)"
                        (isMetaDataViewChanged)="
                            onMetaDataViewOpenedChanged($event)
                        "
                        (isPaintingToolOpenedChanged)="
                            onPaintingToolOpenedChanged($event)
                        "
                        (newTextDocument)="onNewTextDocument($event)"
                        *ngIf="currentTab === 2"
                        [areas]="areas"
                        [findings]="findings"
                        [isFullscreenForImageEnabled]="isFullscreenForImageEnabled()"
                        [isMetaDataViewOpened]="isMetaDataViewOpened()"
                        [isPaintingToolOpened]="isPaintingToolOpened()"
                        [patientNameAndDob]="patientNameAndDob"
                        [records]="records"
                        [selectedRecord]="selectedRecord"
                        [subAreas]="subAreas"
                        [vcFindings]="vcFindings"
                        [vcLabor]="vcLabor"
                    ></patient-files>
                </div>
            </mat-tab>
            <!-- #endregion -->

            <!-- #region Workflow Tab -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex gap-1 items-center">
                        <mat-icon *ngIf="currentTab === 3">done</mat-icon>
                        <span>{{ 'COMPONENT.PATIENT_DETAILS.workflow' | translate }}</span>
                    </div>
                </ng-template>

                <div class="h-full">
                    <app-workflow-tabs
                        *ngIf="currentTab === 3"
                        [filterValue]="filterValue"
                        [workflowDataPatients]="patientWorkflow!"
                    ></app-workflow-tabs>
                </div>
            </mat-tab>
            <!-- #endregion -->

            <!-- #region Communication Tab -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="flex gap-1 items-center">
                        <mat-icon *ngIf="currentTab === 4">done</mat-icon>
                        <span>{{ 'COMPONENT.PATIENT_DETAILS.communication' | translate }}</span>
                    </div>
                </ng-template>

                <div class="h-full">
                    <app-communication
                        (sendMessage)="onCommunicationSendMessage($event)"
                        [caseOverview]="vcCaseOverview"
                        [messages]="vc_CommunicationMessages"
                        [user]="user"
                    ></app-communication>
                </div>
            </mat-tab>
            <!-- #endregion -->
        </mat-tab-group>
    </div>
    <!-- #endregion -->
</div>
