import {Injectable} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {DataRepositoryService} from '../../services/datarepository.service';

@Injectable()
export class AreaEffects {

    /*loadAreas$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AreaActions.loadAreas),
            switchMap(() =>
                from(this.repo.getAreas()).pipe(
                    map(areas => AreaActions.loadAreasSuccess({areas})),
                    catchError(error => of(AreaActions.loadAreasFailure({error})))
                )
            )
        )
    );

    addArea$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AreaActions.addArea),
            switchMap(action =>
                from(this.repo.addArea(action.area)).pipe(
                    map(area => AreaActions.addAreaSuccess({area})),
                    catchError(error => of(AreaActions.addAreaFailure({error})))
                )
            )
        )
    );

    updateArea$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AreaActions.updateArea),
            switchMap(action =>
                from(this.repo.updateArea(action.area)).pipe(
                    map(area => AreaActions.updateAreaSuccess({area})),
                    catchError(error => of(AreaActions.updateAreaFailure({error})))
                )
            )
        )
    );

    removeArea$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AreaActions.removeArea),
            switchMap(action =>
                from(this.repo.removeArea(action.areaId)).pipe(
                    map(() => AreaActions.removeAreaSuccess({areaId: action.areaId})),
                    catchError(error => of(AreaActions.removeAreaFailure({error})))
                )
            )
        )
    );

    loadSubAreas$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AreaActions.loadSubAreas),
            switchMap(() =>
                from(this.repo.getSubAreas()).pipe(
                    map(subAreas => AreaActions.loadSubAreasSuccess({subAreas})),
                    catchError(error => of(AreaActions.loadSubAreasFailure({error})))
                )
            )
        )
    );

    addSubArea$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AreaActions.addSubArea),
            switchMap(action =>
                from(this.repo.addSubArea(action.subArea)).pipe(
                    map(subArea => AreaActions.addSubAreaSuccess({subArea})),
                    catchError(error => of(AreaActions.addSubAreaFailure({error})))
                )
            )
        )
    );

    updateSubArea$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AreaActions.updateSubArea),
            switchMap(action =>
                from(this.repo.updateSubArea(action.subArea)).pipe(
                    map(subArea => AreaActions.updateSubAreaSuccess({subArea})),
                    catchError(error => of(AreaActions.updateSubAreaFailure({error})))
                )
            )
        )
    );

    removeSubArea$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AreaActions.removeSubArea),
            switchMap(action =>
                from(this.repo.removeSubArea(action.subAreaId)).pipe(
                    map(() => AreaActions.removeSubAreaSuccess({subAreaId: action.subAreaId})),
                    catchError(error => of(AreaActions.removeSubAreaFailure({error})))
                )
            )
        )
    );*/

    constructor(
        private actions$: Actions,
        private repo: DataRepositoryService
    ) {
    }
}
