import { cache, utilities } from '@cornerstonejs/core';
import { isVolumeSegmentation } from './stackVolumeCheck';
const { VoxelManager } = utilities;
function getStrategyData({ operationData, viewport }) {
    let segmentationImageData, segmentationScalarData, imageScalarData;
    let imageDimensions;
    let segmentationDimensions;
    let imageVoxelManager;
    let segmentationVoxelManager;
    if (isVolumeSegmentation(operationData, viewport)) {
        const { volumeId, referencedVolumeId } = operationData;
        const segmentationVolume = cache.getVolume(volumeId);
        if (!segmentationVolume) {
            return;
        }
        segmentationVoxelManager = segmentationVolume.voxelManager;
        if (referencedVolumeId) {
            const imageVolume = cache.getVolume(referencedVolumeId);
            imageScalarData = imageVolume.getScalarData();
            imageDimensions = imageVolume.dimensions;
        }
        ({ imageData: segmentationImageData } = segmentationVolume);
        segmentationScalarData = segmentationVolume.getScalarData();
        segmentationDimensions = segmentationVolume.dimensions;
    }
    else {
        const { imageIdReferenceMap, segmentationRepresentationUID } = operationData;
        if (!imageIdReferenceMap) {
            return;
        }
        const currentImageId = viewport.getCurrentImageId();
        if (!currentImageId) {
            return;
        }
        const actor = viewport.getActor(segmentationRepresentationUID);
        if (!actor) {
            return;
        }
        segmentationImageData = actor.actor.getMapper().getInputData();
        segmentationVoxelManager = segmentationImageData.voxelManager;
        const currentSegmentationImageId = imageIdReferenceMap.get(currentImageId);
        const segmentationImage = cache.getImage(currentSegmentationImageId);
        if (!segmentationImage) {
            return;
        }
        segmentationScalarData = segmentationImage.getPixelData?.();
        const image = cache.getImage(currentImageId);
        const imageData = image ? null : viewport.getImageData();
        imageScalarData = image?.getPixelData() || imageData.getScalarData();
        imageDimensions = image
            ? [image.columns, image.rows, 1]
            : imageData.dimensions;
        segmentationDimensions = [
            segmentationImage.columns,
            segmentationImage.rows,
            1,
        ];
        imageVoxelManager = image?.voxelManager;
    }
    segmentationVoxelManager ||= VoxelManager.createVolumeVoxelManager(segmentationDimensions, segmentationScalarData);
    imageVoxelManager ||=
        imageDimensions &&
            VoxelManager.createVolumeVoxelManager(imageDimensions, imageScalarData);
    return {
        segmentationImageData,
        segmentationScalarData,
        segmentationVoxelManager,
        imageScalarData,
        imageVoxelManager,
    };
}
export { getStrategyData };
