<table>
    <!-- Header row (dates) -->
    <tr>
        <!-- Top left cell -->
        <td class="sticky top-0 z-40 bg-white gap-3 max-w-max w-[1%]">
            <div class="flex flex-row justify-between items-center max-w-max">
                <!-- Browse buttons and filter dropdown -->
                <div class="min-w-[16em]">
                    <!-- Day browse buttons -->
                    <div class="flex flex-row justify-between items-center">
                        <button
                            (click)="onClickOnMoveIndex(5)"
                            aria-label="5 Tage zurück"
                            mat-icon-button
                            matTooltip="Eine Woche zurück"
                        >
                            <mat-icon>keyboard_double_arrow_left</mat-icon>
                        </button>

                        <button
                            (click)="onClickOnMoveIndex(1)"
                            aria-label="Ein Tag zurück"
                            mat-icon-button
                            matTooltip="Ein Tag zurück"
                        >
                            <mat-icon>keyboard_arrow_left</mat-icon>
                        </button>

                        <button
                            (click)="onClickOnMoveIndex(-1)"
                            aria-label="Ein Tag vorwärts"
                            mat-icon-button
                            matTooltip="Ein Tag vorwärts"
                        >
                            <mat-icon>keyboard_arrow_right</mat-icon>
                        </button>

                        <button
                            (click)="onClickOnMoveIndex(-5)"
                            aria-label="5 Tage vorwärts"
                            mat-icon-button
                            matTooltip="5 Tage vorwärts"
                        >
                            <mat-icon>keyboard_double_arrow_right</mat-icon>
                        </button>

                        <button
                            (click)="onClickOnResetIndex()"
                            aria-label="Heute"
                            mat-icon-button
                            matTooltip="Heute"
                        >
                            <mat-icon>last_page</mat-icon>
                        </button>
                    </div>

                    <!-- Filter dropdown-->
                    <mat-select
                        [(ngModel)]="filterOption"
                        class="lab-table-viewer-filter-select"
                    >
                        <mat-option value="all">Alle</mat-option>
                        <mat-option value="important">Wichtige</mat-option>
                        <mat-option
                            *ngFor="let group of tableData.groups"
                            [value]="group.label"
                        >
                            {{ group.label }}
                        </mat-option>
                    </mat-select>
                </div>

                <!-- Show limits toggle -->
                <button
                    (click)="onClickOnShowLimitsToggle()"
                    *ngIf="showLimitsToggleButton"
                    [matTooltip]="'Normalwerte ' + (showLimits ? 'ausblenden' : 'anzeigen')"
                    mat-icon-button
                >
                    <mat-icon *ngIf="showLimits">toggle_on</mat-icon>
                    <mat-icon *ngIf="!showLimits">toggle_off</mat-icon>
                </button>
            </div>
        </td>

        <td *ngFor="let d of datesArrayToShow" class="lab-header-date-cell">
            {{ d | date : "dd.MM.YY" }}
            <br />
            {{ d | date : "HH:mm" }}
        </td>
    </tr>

    <!-- Other rows -->
    <ng-container *ngFor="let group of tableData.groups">
        <!-- Group separator row -->
        <tr
            *ngIf="isShowGroup(group)"
            [ngStyle]="{
                background:
                    'var(--group-color-' +
                    group.color +
                    ', var(--color-background-primary-color, #eaddff))'
            }"
            class="lab-table-group-header-row"
        >
            <td class="lab-table-first-column">
                {{ group.label }}
            </td>
            <td [attr.colspan]="datesArrayToShow.length">&nbsp;</td>
        </tr>

        <!-- Value rows -->
        <ng-container *ngFor="let row of group.rows">
            <tr *ngIf="isShowGroup(group) && !isEmptyRow(row)">
                <!-- First column (row reader)-->
                <td class="lab-table-first-column">
                    {{ row.label }}
                    <span
                        *ngIf="showLimits"
                        class="lab-table-first-column-limits"
                    >
                        {{ row.limits }} {{ row.unit }}
                    </span>
                </td>

                <!-- Normal cells (values)-->
                <td
                    *ngFor="let cell of row.cells"
                    [ngClass]="getClassesForCell(cell)"
                    class="lab-table-normal-cell"
                >
                    {{ cell.value | stringReplace : "." : "," }}
                </td>
            </tr>
        </ng-container
        >
    </ng-container>
</table>
