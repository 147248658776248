import { cache, eventTarget, getRenderingEngines, } from '@cornerstonejs/core';
import { Events, SegmentationRepresentations } from '../../enums';
import addSegmentationRepresentations from './addSegmentationRepresentations';
import { triggerSegmentationRender, createImageIdReferenceMap, } from '../../utilities/segmentation';
import { getSegmentation } from './segmentationState';
import { triggerSegmentationDataModified } from './triggerSegmentationEvents';
export async function computeStackSegmentationFromVolume({ volumeId, }) {
    const segmentationVolume = cache.getVolume(volumeId);
    const hasCachedImages = segmentationVolume.imageCacheOffsetMap.size > 0;
    let isAllImagesCached = false;
    if (hasCachedImages) {
        isAllImagesCached = segmentationVolume.imageIds.every((imageId) => cache.getImage(imageId));
    }
    const renderingEngine = getRenderingEngines()[0];
    const volumeUsedInOtherViewports = renderingEngine
        .getVolumeViewports()
        .find((vp) => vp.hasVolumeId(volumeId));
    segmentationVolume.decache(!volumeUsedInOtherViewports && isAllImagesCached);
    const imageIdReferenceMap = _getImageIdReferenceMapForStackSegmentation(segmentationVolume);
    return { imageIdReferenceMap };
}
export async function convertVolumeToStackSegmentation({ segmentationId, options, }) {
    const segmentation = getSegmentation(segmentationId);
    const data = segmentation.representationData
        .LABELMAP;
    const { imageIdReferenceMap } = await computeStackSegmentationFromVolume({
        volumeId: data.volumeId,
    });
    await updateStackSegmentationState({
        segmentationId,
        toolGroupId: options.toolGroupId,
        imageIdReferenceMap,
        options,
    });
}
export async function updateStackSegmentationState({ segmentationId, toolGroupId, imageIdReferenceMap, options, }) {
    const segmentation = getSegmentation(segmentationId);
    if (options?.removeOriginal) {
        const data = segmentation.representationData
            .LABELMAP;
        if (cache.getVolume(data.volumeId)) {
            cache.removeVolumeLoadObject(data.volumeId);
        }
        segmentation.representationData.LABELMAP = {
            imageIdReferenceMap,
        };
    }
    else {
        segmentation.representationData.LABELMAP = {
            ...segmentation.representationData.LABELMAP,
            imageIdReferenceMap,
        };
    }
    await addSegmentationRepresentations(toolGroupId, [
        {
            segmentationId,
            type: SegmentationRepresentations.Labelmap,
        },
    ]);
    triggerSegmentationRender(toolGroupId);
    eventTarget.addEventListenerOnce(Events.SEGMENTATION_RENDERED, () => triggerSegmentationDataModified(segmentationId));
}
function _getImageIdReferenceMapForStackSegmentation(segmentationVolume) {
    if (segmentationVolume.additionalDetails?.imageIdReferenceMap) {
        return segmentationVolume.additionalDetails.imageIdReferenceMap;
    }
    else if (segmentationVolume.referencedImageIds?.length &&
        !segmentationVolume.referencedImageIds[0].startsWith('derived')) {
        const referencedImageIds = segmentationVolume.referencedImageIds;
        const segmentationImageIds = segmentationVolume.imageIds;
        return createImageIdReferenceMap(referencedImageIds, [...segmentationImageIds].reverse());
    }
    else {
        const referencedVolumeId = segmentationVolume.referencedVolumeId;
        const referencedVolume = cache.getVolume(referencedVolumeId);
        if (!referencedVolume) {
            throw new Error('Cannot convert volumetric segmentation without referenced volume to stack segmentation yet');
        }
        if (!referencedVolume?.imageIds?.length) {
            throw new Error('Cannot convert volumetric segmentation without imageIds to stack segmentation yet');
        }
        if (referencedVolume.imageIds?.[0].startsWith('derived')) {
            throw new Error(`Cannot convert volume segmentation that is derived from another segmentation
         to stack segmentation yet, include the additionalDetails.imageIdReferenceMap
         in the volume segmentation in case you need it for the conversion`);
        }
        const referencedImageIds = referencedVolume.imageIds;
        let segmentationImageIdsToUse = segmentationVolume.imageIds;
        if (!segmentationImageIdsToUse?.length) {
            segmentationImageIdsToUse =
                segmentationVolume.convertToImageSlicesAndCache();
        }
        return createImageIdReferenceMap(referencedImageIds, [...segmentationImageIdsToUse].reverse());
    }
}
