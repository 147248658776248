import {AccessFacadeService} from '../../../services/facades/access-facade.service';
import {Cp2ApiService} from '../../../services/cp2-api.service';
import {Component, OnInit} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MatListModule} from '@angular/material/list';
import {DynamicListComponent} from '../../../components/data-interaction/dynamic-list/dynamic-list.component';
import {DayAgoPipe} from '../../../pipes/day-ago.pipe';
import {FormioModule} from '@formio/angular';
import {FormioRendererComponent} from '../../../components/data-interaction/formio-renderer/formio-renderer.component';
import {firstValueFrom} from 'rxjs';
import {PatientListGroup, VcPatientListItem} from '../../../models/view-content.models/view-content.model';
import dayjs from 'dayjs';
import {ToolboxService} from '../../../services/toolbox.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {TDocumentDefinitions} from 'pdfmake/interfaces';

// pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'app-test-sandbox',
    templateUrl: './test-sandbox.component.html',
    standalone: true,
    styleUrls: ['./test-sandbox.component.scss'],
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatButtonModule,
        MatCardModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        DynamicListComponent,
        DayAgoPipe,
        FormioModule,
        FormioRendererComponent
    ]
})
export class TestSandboxComponent implements OnInit {
    private static readonly TAG = 'TestSandboxComponent';

    public accessToken$ = this.accessFacade.userTokens$;
    public caseList: PatientListGroup[] = [];

    public constructor(
        private api: Cp2ApiService,
        private accessFacade: AccessFacadeService,
        private tb: ToolboxService
    ) {
    }

    public async ngOnInit() {
    }
}
