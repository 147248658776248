import { volumeLoader, utilities as csUtils, eventTarget, cache, } from '@cornerstonejs/core';
import { Events, SegmentationRepresentations } from '../../enums';
import addSegmentationRepresentations from './addSegmentationRepresentations';
import { triggerSegmentationRender } from '../../utilities/segmentation';
import { getSegmentation } from './segmentationState';
import { triggerSegmentationDataModified } from './triggerSegmentationEvents';
async function computeVolumeSegmentationFromStack({ imageIdReferenceMap, options, }) {
    const segmentationImageIds = Array.from(imageIdReferenceMap.values());
    const additionalDetails = {
        imageIdReferenceMap,
    };
    const volumeId = options?.volumeId ?? csUtils.uuidv4();
    await volumeLoader.createAndCacheVolumeFromImages(volumeId, segmentationImageIds, {
        additionalDetails,
    });
    return { volumeId };
}
async function convertStackToVolumeSegmentation({ segmentationId, options, }) {
    const segmentation = getSegmentation(segmentationId);
    const data = segmentation.representationData
        .LABELMAP;
    const { volumeId } = await computeVolumeSegmentationFromStack({
        imageIdReferenceMap: data.imageIdReferenceMap,
        options,
    });
    await updateSegmentationState({
        segmentationId,
        toolGroupId: options.toolGroupId,
        options,
        volumeId,
    });
}
async function updateSegmentationState({ segmentationId, toolGroupId, volumeId, options, }) {
    const segmentation = getSegmentation(segmentationId);
    if (options?.removeOriginal) {
        const data = segmentation.representationData
            .LABELMAP;
        const imageIdReferenceMap = data.imageIdReferenceMap;
        Array.from(imageIdReferenceMap.values()).forEach((imageId) => {
            cache.removeImageLoadObject(imageId);
        });
        segmentation.representationData.LABELMAP = {
            volumeId,
        };
    }
    else {
        segmentation.representationData.LABELMAP = {
            ...segmentation.representationData.LABELMAP,
            volumeId,
        };
    }
    await addSegmentationRepresentations(toolGroupId, [
        {
            segmentationId,
            type: SegmentationRepresentations.Labelmap,
        },
    ]);
    triggerSegmentationRender(toolGroupId);
    eventTarget.addEventListenerOnce(Events.SEGMENTATION_RENDERED, () => triggerSegmentationDataModified(segmentationId));
}
export { convertStackToVolumeSegmentation, computeVolumeSegmentationFromStack };
