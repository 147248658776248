import {AfterViewInit, Component, ElementRef, Input, SimpleChanges, ViewChild} from '@angular/core';
import {getDocument, PDFDocumentProxy} from 'pdfjs-dist';
import {DocumentInitParameters, TypedArray} from 'pdfjs-dist/types/src/display/api';

@Component({
    selector: 'app-pdf-viewer',
    standalone: true,
    template: '<div #container></div>'
})
export class PdfViewerComponent implements AfterViewInit {
    @ViewChild('container') container!: ElementRef;

    @Input({required: true}) src!: string | URL | TypedArray | ArrayBuffer | DocumentInitParameters;

    ngOnChanges(changes: SimpleChanges) {
        if (changes['src'] && !changes['src'].isFirstChange()) {
            this.renderPdf();
        }
    }

    ngAfterViewInit() {
        this.renderPdf();
    }

    private renderPdf() {
        this.container.nativeElement.innerHTML = '';
        if (typeof this.src === 'string' && this.src.startsWith('JVB')) {
            // Handle base64-encoded PDF
            const uint8Array = this.base64ToUint8Array(this.src);
            this.loadDocument(uint8Array);
        } else {
            this.loadDocument(this.src);
        }
    }

    private base64ToUint8Array(base64: string): Uint8Array {
        const raw = atob(base64);
        const uint8Array = new Uint8Array(raw.length);
        for (let i = 0; i < raw.length; i++) {
            uint8Array[i] = raw.charCodeAt(i);
        }
        return uint8Array;
    }

    private loadDocument(src: TypedArray | ArrayBuffer | DocumentInitParameters) {
        const loadingTask = getDocument(src);

        loadingTask.promise.then((pdf: PDFDocumentProxy) => {
            const numPages = pdf.numPages;

            for (let pageNum = 1; pageNum <= numPages; pageNum++) {
                pdf.getPage(pageNum).then((page) => {
                    const containerWidth = this.container.nativeElement.clientWidth;
                    const scale = containerWidth / page.getViewport({scale: 1}).width;
                    const viewport = page.getViewport({scale});

                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    this.container.nativeElement.appendChild(canvas);

                    if (context) {
                        const renderContext = {
                            canvasContext: context,
                            viewport: viewport
                        };
                        page.render(renderContext);
                    }
                });
            }
        });
    }
}
