<mat-toolbar>
    <button (click)="onZoomIn()" mat-icon-button matTooltip="Vergrößern">
        <mat-icon>zoom_in</mat-icon>
    </button>
    <button (click)="onZoomOut()" mat-icon-button matTooltip="Verkleinern">
        <mat-icon>zoom_out</mat-icon>
    </button>
    <button (click)="onReset()" mat-icon-button matTooltip="Zurücksetzen">
        <mat-icon>refresh</mat-icon>
    </button>

    <button (click)="onPreviousImage()" mat-icon-button
            matTooltip="Vorheriges Bild">
        <mat-icon>navigate_before</mat-icon>
    </button>
    <button (click)="onNextImage()" mat-icon-button
            matTooltip="Nächstes Bild">
        <mat-icon>navigate_next</mat-icon>
    </button>
    <!--<button (click)="onShowMetaData()" mat-icon-button
            matTooltip="Metadaten anzeigen">
        <mat-icon>info</mat-icon>
    </button>-->

    <button (click)="toggleMetaDataOverlay()" mat-icon-button
            matTooltip="Metadaten anzeigen">
        <mat-icon>info</mat-icon>
    </button>

    <button (click)="onScreenShot()" mat-icon-button matTooltip="Screenshot">
        <mat-icon>camera_alt</mat-icon>
    </button>


    @if (!fullscreen) {
        <button mat-icon-button (click)="onFullScreen()"
                matTooltip="Vollbild">
            <mat-icon>fullscreen</mat-icon>
        </button>
    }
</mat-toolbar>
<!--    <div id="topleft" class="overlay" style="position:absolute;top:0px;left:0px">
        Patient Name
    </div>
    <div id="topright" class="overlay" style="position:absolute;top:0px;right:0px">
        Render Time:
    </div>-->
<div (dragover)="$event.preventDefault()" [id]="viewportUUID" [ngClass]="{'fullscreen-content-container': fullscreen, 'content-container': !fullscreen}" oncontextmenu="return false"></div>
