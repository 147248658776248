import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ViewContent } from '../../../models/view-content.models/view-content.model';
import { Place } from '../../../models/view-content.models/view-content-organization-domain.model';
import { CommonModule } from '@angular/common';

export interface BreadcrumbParam {
    type: 'department' | 'service_unit' | 'room';
    value: string;
}

@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    standalone: true,
    imports: [CommonModule],
})
export class BreadcrumbsComponent  {
    @Input() place: ViewContent<Place> | undefined;

    @Output() clickOnBreadcrumb = new EventEmitter<BreadcrumbParam>();

    public constructor() {}

    public onClickOnBreadcrumb(type: 'department' | 'service_unit' | 'room', value: string): void {
        this.clickOnBreadcrumb.emit({type, value});
    }
}
