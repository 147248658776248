"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.state = void 0;
const state = {
    viewport: {},
};
exports.state = state;
function default_1(viewport) {
    state.viewport = viewport || {};
}
exports.default = default_1;
