<div class="patient-visit-info h-full !items-center">
    <app-dynamic-list
        [clipToWhenSmall]="2"
        [isClipped]="isClipped"
        [list]="[patient?.visit_record?.data]"
        [showButton]="false"
        class="w-full !h-fit"
    >
        <ng-template #itemTemplate let-item="item">
            <mat-list
                (click)="onClickOnElement(item, getPatientNameAndDob())"
            >
                <div class="patient-visit-name table-text">
                    {{ item.note }}
                </div>

                <div class="table-date patient-visit-date">
                    {{
                        item.date_and_time
                            | date : ("GENERAL.date_time_format" | translate)
                    }}
                </div>
            </mat-list>
        </ng-template>
    </app-dynamic-list>
    <!-- button for open a new task dialog-->
    <div class="patient-visit-info-button">
        <button
            (click)="openFormDialog('form_visit.json', getPatientNameAndDob())"
            mat-icon-button
        >
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>
