import { cache, getEnabledElementByIds, utilities as csUtils, VolumeViewport, } from '@cornerstonejs/core';
import * as SegmentationState from '../../../stateManagement/segmentation/segmentationState';
import { getToolGroup } from '../../../store/ToolGroupManager';
const onLabelmapSegmentationDataModified = function (evt) {
    const { segmentationId, modifiedSlicesToUse } = evt.detail;
    const { representationData, type } = SegmentationState.getSegmentation(segmentationId);
    const toolGroupIds = SegmentationState.getToolGroupIdsWithSegmentation(segmentationId);
    const labelmapRepresentationData = representationData[type];
    if ('volumeId' in labelmapRepresentationData) {
        performVolumeLabelmapUpdate({
            modifiedSlicesToUse,
            representationData,
            type,
        });
    }
    if ('imageIdReferenceMap' in labelmapRepresentationData) {
        performStackLabelmapUpdate({
            toolGroupIds,
            segmentationId,
            representationData,
            type,
        });
    }
};
function performVolumeLabelmapUpdate({ modifiedSlicesToUse, representationData, type, }) {
    const segmentationVolume = cache.getVolume(representationData[type].volumeId);
    if (!segmentationVolume) {
        console.warn('segmentation not found in cache');
        return;
    }
    const { imageData, vtkOpenGLTexture } = segmentationVolume;
    let slicesToUpdate;
    if (modifiedSlicesToUse && Array.isArray(modifiedSlicesToUse)) {
        slicesToUpdate = modifiedSlicesToUse;
    }
    else {
        const numSlices = imageData.getDimensions()[2];
        slicesToUpdate = [...Array(numSlices).keys()];
    }
    slicesToUpdate.forEach((i) => {
        vtkOpenGLTexture.setUpdatedFrame(i);
    });
    imageData.modified();
}
function performStackLabelmapUpdate({ toolGroupIds, segmentationId, representationData, type, }) {
    toolGroupIds.forEach((toolGroupId) => {
        const toolGroupSegmentationRepresentations = SegmentationState.getSegmentationRepresentations(toolGroupId);
        const toolGroup = getToolGroup(toolGroupId);
        const viewportsInfo = toolGroup.getViewportsInfo();
        toolGroupSegmentationRepresentations.forEach((representation) => {
            if (representation.segmentationId !== segmentationId) {
                return;
            }
            viewportsInfo.forEach(({ viewportId, renderingEngineId }) => {
                const viewport = getEnabledElementByIds(viewportId, renderingEngineId).viewport;
                if (viewport instanceof VolumeViewport) {
                    return;
                }
                const actorEntry = viewport.getActor(representation.segmentationRepresentationUID);
                if (!actorEntry) {
                    return;
                }
                const currentImageId = viewport.getCurrentImageId();
                const segImageData = actorEntry.actor.getMapper().getInputData();
                const { imageIdReferenceMap } = representationData[type];
                const currentSegmentationImageId = imageIdReferenceMap.get(currentImageId);
                const segmentationImage = cache.getImage(currentSegmentationImageId);
                segImageData.modified();
                csUtils.updateVTKImageDataWithCornerstoneImage(segImageData, segmentationImage);
            });
        });
    });
}
export default onLabelmapSegmentationDataModified;
