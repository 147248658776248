<h1 class="bg-white shadow p-2 text-xl">
    {{ 'COMPONENT.SETTINGS.header' | translate }}
</h1>

<mat-list>
    <mat-accordion>
        <div mat-subheader>Oberfläche</div>

        <!-- Language -->
        <mat-expansion-panel class="!bg-white !shadow">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon class="mr-2">translate</mat-icon>
                    {{ 'COMPONENT.SETTINGS.LANGUAGE.language_label' | translate }}
                </mat-panel-title>
                <mat-panel-description style="justify-content: flex-end">
                    {{ 'GENERAL.language_name_' + selectedLanguage | translate }}
                </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-selection-list (selectionChange)="onSettingLangChange($event)" [multiple]="false">
                <mat-list-option *ngFor="let l of languages" [selected]="selectedLanguage === l.lang" [value]="l.lang">
                    {{ l.title }}
                </mat-list-option>
            </mat-selection-list>
        </mat-expansion-panel>

        <!-- Theme Change -->
        <mat-expansion-panel class="!bg-white !shadow">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon class="mr-2">palette</mat-icon>
                    {{ 'COMPONENT.SETTINGS.theme_change' | translate }}
                </mat-panel-title>
                <mat-panel-description style="justify-content: flex-end"></mat-panel-description>
            </mat-expansion-panel-header>
            <mat-selection-list (selectionChange)="changeColorScheme($event)" [multiple]="false">
                <mat-list-option
                    *ngFor="let colorScheme of colorSchemes"
                    [selected]="selectedColorSchemas === colorScheme.color"
                    [value]="colorScheme.color"
                >
                    {{ colorScheme.label }}
                </mat-list-option>
            </mat-selection-list>
        </mat-expansion-panel>

        <!-- Anonymize Data -->
        <mat-list-item
            class="!bg-white !shadow !cursor-pointer hover:!bg-[var(--mat-expansion-header-hover-state-layer-color)]"
        >
            <mat-checkbox
                (ngModelChange)="onAnonymizeDataClick($event)"
                [(ngModel)]="activeAnonymous"
                class="setting-anonym-data !bg-white hover:!bg-transparent"
                labelPosition="before"
            >
                <mat-icon class="mr-2">theater_comedy</mat-icon>
                {{ 'COMPONENT.SETTINGS.anonymize_data' | translate }}
            </mat-checkbox>
        </mat-list-item>
    </mat-accordion>

    <mat-accordion>
        <div mat-subheader>Daten</div>

        <!-- Synchronization -->
        <mat-expansion-panel class="!bg-white !shadow">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon class="mr-2">sync</mat-icon>
                    {{ 'COMPONENT.SETTINGS.label_synchronization' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-selection-list>
                <mat-list-option
                    (selectedChange)="onSyncItemChange($event, item)"
                    *ngFor="let item of syncArray"
                    [selected]="item.selected"
                >
                    {{ item.name }}
                </mat-list-option>
            </mat-selection-list>
        </mat-expansion-panel>

        <!-- Interaction Modules -->
        <mat-expansion-panel class="!bg-white !shadow">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon class="mr-2">view_module</mat-icon>
                    {{ 'COMPONENT.SETTINGS.label_interaction_modules' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list>
                <mat-list-item *ngFor="let item of interactionModule">{{ item.name }} </mat-list-item>
            </mat-list>
        </mat-expansion-panel>

        <!-- Reset DB -->
        <mat-list-item
            class="shadow cursor-pointer hover:!bg-[var(--mat-expansion-header-hover-state-layer-color)]"
            (click)="onClickOnResetDb()"
        >
            <div class="flex row justify-between align-baseline">
                <span class="flex row align-baseline">
                    <mat-icon class="mr-2">restart_alt</mat-icon>
                    {{ 'COMPONENT.SETTINGS.reset_local_db' | translate }}
                </span>

                <mat-icon>chevron_right</mat-icon>
            </div>
        </mat-list-item>

        <!-- Simulate offline -->
        <mat-list-item
            class="!bg-white !shadow !cursor-pointer hover:!bg-[var(--mat-expansion-header-hover-state-layer-color)]"
        >
            <mat-checkbox
                (ngModelChange)="onSimulateOfflineChange($event)"
                [ngModel]="(settings$ | async)?.simulateOffline"
                class="setting-anonym-data !bg-white hover:!bg-transparent"
                labelPosition="before"
            >
                <mat-icon class="mr-2">flight</mat-icon>
                {{ 'COMPONENT.SETTINGS.simulate_offline' | translate }}
            </mat-checkbox>
        </mat-list-item>
    </mat-accordion>
</mat-list>

<div class="spacer-xy">&nbsp;</div>

<div class="settings-app-version">Checkpad MED 2 {{ appVersion }}</div>

<div class="settings-app-version">Build Zeitpunkt: {{ buildTime | date: 'dd.MM.YYYY, HH:mm' }}</div>
