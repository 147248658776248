import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { LuicTextEditorComponent } from '@lohmann-birkner/luic';
import { JSONContent } from '@tiptap/core';
import htmlToPdfmake from 'html-to-pdfmake';
import { MatInputModule } from '@angular/material/input';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { Area, SubArea } from '../../../models/patient-records.model';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { Cp2ApiService } from '../../../services/cp2-api.service';
import * as jose from 'jose';
import { AccessFacadeService } from '../../../services/facades/access-facade.service';
import { Subscription } from 'rxjs';
import { UserToken } from '../../../models/auth.model';

import * as pdfMake from 'pdfmake/build/pdfmake';
import { MatSnackBar } from '@angular/material/snack-bar';

// (<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

export interface TextEditorModalData {
    caseId: string;
    areas?: Area[];
}

export interface TextEditorModalResult {
    role: 'cancel' | 'save';
    data: {
        area?: Area;
        subarea?: SubArea;
        title?: string;
        content: string;
    };
}

const SNACK_BAR_DURATION = 2000;

@Component({
    selector: 'app-text-editor-bridge',
    templateUrl: './text-editor-bridge.component.html',
    styleUrls: ['./text-editor-bridge.component.scss'],
    standalone: true,
    imports: [CommonModule, FormsModule, LuicTextEditorComponent, MatButtonModule, MatFormFieldModule, MatIcon, MatInputModule, MatSelectModule, ReactiveFormsModule],
    encapsulation: ViewEncapsulation.None,
})
export class TextEditorBridgeComponent implements OnInit, OnDestroy {
    documentTitle: string = '';

    public content: string | JSONContent | undefined;
    public areas: Area[] = [];
    public subAreas: SubArea[] = [];

    public selectedArea: Area | undefined;
    public selectedSubArea: SubArea | undefined;
    private allSubs: Subscription[] = [];
    private token: UserToken | undefined;

    public constructor(
        private dialogRef: MatDialogRef<TextEditorModalResult>,
        private api: Cp2ApiService,
        private accessFacade: AccessFacadeService,
        private snackBar: MatSnackBar
    ) {}

    public async ngOnInit() {
        this.allSubs.push(
            this.accessFacade.userTokens$.subscribe(async (t) => {
                if (!t?.token) return;

                const { access_token } = t.token;
                const decodedToken = jose.decodeJwt(access_token);

                this.token = t.token;
            })
        );

        if (this.token) {
            await Promise.all([(this.areas = await this.api.getAllAreas(this.token?.access_token)), (this.subAreas = await this.api.getAllSubAreas(this.token?.access_token))]);
        }
    }

    /**
     * Cleans up resources on component destruction.
     */
    public ngOnDestroy(): void {
        this.allSubs.forEach((s) => s.unsubscribe());
    }

    //#region Listeners

    public onClickOnSave() {
        if (!this.documentTitle) {
            this.snackBar.open('Bitte geben Sie für das Dokument ein Titel an.', 'Close', {
                duration: SNACK_BAR_DURATION,
                panelClass: ['error-snackbar'],
            });
            return;
        }

        if (typeof this.content !== 'string') {
            throw new Error('JSONContent is currently not supported');
        }

        const docDefinition: TDocumentDefinitions = {
            content: htmlToPdfmake(this.content ?? ''),
            info: {
                title: this.documentTitle,
            },
        };

        this.createPdfBase64(docDefinition).then((base64) => {
            const result: TextEditorModalResult = {
                role: 'save',
                data: {
                    content: base64,
                    area: this.selectedArea,
                    subarea: this.selectedSubArea,
                    title: this.documentTitle,
                },
            };
            this.dialogRef.close(result);
        });
    }

    public onClickOnCancel() {
        this.dialogRef.close({ role: 'cancel', content: null });
    }

    public filterRelatedSubAreas() {
        return this.subAreas.filter((subArea: SubArea) => {
            return subArea.region === this.selectedArea?.id;
        });
    }

    private createPdfBase64(docDefinition: any): Promise<string> {
        const fonts = {
            Roboto: {
                normal: `${window.location.origin}/assets/fonts/Roboto/Roboto-Regular.ttf`,
                bold: `${window.location.origin}/assets/fonts/Roboto/Roboto-Bold.ttf`,
                italics: `${window.location.origin}/assets/fonts/Roboto/Roboto-Italic.ttf`,
                bolditalics: `${window.location.origin}/assets/fonts/Roboto/Roboto-BoldItalic.ttf`,
            },
            Arial: {
                normal: `${window.location.origin}/assets/fonts/arial/ARIAL.TTF`,
                bold: `${window.location.origin}/assets/fonts/arial/ARIALBD.TTF`,
                italics: `${window.location.origin}/assets/fonts/arial/ARIALI.TTF`,
                bolditalics: `${window.location.origin}/assets/fonts/arial/ARIALBI.TTF`,
            },
            // Calibri: {
            //     normal: `${window.location.origin}/assets/fonts/calibri/CALIBRI.TTF`,
            //     bold: `${window.location.origin}/assets/fonts/calibri/CALIBRI-Bold.TTF`,
            //     italics: `${window.location.origin}/assets/fonts/calibri/CALIBRI-Italic.TTF`,
            //     bolditalics: `${window.location.origin}/assets/fonts/calibri/CALIBRI-BoldItalic.TTF`,
            // },
            Courier: {
                normal: 'Courier',
                bold: 'Courier-Bold',
                italics: 'Courier-Oblique',
                bolditalics: 'Courier-BoldOblique',
            },
            Georgia: {
                normal: `${window.location.origin}/assets/fonts/georgia/georgia.ttf`,
                bold: `${window.location.origin}/assets/fonts/georgia/georgiab.ttf`,
                italics: `${window.location.origin}/assets/fonts/georgia/georgiai.ttf`,
                bolditalics: `${window.location.origin}/assets/fonts/georgia/georgiaz.ttf`,
            },
            Helvetica: {
                normal: 'Helvetica',
                bold: 'Helvetica-Bold',
                italics: 'Helvetica-Oblique',
                bolditalics: 'Helvetica-BoldOblique',
            },
            // 'Lucida Console': {
            //     normal: `${window.location.origin}/assets/fonts/lucida-console/LUCIDA-CONSOLE.T`F',
            //     bold: `${window.location.origin}/assets/fonts/lucida-console/LUCIDA-CONSOLE-Bo`d.TTF',
            //     italics: `${window.location.origin}/assets/fonts/lucida-console/LUCIDA-CONSOLE-Ital`c.TTF',
            //     bolditalics: `${window.location.origin}/assets/fonts/lucida-console/LUCIDA-CONSOLE-BoldItal`c.TTF',
            // },
            // 'Lucida Sans Unicode': {
            //     normal: `${window.location.origin}/assets/fonts/lucida-sans-unicode/LUCIDA-SA`S-UNICODE.TTF',
            //     bold: `${window.location.origin}/assets/fonts/lucida-sans-unicode/LUCIDA-SA`S-UNICODE-Bold.TTF',
            //     italics: `${window.location.origin}/assets/fonts/lucida-sans-unicode/LUCIDA-SA`S-UNICODE-Italic.TTF',
            //     bolditalics: `${window.location.origin}/assets/fonts/lucida-sans-unicode/LUCIDA-SA`S-UNICODE-BoldItalic.TTF',
            // },
            'Times New Roman': {
                normal: `${window.location.origin}/assets/fonts/times-new-roman/times-new-roman.ttf`,
                bold: `${window.location.origin}/assets/fonts/times-new-roman/times-new-romanb.ttf`,
                italics: `${window.location.origin}/assets/fonts/times-new-roman/times-new-romani.ttf`,
                bolditalics: `${window.location.origin}/assets/fonts/times-new-roman/times-new-romanbi.ttf`,
            },
            Verdana: {
                normal: `${window.location.origin}/assets/fonts/Verdana/Verdana.ttf`,
                bold: `${window.location.origin}/assets/fonts/Verdana/Verdana Bold.ttf`,
                italics: `${window.location.origin}/assets/fonts/Verdana/Verdana Italic.ttf`,
                bolditalics: `${window.location.origin}/assets/fonts/Verdana/Verdana Bold Italic.ttf`,
            },
        };

        return new Promise((resolve) => {
            pdfMake.createPdf(docDefinition, undefined, fonts).getBase64((base64) => {
                resolve(base64);
            });
        });
    }

    //#endregion
}
