<mat-dialog-content>
    <luic-painting-tool (canvasStateSave)="painting($event)" *ngIf="data.img && Object.keys(data.img).length > 0"
                        [imageFormat]="data.img"></luic-painting-tool>
    <div class="luic-container">
    </div>
</mat-dialog-content>

<mat-card-actions class="flex items-end justify-end  gap-2">
    <button (click)="onClickOnCancel()" mat-stroked-button>Abbrechen</button>
    <button (click)="onClickOnSave()" mat-flat-button>Speichern</button>
</mat-card-actions>
