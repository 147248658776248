<mat-sidenav-container [hasBackdrop]="true">
    <mat-sidenav
        #sidenav
        [disableClose]="true"
        class="min-w-[64px] max-w-[250px] !rounded-none sidenav-shadow h-[calc(100vh-64px)] !flex !flex-col justify-between !z-50 py-2 !bg-white"
        mode="over"
    >
        <mat-nav-list>
            <!-- Top section -->
            <mat-card (click)="toggleTooltip($event, UserInfo)" class="sidebar-up-name-wrapper !shadow-none !px-2 mb-4 !bg-white cursor-pointer">
                <div class="avatar-wrapper">
                    <!-- User avatar with dynamic background image -->
                    <div [style.background-image]="'url(' + userData.photoLink + ')'" class="avatar">
                        {{ getInitials(userData.name) }}
                    </div>
                </div>
                <p class="sidebar-up-name text-center" matTooltip="{{ userData.name }}">
                    {{ userData.name }}
                </p>
            </mat-card>

            <mat-list-item (click)="sidenav.toggle()" class="text-center items-center text-gray-800">
                <div class="flex flex-row gap-2 items-center">
                    <mat-icon
                        [ngClass]="{ rotated: !sidenav.opened }"
                        aria-label="toggle_menu"
                        class="align-middle cursor-pointer toggle_menu"
                        mat-list-icon
                    >
                        {{ toggleButton.icon_name }}
                    </mat-icon>
                    <span class="text-sm" translate="{{ toggleButton.label | translate }}"></span>
                </div>
            </mat-list-item>

            <ng-container *ngFor="let button of topButtons">
                <mat-list-item (click)="onClickOnItem(button)" class="text-center">
                    <div [ngClass]="{ '!text-gray-400': button.disabled }" class="flex flex-row gap-2 items-center text-gray-800">
                        <mat-icon
                            [attr.aria-label]="button.name"
                            [ngClass]="{ rotated: !sidenav.opened }"
                            class="align-middle cursor-pointer"
                            mat-list-icon
                        >{{ button.icon_name }}
                        </mat-icon>
                        <span class="text-sm" translate="{{ button.label | translate }}"></span>
                    </div>
                </mat-list-item>
            </ng-container>
        </mat-nav-list>

        <!-- Middle section (can be empty or contain additional items) -->
        <div class="flex-grow"></div>

        <mat-nav-list>
            <!-- Bottom section -->
            <ng-container *ngFor="let button of bottomButtons">
                <mat-list-item (click)="onClickOnItem(button)" class="text-center">
                    <div [ngClass]="{ '!text-gray-500': button.disabled }" class="flex flex-row gap-2 items-center text-gray-800">
                        <mat-icon
                            [attr.aria-label]="button.name"
                            [ngClass]="{ rotated: !sidenav.opened }"
                            class="align-middle cursor-pointer"
                            mat-list-icon
                        >{{ button.icon_name }}
                        </mat-icon>
                        <span class="text-sm" translate="{{ button.label | translate }}"></span>
                    </div>
                </mat-list-item>
            </ng-container>
        </mat-nav-list>
    </mat-sidenav>

    <div
        *ngIf="currentPath !== '/login'"
        class="flex flex-row h-[calc(100vh-64px)] bg-[var(--color-background-primary-color)]"
    >
        <div
            class="sidenav-shadow max-w-[64px] min-w-[64px] flex flex-col justify-between z-40 py-2 overflow-y-auto bg-white"
        >
            <mat-nav-list>
                <!-- Top section -->
                <mat-card (click)="toggleTooltip($event, UserInfo)" class="sidebar-up-name-wrapper !shadow-none !px-2 mb-4 !bg-white cursor-pointer">
                    <div class="avatar-wrapper">
                        <!-- User avatar with dynamic background image -->
                        <div [style.background-image]="'url(' + userData.photoLink + ')'" class="avatar">
                            {{ getInitials(userData.name) }}
                        </div>
                    </div>
                    <p class="sidebar-up-name text-center" matTooltip="{{ userData.name }}">
                        {{ userData.name }}
                    </p>
                </mat-card>

                <mat-list-item (click)="sidenav.toggle()" class="text-center items-center text-gray-800">
                    <mat-icon
                        [ngClass]="{ rotated: !sidenav.opened }"
                        aria-label="toggle_menu"
                        class="align-middle cursor-pointer toggle_menu"
                        mat-list-icon
                    >
                        {{ toggleButton.icon_name }}
                    </mat-icon>
                </mat-list-item>

                <ng-container *ngFor="let button of topButtons">
                    <mat-list-item (click)="onClickOnItem(button)" class="text-center">
                        <div [ngClass]="{ '!text-gray-400': button.disabled }" class="text-gray-800">
                            <mat-icon
                                [attr.aria-label]="button.name"
                                [ngClass]="{ rotated: !sidenav.opened }"
                                class="align-middle cursor-pointer"
                                mat-list-icon
                            >{{ button.icon_name }}
                            </mat-icon>
                        </div>
                    </mat-list-item>
                </ng-container>
            </mat-nav-list>

            <!-- Middle section (can be empty or contain additional items) -->
            <div class="flex-grow"></div>

            <!-- Bottom section -->
            <mat-nav-list>
                <div class="sidebar-cache-sync-indicator">
                    <mat-icon *ngIf="vcCache.isSyncingDownloads$ | async">download</mat-icon>
                    <mat-icon *ngIf="vcCache.isSyncingUploads$ | async">upload</mat-icon>
                </div>
                <mat-list-item (click)="toggleTooltip($event, SEEStatus)" class="!p-0" matTooltip="Verlauf anzeigen">
                    <div
                        [ngClass]="{ '!text-left': sidenav.opened }"
                        class="flex flex-col text-sm text-center font-light"
                    >
                        <div><span class="font-bold text-gray-700">W:</span> {{ locatorStatus.remaining }}</div>
                        <div><span class="font-bold text-gray-700">V:</span> {{ locatorStatus.processed }}</div>
                    </div>
                </mat-list-item>

                <mat-list-item (click)="toggleTooltip($event, DeviceStatus)" matTooltip="Geräteinformationen anzeigen">
                    <app-device-registered-indicator></app-device-registered-indicator>
                </mat-list-item>

                <mat-list-item>
                    <app-network-quality></app-network-quality>
                </mat-list-item>
                <ng-container *ngFor="let button of bottomButtons">
                    <mat-list-item (click)="onClickOnItem(button)" class="text-center">
                        <div [ngClass]="{ '!text-gray-400': button.disabled }" class="text-gray-800">
                            <mat-icon
                                [attr.aria-label]="button.name"
                                [ngClass]="{ rotated: !sidenav.opened }"
                                class="align-middle cursor-pointer"
                                mat-list-icon
                            >{{ button.icon_name }}
                            </mat-icon>
                        </div>
                    </mat-list-item>
                </ng-container>
            </mat-nav-list>
        </div>
        <router-outlet></router-outlet>
    </div>
    <router-outlet *ngIf="currentPath === '/login'"></router-outlet>
</mat-sidenav-container>

<ng-template #SEEStatus>
    <div class="flex flex-col gap-2">
        <div class="flex flex-col gap-1 text-sm">
            <div class="flex flex-row justify-between items-center">
                <div>Status</div>
                <mat-icon (click)="deleteLocatorQueueeInLS()" class="text-red-600 cursor-pointer">delete</mat-icon>
            </div>
            <div class="font-light"><span class="font-bold text-gray-700">Warteschlange:</span> {{ locatorStatus.remaining }}</div>
            <div class="font-light"><span class="font-bold text-gray-700">Verarbeitet:</span> {{ locatorStatus.processed }}</div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex flex-col gap-1 text-sm">
            <div>Log</div>
            <div class="max-h-40 w-full overflow-y-auto pr-2 min-w-60">
                <div *ngFor="let log of locatorStatus.locatorLog">
                    <div
                        *ngIf="log.locator"
                        [ngClass]="{ '!text-[#27ae60]': log.success }"
                        class="text-sm font-light text-[#c0392b]"
                    >
                        {{ log.locator }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #DeviceStatus>
    <div class="flex flex-col gap-2">
        <div class="flex gap-1 items-center">
            <span class="text-gray-800">Ger&auml;te ID:</span>
            <span class="text-sm font-light">{{ deviceId }}</span>
        </div>
        <div class="flex gap-1 items-center">
            <span class="text-gray-800">Anzeigename:</span>
            <span class="text-sm font-light">{{ displayName }}</span>
        </div>
    </div>
</ng-template>

<ng-template #UserInfo>
    <div class="flex flex-col gap-2">
        <div>Comming soon...</div>
    </div>
</ng-template>
