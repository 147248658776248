<ng-container *ngFor="let item of listToShow">
    <div *ngTemplateOutlet="template; context: { item: item }"></div>
</ng-container>

<div *ngIf="!isClipped" [@inOutExpandY] style="overflow: hidden">
    <ng-container *ngFor="let item of list.slice(clipToWhenSmall, list.length)">
        <ng-container
            *ngTemplateOutlet="template; context: { item: item }"
        ></ng-container>
    </ng-container>
</div>
<div (click)="toggleClip()" *ngIf="list && list.length > clipToWhenSmall && showButton" class="show-more font-light text-sm">
    {{ (isClipped ? "COMPONENT.DYNAMIC_LIST.button_text_show_more" : "COMPONENT.DYNAMIC_LIST.button_text_show_less") | translate }}
</div>
