import {
    Component,
    EventEmitter,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    signal,
    SimpleChanges,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FullscreenDialogComponent } from '../../modals/fullscreen-dialog/fullscreen-dialog.component';
import { convertMultiframeImageIds, prefetchMetadataInformation } from '../../shared/convertMultiframeImageIds';
import { ImageService } from './helper/image.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Finding } from '../../models/patient-records.model';
import { v4 as uuidv4 } from 'uuid';
import { NgClass } from '@angular/common';
import { MetadataService } from './helper/metadata.service';
import dayjs from 'dayjs';
import { firstValueFrom } from 'rxjs';

export interface MetaDataDialogData {
    patient: {
        patientName: string;
        patientID: string;
        patientBirthDate: string;
        patientSex: string;
    };
    radiation: {
        seriesInstanceUID: string;
        seriesNumber: string;
        imageNumber: string;
    };
    deviceInformation: {
        manufacturer: string; // Hersteller
        manufacturerModelName: string; // Modellname des Herstellers
    };
}

@Component({
    selector: 'app-dicom-viewer',
    templateUrl: './dicom-viewer.component.html',
    imports: [MatButtonModule, MatIconModule, MatToolbarModule, MatTooltipModule, NgClass],
    standalone: true,
    styleUrls: ['./dicom-viewer.component.scss'],
})
export class DicomViewerComponent implements OnDestroy, OnChanges {
    @Input() base64: string | undefined;
    @Input() finding: Finding = {} as Finding;

    @Output() changedFinding: EventEmitter<Finding> = new EventEmitter<Finding>();

    @Input() fullscreen: boolean = false;

    public viewportUUID = 'viewportId-' + uuidv4();
    public metaData = signal<MetaDataDialogData | undefined>(undefined);
    private blob: Blob | undefined;
    private readonly dialog = inject(MatDialog);
    private readonly imageService = new ImageService(this.viewportUUID, this.metaDataService);

    constructor(public metaDataService: MetadataService) {}

    // private readonly accessFacade = inject(AccessFacadeService);
    // private documentService = inject(DocsService);
    // private http = inject(HttpClient);

    async ngOnChanges(changes: SimpleChanges) {
        if (changes['base64']?.firstChange) {
            await this.imageService.initCornerstoneDICOMImageLoader();
        }
        if (changes['base64'] && this.base64) {
            this.blob = this.base64ToBlob(this.base64);
            if (this.blob) {
                await this.proccessFile(this.blob);
            }
        }
    }

    async proccessFile(file: any) {
        const imageId = this.imageService.getImageIdFromFile(file);

        await prefetchMetadataInformation([imageId]);
        const stack = convertMultiframeImageIds([imageId]);

        const dataSet = await this.imageService.loadImage(imageId);

        this.metaData.set({
            patient: {
                patientName: dataSet.data.string('x00100010'), // Patient Name,
                patientID: dataSet.data.string('x00100020'), // Patient ID
                patientBirthDate: dataSet.data.string('x00100030'), // Patient Birth Date
                patientSex: dataSet.data.string('x00100040'), // Patient Sex
            },
            radiation: {
                seriesInstanceUID: dataSet.data.string('x0020000E'), // Series Instance UID
                seriesNumber: dataSet.data.string('x00200011'), // Series Number
                imageNumber: dataSet.data.string('x00200013'), // Image Number
            },
            deviceInformation: {
                manufacturer: dataSet.data.string('x00080070'), // Manufacturer
                manufacturerModelName: dataSet.data.string('x00081090'), // Manufacturer Model Name
            },
        });

        await this.imageService.loadImageOnViewport(stack);
        this.loadMedtaDataOverlay();
    }

    ngOnDestroy(): void {
        this.imageService.clean();
    }

    public onZoomIn() {
        this.imageService.zoomIn();
    }

    public onZoomOut() {
        this.imageService.zoomOut();
    }

    public onReset() {
        this.imageService.resetZoom();
    }

    public onNextImage() {
        this.imageService.nextImage();
    }

    public onPreviousImage() {
        this.imageService.previousImage();
    }

    /*public onShowMetaData() {
        const dialogRef = this.dialog.open(MetadataComponent, {
            data: {
                ...this.metaData(),
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log(`Dialog result: ${result}`);
        });
    }*/

    public async onScreenShot() {
        const finding = JSON.parse(JSON.stringify(this.finding));
        const res = await this.imageService.screenShot(finding);
        //        console.log({ res });
        if (res) {
            finding.base64 = res;
            finding.documentType = 'image';
            finding.documentPath = '';
        }
        this.changedFinding.emit(finding);
    }

    public async onFullScreen() {
        const dialogRef = this.dialog.open(FullscreenDialogComponent, {
            data: {
                data: {
                    documentType: 'dicom',
                    documentBase64: this.base64,
                },
                fullScreen: true,
            },
            width: 'calc(100% - 2rem)',
            height: 'calc(100% - 2rem)',
        });

        const data = await firstValueFrom(dialogRef.afterClosed());

        await this.imageService.initCornerstoneDICOMImageLoader();
        await this.proccessFile(this.blob);
    }

    public toggleMetaDataOverlay() {
        this.metaDataService.viewMetaData = !this.metaDataService.viewMetaData;
        this.loadMedtaDataOverlay();
    }

    private loadMedtaDataOverlay() {
        const canvasElements = document.getElementsByClassName('cornerstone-canvas');
        const wrapperID = `wrapper_${this.viewportUUID}`;
        for (const el of canvasElements) {
            const canvas = el as HTMLElement;

            if (canvas) {
                const parent = canvas.parentNode;

                if (this.metaDataService.viewMetaData) {
                    const width = canvas.style.width;
                    const height = canvas.style.height;
                    const wrapperDiv = document.createElement('div');
                    wrapperDiv.classList.add('metadata-wrapper');
                    wrapperDiv.id = wrapperID;
                    wrapperDiv.style.position = 'relative';
                    wrapperDiv.style.width = width;
                    wrapperDiv.style.height = height;

                    if (parent) {
                        parent.removeChild(canvas);
                        wrapperDiv.appendChild(canvas);

                        const metadataConfig = [
                            {
                                metaData: `Bildnr.: ${this.metaData()?.radiation?.imageNumber ?? '-'}`,
                                position: 'top-left',
                                order: 1,
                                textColor: 'yellow',
                            },
                            {
                                metaData: `Seriennr.: ${this.metaData()?.radiation?.seriesNumber ?? '-'}`,
                                position: 'top-left',
                                order: 2,
                                textColor: 'white',
                            },
                            {
                                metaData: `Serieninst.: ${this.metaData()?.radiation?.seriesInstanceUID ?? '-'}`,
                                position: 'top-left',
                                order: 3,
                                textColor: 'white',
                            },
                            {
                                metaData: `Pat.-ID: ${this.metaData()?.patient?.patientID ?? '-'}`,
                                position: 'top-right',
                                order: 1,
                                textColor: 'white',
                            },
                            {
                                metaData: `Pat.-Name: ${this.metaData()?.patient?.patientName ?? '-'}`,
                                position: 'top-right',
                                order: 2,
                                textColor: 'yellow',
                            },
                            {
                                metaData: `Geschl.: ${this.metaData()?.patient?.patientSex === 'F' ? 'Weiblich' : ('Männlich' ?? '-')}`,
                                position: 'top-right',
                                order: 3,
                                textColor: 'white',
                            },
                            {
                                metaData: `Geb.-Datum: ${dayjs(this.metaData()?.patient?.patientBirthDate, 'YYYYMMDD').format('DD.MM.YYYY') ?? '-'}`,
                                position: 'top-right',
                                order: 4,
                                textColor: 'white',
                            },

                            {
                                metaData: `Herst.: ${this.metaData()?.deviceInformation?.manufacturer ?? '-'}`,
                                position: 'bottom-left',
                                order: 1,
                                textColor: 'white',
                            },
                            {
                                metaData: `Modellnr.: ${this.metaData()?.deviceInformation?.manufacturerModelName ?? '-'}`,
                                position: 'bottom-left',
                                order: 2,
                                textColor: 'white',
                            },
                        ];

                        metadataConfig.forEach((item) => {
                            const textElement = document.createElement('div');
                            textElement.innerText = item.metaData;
                            textElement.style.position = 'absolute';
                            textElement.style.color = item.textColor;
                            textElement.style.fontSize = '.8rem';
                            textElement.style.fontWeight = 'light';
                            textElement.style.fontFamily = "'Roboto', Sans-serif";

                            switch (item.position) {
                                case 'top-left':
                                    textElement.style.left = '.5rem';
                                    textElement.style.top = `${(item.order - 1) * 1.5 + 0.5}rem`;
                                    break;
                                case 'top-right':
                                    textElement.style.right = '.5rem';
                                    textElement.style.top = `${(item.order - 1) * 1.5 + 0.5}rem`;
                                    break;
                                case 'bottom-left':
                                    textElement.style.left = '.5rem';
                                    textElement.style.bottom = `${(2 - item.order) * 1.5 + 0.5}rem`;
                                    break;
                                case 'bottom-right':
                                    textElement.style.right = '.5rem';
                                    textElement.style.bottom = `${(2 - item.order) * 1.5 + 0.5}rem`;
                                    break;
                            }

                            wrapperDiv.appendChild(textElement);
                        });

                        parent.appendChild(wrapperDiv);
                    }
                } else {
                    const existingWrapper = document.querySelector('.metadata-wrapper');

                    if (existingWrapper && existingWrapper.parentNode) {
                        existingWrapper.parentNode.replaceChild(canvas, existingWrapper);
                    }
                }
            } else {
                console.error('Canvas-Element mit der Klasse "cornerstone-canvas" wurde nicht gefunden.');
            }
        }
    }

    private base64ToBlob(base64: string) {
        base64 = base64.replace(/\s/g, '');

        const padding = '='.repeat((4 - (base64.length % 4)) % 4);
        const cleanedBase64 = base64 + padding;

        const fixedBase64 = cleanedBase64.replace(/-/g, '+').replace(/_/g, '/');

        try {
            const byteCharacters = atob(fixedBase64);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            return new Blob([byteArray]);
        } catch (e) {
            console.error('Error decoding Base64:', e);
            return undefined;
        }
    }
}
