import { getEnabledElement } from '@cornerstonejs/core';
import { addAnnotation, getAnnotations, getChildAnnotations, } from '../../stateManagement/annotation/annotationState';
import { drawPath as drawPathSvg } from '../../drawingSvg';
import AnnotationTool from './AnnotationTool';
import { updateContourPolyline } from '../../utilities/contours/';
import { getContourHolesDataCanvas } from '../../utilities/contours';
class ContourBaseTool extends AnnotationTool {
    constructor(toolProps, defaultToolProps) {
        super(toolProps, defaultToolProps);
    }
    renderAnnotation(enabledElement, svgDrawingHelper) {
        let renderStatus = false;
        const { viewport } = enabledElement;
        const { element } = viewport;
        if (!viewport.getRenderingEngine()) {
            console.warn('Rendering Engine has been destroyed');
            return renderStatus;
        }
        let annotations = getAnnotations(this.getToolName(), element);
        if (!annotations?.length) {
            return renderStatus;
        }
        annotations = this.filterInteractableAnnotationsForElement(element, annotations);
        if (!annotations?.length) {
            return renderStatus;
        }
        const targetId = this.getTargetId(viewport);
        const styleSpecifier = {
            toolGroupId: this.toolGroupId,
            toolName: this.getToolName(),
            viewportId: enabledElement.viewport.id,
        };
        for (let i = 0; i < annotations.length; i++) {
            const annotation = annotations[i];
            styleSpecifier.annotationUID = annotation.annotationUID;
            const annotationStyle = this.getAnnotationStyle({
                annotation,
                styleSpecifier,
            });
            if (!annotationStyle.visibility) {
                continue;
            }
            const annotationRendered = this.renderAnnotationInstance({
                enabledElement,
                targetId,
                annotation,
                annotationStyle,
                svgDrawingHelper,
            });
            renderStatus ||= annotationRendered;
            annotation.invalidated = false;
        }
        return renderStatus;
    }
    createAnnotation(evt) {
        const eventDetail = evt.detail;
        const { currentPoints, element } = eventDetail;
        const { world: worldPos } = currentPoints;
        const enabledElement = getEnabledElement(element);
        const { viewport } = enabledElement;
        const camera = viewport.getCamera();
        const { viewPlaneNormal, viewUp, position: cameraPosition } = camera;
        const referencedImageId = this.getReferencedImageId(viewport, worldPos, viewPlaneNormal, viewUp);
        const viewReference = viewport.getViewReference({ points: [worldPos] });
        return {
            highlighted: true,
            invalidated: true,
            metadata: {
                toolName: this.getToolName(),
                ...viewReference,
                referencedImageId,
                viewUp,
                cameraPosition,
            },
            data: {
                handles: {
                    points: [],
                    activeHandleIndex: null,
                    textBox: {
                        hasMoved: false,
                        worldPosition: [0, 0, 0],
                        worldBoundingBox: {
                            topLeft: [0, 0, 0],
                            topRight: [0, 0, 0],
                            bottomLeft: [0, 0, 0],
                            bottomRight: [0, 0, 0],
                        },
                    },
                },
                contour: {
                    polyline: [],
                    closed: false,
                },
            },
            interpolationUID: '',
            autoGenerated: false,
        };
    }
    addAnnotation(annotation, element) {
        return addAnnotation(annotation, element);
    }
    cancelAnnotation(annotation) {
    }
    moveAnnotation(annotation, worldPosDelta) {
        const { points } = annotation.data.handles;
        for (let i = 0, numPoints = points.length; i < numPoints; i++) {
            const point = points[i];
            point[0] += worldPosDelta[0];
            point[1] += worldPosDelta[1];
            point[2] += worldPosDelta[2];
        }
        annotation.invalidated = true;
        getChildAnnotations(annotation).forEach((childAnnotation) => this.moveAnnotation(childAnnotation, worldPosDelta));
    }
    updateContourPolyline(annotation, polylineData, transforms) {
        const decimateConfig = this.configuration?.decimate || {};
        updateContourPolyline(annotation, polylineData, transforms, {
            decimate: {
                enabled: !!decimateConfig.enabled,
                epsilon: decimateConfig.epsilon,
            },
        });
    }
    getPolylinePoints(annotation) {
        return annotation.data.contour?.polyline ?? annotation.data.polyline;
    }
    renderAnnotationInstance(renderContext) {
        const { enabledElement, annotationStyle, svgDrawingHelper } = renderContext;
        const annotation = renderContext.annotation;
        if (annotation.parentAnnotationUID) {
            return;
        }
        const { annotationUID } = annotation;
        const { viewport } = enabledElement;
        const { worldToCanvas } = viewport;
        const polylineCanvasPoints = this.getPolylinePoints(annotation).map((point) => worldToCanvas(point));
        const { lineWidth, lineDash, color, fillColor, fillOpacity } = annotationStyle;
        const childContours = getContourHolesDataCanvas(annotation, viewport);
        const allContours = [polylineCanvasPoints, ...childContours];
        drawPathSvg(svgDrawingHelper, annotationUID, 'contourPolyline', allContours, {
            color,
            lineDash,
            lineWidth: Math.max(0.1, lineWidth),
            fillColor: fillColor,
            fillOpacity,
        });
        return true;
    }
}
export { ContourBaseTool as default, ContourBaseTool };
