<p *ngIf="device$ | async as device">
    <mat-icon
        *ngIf="device.device?.deviceId; else notRegisteredBlock"
        matTooltip="Info about the action"
        >phonelink</mat-icon
    >

    <ng-template #notRegisteredBlock>
        <mat-icon matTooltip="Info about the action" style="color: var(--danger-color, red)">phonelink_off</mat-icon>
    </ng-template>
</p>
