import toolStyle from './ToolStyle';
function getHierarchalPropertyStyles(property, state, mode) {
    const list = [`${property}`];
    if (state) {
        list.push(`${list[0]}${state}`);
    }
    if (mode) {
        list.push(`${list[list.length - 1]}${mode}`);
    }
    return list;
}
function getStyleProperty(property, styleSpecifier, state, mode) {
    const alternatives = getHierarchalPropertyStyles(property, state, mode);
    for (let i = alternatives.length - 1; i >= 0; --i) {
        const style = toolStyle.getStyleProperty(alternatives[i], styleSpecifier);
        if (style !== undefined) {
            return style;
        }
    }
}
export { getStyleProperty };
