import { AuthorizationToken, Device, UserToken } from '../models/auth.model';
import { Injectable } from '@angular/core';
import { DEFAULT_SETTINGS, Settings } from '../models/settings.model';
import { LS_ACCESS_TOKEN, LS_AUTHZ_TOKENS, LS_CODE_VERIFIER, LS_DEVICE, LS_LOCATORS, LS_PATIENT_RECORDS, LS_PATIENT_SELECTED_RECORD, LS_SETTINGS } from '../shared/constants';
import { Preferences } from '@capacitor/preferences';
import { DbService } from './database/db.service';
import { MercureStatus } from '../models/mercure.model';
import { Record } from '../models/patient-records.model';
import { PatientListIdentifier, VcHistoryElement, ViewContent } from '../models/view-content.models/view-content.model';
import { capSQLiteChanges } from '@capacitor-community/sqlite';
import { Locator } from '../models/view-content.model';
import { CP2_User } from '../models/view-content.models/view-content-personal-domain.model';

//
// TODO: BIG TODO!!! Check the use of Access and Authz with care
//

export interface VcPendingPutOperation {
    id: number;
    vcLocator: string;
    user: CP2_User;
    timestamp: string;
}

export interface DocumentPendingPutOperation {
    id: number;
    area: string;
    subarea: string;
    case_id: string;
    documenttext: string;
    filename: string;
    timestamp: string;
    mimetype: string;
}

@Injectable({
    providedIn: 'root',
})
export class DataRepositoryService {
    private static readonly TAG = 'DataRepositoryService';

    public constructor(private db: DbService) {}

    //#region Admin functions
    /** Will completely delete the main app database (named like constants.APP_DB_NAME) */
    public async deleteDatabase(): Promise<void> {
        const res = await this.db.deleteDatabase();
    }

    //#endregion

    //#region Authz
    /**
     * Safely gets all authorization tokens. Will return all saved authz tokens or an empty array in case of error or none
     */
    public async getAllAuthzTokens(): Promise<AuthorizationToken[]> {
        try {
            const v = (await Preferences.get({ key: LS_AUTHZ_TOKENS })).value;

            if (v) return JSON.parse(v);
        } catch (e) {
            console.warn(DataRepositoryService.TAG, 'error retrieving authz tokens', e);
        }

        return [];
    }

    /**
     * Save the given array to the preferences' authorization tokens. Will clean up the expired tokens before save.
     * Will overwrite whatever was in preferences before.
     */
    public async saveAuthzTokens(tokens: AuthorizationToken[]): Promise<void> {
        // Remove tokens that are older than 300 seconds
        const currentTimeInSeconds = Math.floor(Date.now() / 1000);
        tokens = tokens.filter((token) => {
            const expiresIn = token.exp;
            return expiresIn >= 0 && expiresIn <= 300 + currentTimeInSeconds;
        });

        await Preferences.set({
            key: LS_AUTHZ_TOKENS,
            value: JSON.stringify(tokens),
        });
    }

    //#endregion

    //#region Access Token
    public async getAccessToken(): Promise<UserToken | null> {
        try {
            const value = (await Preferences.get({ key: LS_ACCESS_TOKEN })).value;

            return value ? JSON.parse(value) : null;
        } catch (e) {
            console.warn(`${DataRepositoryService.TAG}, error retrieving access token`, e);
            return null;
        }
    }

    public async setAccessToken(token: UserToken | null): Promise<void> {
        if (!token) {
            await Preferences.remove({ key: LS_ACCESS_TOKEN });
            return;
        }

        await Preferences.set({
            key: LS_ACCESS_TOKEN,
            value: JSON.stringify(token),
        });
    }

    public async removeAccessToken(): Promise<void> {
        return await this.setAccessToken(null);
    }

    public async saveCodeVerifier(codeVerifier: string | null): Promise<void> {
        if (!codeVerifier) {
            await Preferences.remove({ key: LS_CODE_VERIFIER });
            return;
        }

        await Preferences.set({
            key: LS_CODE_VERIFIER,
            value: codeVerifier,
        });
    }

    public async getCodeVerifier(): Promise<string | null> {
        try {
            return (await Preferences.get({ key: LS_CODE_VERIFIER })).value;
        } catch (e) {
            console.warn(`${DataRepositoryService.TAG}, error retrieving code verifier`, e);
            return null;
        }
    }

    public async removeCodeVerifier(): Promise<void> {
        try {
            await Preferences.remove({ key: LS_CODE_VERIFIER });
        } catch (e) {
            console.error(`${DataRepositoryService.TAG}, error removing code verifier`, e);
        }
    }

    //#endregion

    //#region settings
    public async saveSettings(settings: Settings): Promise<void> {
        await Preferences.set({
            key: LS_SETTINGS,
            value: JSON.stringify(settings),
        });
    }

    public async getSettings(): Promise<Settings> {
        try {
            const value = (await Preferences.get({ key: LS_SETTINGS })).value;
            return value ? JSON.parse(value) : DEFAULT_SETTINGS;
        } catch (e) {
            console.warn(`${DataRepositoryService.TAG}, error retrieving settings`, e);
            return DEFAULT_SETTINGS;
        }
    }

    //#endregion

    //#region locators

    public async getLocators(): Promise<Locator[]> {
        try {
            const v = (await Preferences.get({ key: LS_LOCATORS })).value;
            if (v) return JSON.parse(v);
        } catch (e) {
            console.warn(DataRepositoryService.TAG, 'error retrieving locators', e);
        }

        return [];
    }

    public async saveLocators(locator: Locator[]): Promise<void> {
        await Preferences.set({
            key: LS_LOCATORS,
            value: JSON.stringify(locator),
        });
    }

    //#endregion

    //#region settings
    public async saveMercure(mercure: MercureStatus): Promise<void> {
        /*  await Preferences.set({
         key: LS_SETTINGS,
         value: JSON.stringify(settings),
         });*/
    }

    public async getMercure(): Promise<any> {
        /* try {
         const value = (await Preferences.get({ key: LS_SETTINGS })).value;
         return value ? JSON.parse(value) : DEFAULT_SETTINGS;
         } catch (e) {
         console.warn(
         `${DataRepositoryService.TAG}, error retrieving settings`,
         e
         );
         return DEFAULT_SETTINGS;
         }*/
    }

    //#endregion

    //#region Device
    public async getDevice(): Promise<Device> {
        try {
            const v = (await Preferences.get({ key: LS_DEVICE })).value;

            if (v) return JSON.parse(v);
        } catch (e) {
            console.warn(DataRepositoryService.TAG, 'error retrieving device id', e);
        }

        return { deviceId: '' };
    }

    public async saveDevice(device: Device): Promise<void> {
        await Preferences.set({
            key: LS_DEVICE,
            value: JSON.stringify(device),
        });
    }

    //#endregion

    //#region patientRecords

    async getRecords(): Promise<Record[]> {
        const { value } = await Preferences.get({ key: LS_PATIENT_RECORDS });
        return value ? JSON.parse(value) : [];
    }

    async setRecords(records: Record[]): Promise<void> {
        await Preferences.set({
            key: LS_PATIENT_RECORDS,
            value: JSON.stringify(records),
        });
    }

    async getSelectedRecord(): Promise<Record | null> {
        const { value } = await Preferences.get({
            key: LS_PATIENT_SELECTED_RECORD,
        });
        return value ? JSON.parse(value) : null;
    }

    async setSelectedRecord(record: Record): Promise<void> {
        await Preferences.set({
            key: LS_PATIENT_SELECTED_RECORD,
            value: JSON.stringify(record),
        });
    }

    async removeSelectedRecord(): Promise<void> {
        await Preferences.remove({ key: LS_PATIENT_SELECTED_RECORD });
    }

    //#endregion

    //#region ViewContent
    public async createOrUpdateViewContent<T>(vc: ViewContent<T>, user: CP2_User): Promise<capSQLiteChanges> {
        const e = await this.getFullViewContentForLocator(vc.locator);

        if (e) return await this.updateViewContent(vc, user);

        return await this.createViewContent(vc, user);
    }

    public async createViewContent<T>(vc: ViewContent<T>, user: CP2_User): Promise<capSQLiteChanges> {
        const dataRes = await this.createVcData(vc.data);
        if (!dataRes?.changes || !dataRes.changes.lastId) throw Error('Error saving VC_Data');

        const vcRes = await this.db.createViewContent(
            vc.locator,
            JSON.stringify(vc.owners),
            JSON.stringify(vc.owner_departments),
            vc.main_owner_job_type,
            vc.created_at,
            vc.status,
            vc.related_patient_id,
            vc.related_case_id,
            dataRes.changes.lastId,
            JSON.stringify(vc.form),
            JSON.stringify(vc.i18n)
        );
        if (!vcRes?.changes || !vcRes.changes.lastId) throw Error('Error saving ViewContent');

        // Store the current data in history with current time
        vc.id = vcRes.changes.lastId;
        const histRes = await this.db.createViewContentHistory(vc.id, dataRes.changes.lastId, new Date().toISOString(), user.uuid);
        if (!histRes?.changes || !histRes.changes.lastId) throw Error('Error saving History');

        return vcRes;
    }

    public async updateViewContent<T>(vc: ViewContent<T>, user: CP2_User): Promise<any> {
        const dataRes = await this.createVcData(vc.data);
        if (!dataRes?.changes || !dataRes.changes.lastId) throw Error('Error saving VC_Data');

        const localVc = await this.getFullViewContentForLocator(vc.locator);
        if (!localVc) throw Error('Error before writing history object for ViewContent ' + vc.locator);

        // TODO: When db.updateViewContent is fixed and works only with locators, get localVc.id from vcRes
        const vcRes = await this.db.updateViewContent(
            localVc.id,
            vc.locator,
            JSON.stringify(vc.owners),
            JSON.stringify(vc.owner_departments),
            vc.main_owner_job_type,
            vc.created_at,
            vc.status,
            vc.related_patient_id,
            vc.related_case_id,
            dataRes.changes.lastId,
            JSON.stringify(vc.form),
            JSON.stringify(vc.i18n)
        );
        if (!vcRes?.changes) throw Error('Error saving ViewContent');

        // Create new user in if necessary
        const userRes = await this.createCP2User(user);

        //Store the current data in history with current time
        const histRes = await this.db.createViewContentHistory(localVc.id, dataRes.changes.lastId, new Date().toISOString(), user.uuid);
        if (!histRes?.changes || !histRes.changes.lastId) throw Error('Error saving History');

        return vcRes;
    }

    public async getFullViewContentForLocator<T>(locator: string): Promise<ViewContent<T> | null> {
        let vc = (await this.db.getViewContentByLocator(locator)).values?.[0];
        if (!vc) {
            console.warn('No ViewContent found for locator=' + locator);
            return null;
        }

        vc = this.parseViewContent(vc);
        const data = await this.getVcData(vc.data_id);
        vc.data = data;

        const history = await this.getVcHistory(vc.id);
        vc.history = history;

        return vc;
    }

    public async getBatchFullViewContentsForLocator<T>(locator: string): Promise<ViewContent<T>[] | null> {
        const allowedLocatorStarts = ['document.dicom', 'document.others', 'labor.results'];
        if (!allowedLocatorStarts.some((e) => locator.startsWith(e)) || locator.split('.').length > 3) throw Error(`Given locator is not allowed for batch fetch: ${locator}`);

        let vcs = (await this.db.getViewContentBatchByLocator(locator)).values;
        if (!vcs) {
            console.warn('No ViewContents found for locator=' + locator);
            return null;
        }

        const res = vcs.map((e) => this.parseViewContent(e) as any);
        for (const vc of res) {
            const data = await this.getVcData(vc.data_id);
            vc.data = data;

            const history = await this.getVcHistory(vc.id);
            vc.history = history;
        }

        return res;
    }

    public async getFullViewContentsWithSimilarLocator<T>(locator: string): Promise<ViewContent<T>[]> {
        // Hol alle ViewContent-Objekte für den Locator (nicht nur das erste)
        const vcs = (await this.db.getViewContentByLocator(locator, true)).values;

        if (!vcs || vcs.length === 0) {
            return [] as ViewContent<T>[];
        }

        // Lade die zusätzlichen Daten für jedes ViewContent-Objekt und speichere es
        const viewContentArray: ViewContent<T>[] = [];
        for (const vc of vcs) {
            const data = await this.getVcData(vc.data_id);
            vc.data = data;
            viewContentArray.push(vc); // Füge das vollständige ViewContent-Objekt dem Array hinzu
        }

        return viewContentArray; // Rückgabe des Arrays mit allen ViewContent-Objekten
    }

    public async getAllLocatorsForCaseId(caseId: string): Promise<string[]> {
        const dbRes = await this.db.getAllLocatorsForCase(caseId);

        return dbRes.values ? dbRes.values.map((e) => e.locator) : [];
    }

    public async getAllCaseId(): Promise<string[]> {
        const dbRes = await this.db.getAllDifferentCaseId();

        return dbRes.values ? dbRes.values.map((e) => e.related_case_id) : [];
    }

    // Hilfsmethoden

    public async getAllEmptyViewContentForCase(caseId: string): Promise<ViewContent<null>[]> {
        const res = await this.db.getAllViewContentForCase(caseId);

        return res.values.map((c: any) => this.parseViewContent(c));
    }

    public async getVcHistory<T>(vcId: number): Promise<VcHistoryElement<T>[]> {
        const res = await this.db.getHistoryForViewContent(vcId);

        // Build an array with the full editors
        const v: any[] = res.values ?? [];
        const editorIds = Array.from(new Set(v.map((item) => item['editor'])));
        const editors: CP2_User[] = [];
        for (const id of editorIds) {
            const ed = await this.getCp2UserById(id);
            if (ed) editors.push(ed);
        }

        if (res.values?.length) {
            const h = res.values.map<VcHistoryElement<T>>((e) => ({
                modifiedAt: e.datetime,
                modifiedBy: editors.find((ed) => ed.uuid === e.editor) ?? ({} as CP2_User),
                data: JSON.parse(e.data),
            }));
            return h;
        }

        return [];
    }

    //#region VC Pending PUT operations
    public async createPendingPutVc(o: VcPendingPutOperation): Promise<capSQLiteChanges> {
        return await this.db.createPendingPutVc(o.vcLocator, JSON.stringify(o.user), o.timestamp);
    }

    public async deletePendingPutVc(id: number): Promise<capSQLiteChanges> {
        return await this.db.deletePendingPutVc(id);
    }

    public async getAllPendingPutVc(): Promise<VcPendingPutOperation[]> {
        const dbRes = await this.db.getAllPendingPutVc();
        if (!dbRes.values) return [];

        return dbRes.values.map((e) => ({
            id: e.id,
            user: JSON.parse(e.userJson),
            vcLocator: e.vcLocator,
            timestamp: e.timestamp,
        }));
    }

    //#endregion

    //#region Document Pending PUT operations
    public async createPendingPutDocument(o: DocumentPendingPutOperation): Promise<capSQLiteChanges> {
        return await this.db.createPendingPutDocument(o.area, o.subarea, o.case_id, o.documenttext, o.filename, o.timestamp);
    }

    public async deletePendingPutDocument(id: number): Promise<capSQLiteChanges> {
        return await this.db.deletePendingPutDocument(id);
    }

    public async getAllPendingPutDocument(): Promise<DocumentPendingPutOperation[]> {
        const dbRes = await this.db.getAllPendingPutDocument();
        if (!dbRes.values) return [];

        return dbRes.values.map((e) => ({
            id: e.id,
            area: e.area,
            subarea: e.subarea,
            case_id: e.case_id,
            documenttext: e.documenttext,
            filename: e.filename,
            timestamp: e.timestamp,
            mimetype: e.mimetype,
        }));
    }

    //#endregion

    //#region Case List operations
    public async saveCaseListIdentifier(o: PatientListIdentifier): Promise<capSQLiteChanges> {
        const l = await this.getCaseListEntriesForNameType(o.name, o.list_type);

        if (l) {
            o.id = l.id;
            return await this.updateCaseListEntry(o);
        }

        return this.createCaseListIdentifier(o);
    }

    public async createCaseListIdentifier(o: PatientListIdentifier): Promise<capSQLiteChanges> {
        return await this.db.createCaseListEntry(o.name, o.list_type);
    }

    public async updateCaseListEntry(o: PatientListIdentifier): Promise<capSQLiteChanges> {
        return await this.db.updateCaseListEntry(o.id, o.name, o.list_type);
    }

    //#endregion

    public async getAllCaseListEntries(): Promise<PatientListIdentifier[]> {
        const dbRes = await this.db.getAllCaseListEntries();
        if (!dbRes.values?.length) return [];

        return dbRes.values.map((e) => ({
            id: e.id,
            name: e.name,
            list_type: e.list_type,
        }));
    }

    public async getCaseListEntriesForNameType(name: string, type: string): Promise<PatientListIdentifier | null> {
        const dbRes = await this.db.getCaseListEntriesForNameType(name, type);
        if (!dbRes.values?.length) return null;

        const v = dbRes.values[0];

        return {
            id: v.id,
            name: v.name,
            list_type: v.list_type,
        };
    }

    public async deleteCaseListEntry(id: number): Promise<capSQLiteChanges> {
        return await this.db.deleteCaseListEntry(id);
    }

    //#endregion

    public async includeCaseInList(case_id: string, list: PatientListIdentifier): Promise<capSQLiteChanges> {
        return await this.db.includeCaseInList(case_id, list.id);
    }

    public async getListIdForCase(caseId: string): Promise<number[]> {
        const dbRes = await this.db.getListIdForCase(caseId);

        if (dbRes.values) return dbRes.values.map((e) => e.list_id);

        return [];
    }

    public async removeCaseFromList(case_id: string, list: PatientListIdentifier): Promise<capSQLiteChanges> {
        return await this.db.removeCaseFromList(case_id, list.id);
    }

    private parseViewContent<T>(vc: any): ViewContent<T> {
        try {
            vc.i18n = vc.i18n ? JSON.parse(vc.i18n) : undefined;
            vc.owner_departments = vc.owner_departments ? JSON.parse(vc.owner_departments) : undefined;
            vc.owners = vc.owners ? JSON.parse(vc.owners) : undefined;
            vc.form = vc.form ? JSON.parse(vc.form) : undefined;
        } catch (e) {
            console.warn('Error parsing View Content', e);
        }
        return vc;
    }

    private async getVcData(id: number): Promise<any> {
        const res = await this.db.getVcDataForId(id);
        if (!res.values?.[0]?.data) return null;

        return JSON.parse(res.values[0].data);
    }

    private async createVcData(data: any): Promise<capSQLiteChanges> {
        return await this.db.createVcData(JSON.stringify(data));
    }

    //#region CP2_User
    private async createCP2User(u: CP2_User): Promise<capSQLiteChanges> {
        return await this.db.createCp2User(u.uuid, u.surname, u.name, u.validSince ?? '', u.validUntil ?? '');
    }

    private async updateCP2User(u: CP2_User): Promise<capSQLiteChanges> {
        return await this.db.updateCp2User(u.uuid, u.surname, u.name, u.validSince ?? '', u.validUntil ?? '');
    }

    private async getCp2UserById(id: string): Promise<CP2_User | null> {
        const res = await this.db.getCp2UserById(id);
        if (!res.values?.[0]) return null;

        return res.values[0];
    }

    //#endregion
}
