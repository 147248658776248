import { Cp2ApiService } from './../cp2-api.service';
import { Injectable } from '@angular/core';
import { DataRepositoryService } from '../datarepository.service';
import { AccessFacadeService } from '../facades/access-facade.service';
import { fromEvent, merge, startWith } from 'rxjs';
import { CP2_User } from '../../models/view-content.models/view-content-personal-domain.model';
import * as jose from 'jose';
import { UserToken } from '../../models/auth.model';
import dayjs from 'dayjs';
import { ApiDocument, DocsService } from '../docs.service';

@Injectable({
    providedIn: 'root',
})
export class RecordDocumentCacheService {
    /** Sets the verbosity level of the service; `true` for high verbosity */
    public static debugMode = false;
    /** Changes the policy on the get methods: if true, will check the API first and then update local DB, if false, will get the local copy and not check the API */
    public static preferApi = true;
    private isOnline = navigator.onLine;
    private userToken: UserToken | undefined;
    private user: CP2_User | undefined;
    private isSyncing = false;

    public constructor(
        private accessFacade: AccessFacadeService,
        private api: Cp2ApiService,
        private docs: DocsService,
        private repo: DataRepositoryService
    ) {
        this.initOnlineStatus();

        this.accessFacade.userTokens$.subscribe((t) => {
            this.log('new userToken value', { t });
            if (!t?.token) {
                this.userToken = undefined;
                this.user = undefined;
                return;
            }
            this.userToken = t.token;

            const decodedToken = jose.decodeJwt(this.userToken.access_token);
            this.user = {
                userId: this.userToken.related_user_id,
                uuid: decodedToken['sub'] ?? '',
                surname: decodedToken['family_name'] as string,
                name: decodedToken['given_name'] as string,
            };

            this.syncPendingPuts().then();
        });
    }

    public async saveDocument<T>(document: ApiDocument): Promise<any> {
        if (this.isOnline) {
            try {
                // Write the viewContent in the API and update the DB with what the API delivers back
                if (!this.userToken?.access_token) {
                    throw Error('No token available to save Document');
                }
                const apiResult = await this.docs.createDocument(this.userToken.access_token, document);
                const newLocator = `document.others.${document.case_id}.${apiResult.data.data.id}`;

                // TODO das kann so nicht funktionieren!!!
                // Ticket CS-834
                // es ist ein Timinig-Problem. Der VC wird im Backend asynchron produziert.
                // das bedeutet, es vergeht zwischen dem Save (erzeugen des Dokumentes in der Tabelle docs) und dem produzieren des VC eine
                // gewisse Zeit > 0. Das bedeutet, der VC ist noch gar nicht fertig, wenn er hier wieder gelesen werden soll
                // -> der VC kommt erst im Client an, wenn der locator mit dem VC eintrifft.
                //
                // ***** hier ist der Fehler *****
                // const apiVc = await this.api.getVcForLocator<T>(newLocator, this.userToken.access_token);
                //

                // Du könntest local einen VC mit dem locator produzieren und den in den Cache einfügen und dann würde die Ansicht sofort aktualisiert werden
                // damit wir offline-fähig sind, muss das auch so gebaut werden
                // jetzt klappt das erstmal, weil der locator ja per mercure ankommt
                //
                const apiVc = undefined
                this.log('saveViewContent: online PUT', { apiResult, apiVc });

                if (this.user && apiVc) {
                    const repoRes = await this.repo.createOrUpdateViewContent<T>(apiVc, this.user);
                    this.log('saveDocument: repo create/update', {
                        repoRes,
                    });
                }
                return apiResult;
            } catch (e) {
                // If there is something wrong, at least update DB
                console.warn('Error putting Document to API', e);
                if (this.user) {
                    const pendingPutRes = await this.createPendingPutEntry(document);

                    this.log('saveViewContent: online error', {
                        pendingPutRes,
                    });
                }

                return document;
            }
        } else {
            if (this.user) {
                const dbr2 = await this.createPendingPutEntry(document);

                this.log('saveViewContent: offline', { dbr2 });
            }
        }

        return;
    }

    private async createPendingPutEntry<T>(d: ApiDocument): Promise<void> {
        // Create the pending PUT entry
        if (this.user) {
            const pendingPutRes = await this.repo.createPendingPutDocument({
                id: -1,
                area: d.area ?? '',
                subarea: d.subarea ?? '',
                case_id: d.case_id,
                documenttext: d.documenttext ?? '',
                filename: d.filename,
                timestamp: dayjs().toISOString(),
                mimetype: d.mimetype,
            });
            this.log('createPendingPutEntry: Created pending PUT entry in repo', {
                pendingPutRes,
            });
        }
    }

    private initOnlineStatus(): void {
        // Listen to the online and offline events from the window object
        const online$ = fromEvent(window, 'online').pipe(startWith(navigator.onLine));
        const offline$ = fromEvent(window, 'offline').pipe(startWith(!navigator.onLine));

        // Merge online and offline observables and update this.isOnline
        merge(online$, offline$).subscribe(() => {
            this.isOnline = navigator.onLine;

            if (this.isOnline) this.syncPendingPuts().then();
        });
    }

    private async syncPendingPuts(): Promise<void> {
        if (this.isSyncing) {
            this.log('RecordDocumentCacheService already syncing. New sync cancelled.');
            return;
        }
        this.isSyncing = true;

        try {
            // Get all pending operations and log the result
            const pendingPuts = await this.repo.getAllPendingPutDocument();
            this.log('syncPendingPuts: Fetched all pending PUT operations', {
                pendingPuts,
            });

            for (const e of pendingPuts) {
                if (this.userToken) {
                    try {
                        // Send the PUT request to the API and log the response
                        const apiRes = await this.docs.createDocument(this.userToken?.access_token, e);
                        this.log('syncPendingPuts: Sent PUT request to API', {
                            apiRes,
                        });
                        const newLocator = `document.others.${e.case_id}.${apiRes.data.data.id}`;

                        if (RecordDocumentCacheService.preferApi) {
                            const apiVc = await this.api.getVcForLocator(newLocator, this.userToken.access_token);
                            if (this.user && apiVc) {
                                const repoRes = await this.repo.createOrUpdateViewContent(apiVc, this.user);
                                this.log('saveDocument: repo create/update', {
                                    repoRes,
                                });
                            }
                        }

                        // Delete the pending PUT from the database and log the response
                        const dbDeleteRes = await this.repo.deletePendingPutDocument(e.id);
                        this.log('syncPendingPuts: Deleted pending PUT entry from repo', { dbDeleteRes });
                    } catch (error) {
                        // Log any errors that occur during the PUT request or delete operation
                        this.log('syncPendingPuts: Error during PUT or DB delete operation', { error });
                    }
                }
            }

            this.log('syncPendingPuts: Completed all pending PUT operations');
        } finally {
            this.isSyncing = false;
        }
    }

    private log(...l: any): void {
        if (RecordDocumentCacheService.debugMode) {
            console.log(...l);
        }
    }
}
