<div class="w-full h-full bg-[var(--color-background-primary-color)] p-2 flex flex-col">
    <div class="flex space-x-4 mb-2">
        <div class="flex items-center justify-between w-full">
            <!-- Category chips -->
            <div class="flex gap-2 items-center">
                <mat-chip-option
                    (click)="changeArea({ id: -1, long_text: 'All' })"
                    [selected]="selectedArea?.long_text === 'All'"
                ><span class="font-light">Alle</span>
                </mat-chip-option>

                <mat-chip-option
                    (click)="changeArea(area ?? { id: -1, long_text: 'All' })"
                    *ngFor="let area of getFilteredAreasBySelectedRecord()"
                    [selected]="selectedArea?.long_text === area.long_text"
                >
                    <span class="font-light">{{ area.long_text }}</span>
                </mat-chip-option>

                <mat-chip-option
                    (click)="changeArea({ id: -1, long_text: 'Sonstiges' })"
                    *ngIf="
                        selectedRecord &&
                        Object.keys(findingsByRecords[selectedRecord.name] ?? []).includes('Sonstiges')
                    "
                    [selected]="selectedArea?.long_text === 'Sonstiges'"
                >
                    <span class="font-light">Sonstiges</span>
                </mat-chip-option>

                <!--<mat-chip [disabled]="true" [matMenuTriggerFor]="menu">
                    <mat-icon class="p-0 m-0 pt-[2px]">add</mat-icon>
                </mat-chip>
                <mat-menu #menu="matMenu">
                    <button (click)="addNewPatientRecord()" mat-menu-item>
                        <mat-icon>description</mat-icon>
                        <span>Dokument erstellen</span>
                    </button>
                    <button
                        (click)="addNewPatientRecord()"
                        disabled
                        mat-menu-item
                    >
                        <mat-icon>publish</mat-icon>
                        <span>Exportieren</span>
                    </button>
                </mat-menu>-->
            </div>

            <div class="flex items-center">
                <!-- New document button -->
                <button (click)="openTextEditor()" mat-icon-button matTooltip="Dokument erstellen">
                    <mat-icon>note_add</mat-icon>
                </button>
                <button (click)="openDoctorFormular()" mat-icon-button matTooltip="Arztbericht erstellen">
                    <mat-icon svgIcon="prescriptions"></mat-icon>
                </button>
                <button (click)="openNurseFormular()" mat-icon-button matTooltip="Pflegebericht erstellen">
                    <mat-icon svgIcon="clinical_notes"></mat-icon>
                </button>

                <mat-select
                    *ngIf="Object.keys(findingsByRecords).length > 0 && selectedRecord"
                    [value]="selectedRecord"
                    class="!w-32 bg-white p-2 !font-light !shadow-sm"
                >
                    <ng-container *ngFor="let record of records">
                        <mat-option
                            (click)="selectedRecord = record; changeArea({ id: -1, long_text: 'All' })"
                            *ngIf="record"
                            [value]="record"
                        >
                            <span>{{ record?.name }}</span>
                        </mat-option>
                    </ng-container>
                </mat-select>
            </div>
        </div>
    </div>

    <!-- Lab viewer button (to see lab table) -->
    <div class="laborviewer-chip-container mdc-evolution-chip__action--secondary">
        <mat-chip-option
            (click)="onClickOnLaborViewer()"
            [disabled]="selectedArea?.long_text !== 'Labor'"
            class="laborviewer-chip"
            #laborViewerChip
        >
            <span class="font-light"> Laborviewer </span>
        </mat-chip-option>
    </div>

    <div *ngIf="Object.keys(findingsByRecords).length === 0" class="w-full p-4 bg-white shadow">
        Es befinden sich keine Daten in der Patientenakte.
    </div>

    <div
        *ngIf="Object.keys(findingsByRecords).length > 0"
        class="flex flex-grow flex-col sm:flex-row h-[calc(100%-3.5rem)] gap-2"
    >
        <!-- Findings list -->
        <div class="w-full sm:w-1/3 overflow-y-auto min-h-[30%] max-h-[calc(100%-1.5rem)] h-full files-list-margin">
            <ng-container *ngIf="Object.keys(findingsByRecords).length > 0 && selectedRecord">
                <ng-container *ngFor="let areaName of Object.keys(findingsByRecords[selectedRecord.name])">
                    <!-- Prüfe, ob "All" oder ein spezifischer Bereich angezeigt werden soll -->
                    <ng-container
                        *ngIf="
                            selectedArea?.long_text === 'All'
                                ? areaName === 'All'
                                : selectedArea?.long_text === areaName
                        "
                    >
                        <ng-container
                            *ngFor="let subareaName of Object.keys(findingsByRecords[selectedRecord.name][areaName])"
                        >
                            <p
                                *ngIf="
                                    selectedArea?.long_text !== 'Labor' &&
                                    (findingsByRecords[selectedRecord.name][areaName][subareaName] ?? []).length > 0
                                "
                                class="text-gray-700"
                            >
                                <span>{{ subareaName }} </span>
                                <span class="text-gray-400 font-light text-sm"
                                >({{ findingsByRecords[selectedRecord.name][areaName][subareaName]?.length }})</span
                                >
                            </p>
                            <ng-container
                                *ngFor="
                                    let finding of findingsByRecords[selectedRecord.name][areaName][subareaName];
                                    let i = index
                                "
                            >
                                <div
                                    (click)="onClickOnFileListItem(finding)"
                                    *ngIf="finding.status !== 'archived'"
                                    [id]="finding.id"
                                    [ngClass]="
                                        selectedFinding?.id === finding.id
                                            ? 'border-[var(--primary-color)]'
                                            : 'border-transparent'
                                    "
                                    class="mb-4 p-2 bg-white shadow-md border-2 cursor-pointer block relative"
                                >
                                    <p class="text-gray-700 text-lg font-light">{{ finding.title }}</p>

                                    <p
                                        *ngIf="
                                            selectedArea?.long_text === 'Labor' || finding.documentType === 'lab-report'
                                        "
                                        class="text-gray-700 sub-text-files font-light"
                                    >
                                        {{ finding.labResult?.result_groups }}
                                    </p>

                                    <p class="text-gray-500 font-light sub-text-files">
                                        B: {{ finding.examinationDateTime | date: 'dd.MM.yyyy HH:mm' }} E:
                                        {{ finding.dateTimeOfRecord | date: 'dd.MM.yyyy HH:mm' }}
                                    </p>

                                    <div
                                        (click)="deleteFinding(finding)"
                                        [matTooltip]="'Befund löschen'"
                                        class="absolute right-1 top-1"
                                    >
                                        <mat-icon class="icon-color">delete</mat-icon>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>

        <!-- Findings viewer -->
        <div
            [ngClass]="{ 'sm:!w-1/3': isMetaDataViewOpened }"
            class="w-full sm:w-2/3 flex flex-col overflow-auto max-h-[calc(100%-1.5rem)] h-full"
        >
            <ng-container [ngSwitch]="selectedFinding?.documentType">
                <!-- Form renderer -->
                <div *ngSwitchCase="'form'" class="flex-grow">
                    <ng-container *ngIf="shouldRenderFormioRenderer">
                        <app-formio-renderer
                            [data]="selectedFinding?.formioRendererData ?? []"
                            [form]="selectedFinding?.formioRendererForm"
                            [i18n]="selectedFinding?.formioRendererI18n"
                            [maxRows]="10"
                            [readonly]="true"
                        >
                        </app-formio-renderer>
                    </ng-container>
                </div>
                <!-- Image viewer -->
                <div *ngSwitchCase="'image'" class="flex-grow flex flex-col h-full p-2">
                    <media-toolbar
                        (isFullscreenForImageEnabledChanged)="onClickOnFullScreen($event)"
                        (isMetaDataViewOpenedChanged)="onMetaDataViewToggleChanged($event)"
                        (isPaintingToolOpenedChanged)="onPaintingToolToggleChanged($event)"
                        [isFullscreenForImageEnabled]="isFullscreenForImageEnabled"
                        [isMetadataViewOpened]="isMetaDataViewOpened"
                        [isPaintingToolOpened]="isPaintingToolOpened"
                    >
                    </media-toolbar>
                    <div class="flex-grow overflow-hidden pt-1">
                        <ng-container *ngIf="selectedFinding?.base64">
                            <img
                                [alt]="'Image for ' + (selectedFinding.title || 'Finding')"
                                [src]="'data:image;base64,' + selectedFinding.base64"
                                class="w-full h-full object-contain pb-2"
                            />
                        </ng-container>

                        <ng-container *ngIf="!selectedFinding?.base64">
                            <p class="text-center text-gray-500">No image available</p>
                        </ng-container>
                    </div>
                </div>

                <!-- PDF viewer -->
                <div *ngSwitchCase="'pdf'" class="flex-grow flex flex-col">
                    <media-toolbar
                        (isFullscreenForImageEnabledChanged)="onClickOnFullScreen($event)"
                        (isMetaDataViewOpenedChanged)="onMetaDataViewToggleChanged($event)"
                        (isPaintingToolOpenedChanged)="onPaintingToolToggleChanged($event)"
                        [isFullscreenForImageEnabled]="isFullscreenForImageEnabled"
                        [isMetadataViewOpened]="isMetaDataViewOpened"
                        [isPaintingToolOpened]="isPaintingToolOpened"
                    >
                    </media-toolbar>
                    <div class="flex-grow overflow-hidden pdfViewerContainer">
                        <app-pdf-viewer
                            *ngIf="selectedFinding.base64"
                            [src]="selectedFinding.base64"
                            class="w-full h-full"
                        ></app-pdf-viewer>
                    </div>
                </div>

                <!-- DICOM viewer-->
                <div *ngSwitchCase="'dicom'" class="flex-grow">
                    <app-dicom-viewer
                        (changedFinding)="addNewPatientFindingBy($event)"
                        [base64]="selectedFinding.base64"
                        [finding]="selectedFinding"
                    />
                </div>

                <!-- Lab result viewer-->
                <div *ngSwitchCase="'lab-report'" class="flex-grow">
                    <app-lab-result-viewer [labResult]="selectedFinding.labResult"></app-lab-result-viewer>
                </div>

                <!-- Lab table -->
                <div *ngSwitchCase="'lab-table'" class="flex-grow">
                    <mat-toolbar>
                        <span>Laborviewer</span>

                        <span class="spacer-xy"></span>

                        <!-- Show limits toggle -->
                        <span style="font-size: 0.7em"
                        >Normalwerte {{ isLabTableShowLimits ? 'ausblenden' : 'anzeigen' }}
                        </span>
                        <button (click)="isLabTableShowLimits = !isLabTableShowLimits" mat-icon-button>
                            <mat-icon *ngIf="isLabTableShowLimits">toggle_on </mat-icon>
                            <mat-icon *ngIf="!isLabTableShowLimits">toggle_off </mat-icon>
                        </button>

                        <button (click)="onClickOnLabViewerFullScreen(allLabResults)" mat-icon-button>
                            <mat-icon>fullscreen</mat-icon>
                        </button>
                    </mat-toolbar>

                    <app-lab-table-viewer
                        [labResults]="allLabResults"
                        [showLimits]="isLabTableShowLimits"
                    ></app-lab-table-viewer>
                </div>

                <!-- PDF viewer -->
                <div *ngSwitchCase="'base64_text'" class="flex-grow flex flex-col">
                    <media-toolbar
                        (isFullscreenForImageEnabledChanged)="onClickOnFullScreen($event)"
                        (isMetaDataViewOpenedChanged)="onMetaDataViewToggleChanged($event)"
                        (isPaintingToolOpenedChanged)="onPaintingToolToggleChanged($event)"
                        [isFullscreenForImageEnabled]="isFullscreenForImageEnabled"
                        [isMetadataViewOpened]="isMetaDataViewOpened"
                        [isPaintingToolOpened]="isPaintingToolOpened"
                    >
                    </media-toolbar>
                    <div class="flex-grow overflow-hidden bg-white">
                        <div [innerHTML]="base64TextToHTML(selectedFinding.base64 ?? '')"></div>
                    </div>
                </div>

                <div *ngSwitchDefault>
                    <media-toolbar
                        (isFullscreenForImageEnabledChanged)="onClickOnFullScreen($event)"
                        (isMetaDataViewOpenedChanged)="onMetaDataViewToggleChanged($event)"
                        (isPaintingToolOpenedChanged)="onPaintingToolToggleChanged($event)"
                        [isFullscreenForImageEnabled]="isFullscreenForImageEnabled"
                        [isMetadataViewOpened]="isMetaDataViewOpened"
                        [isPaintingToolOpened]="isPaintingToolOpened"
                    >
                    </media-toolbar>
                    <div class="flex-grow overflow-hidden">
                        <ng-container *ngIf="selectedFinding?.documentPath || selectedFinding?.base64">
                            <img
                                [alt]="'Image for ' + (selectedFinding.title || 'Finding')"
                                [src]="selectedFinding.documentPath || selectedFinding.base64"
                                class="w-full h-full object-contain pb-2"
                            />
                        </ng-container>
                        <ng-container *ngIf="!(selectedFinding?.documentPath || selectedFinding?.base64)">
                            <p class="text-center text-gray-500">No image available</p>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>

        <!-- Metadata viewer -->
        <div
            *ngIf="isMetaDataViewOpened"
            class="w-full sm:w-1/3 flex flex-col gap-4 overflow-auto p-2 bg-white shadow-md"
        >
            <p class="font-bold">Metadaten</p>
            <div class="flex flex-col gap-1">
                <div class="flex gap-2">
                    <p class="font-bold">ID:</p>
                    <p>{{ selectedFinding.id }}</p>
                </div>
                <div class="flex gap-2">
                    <p class="font-bold">Pfad:</p>
                    <p>{{ selectedFinding.documentPath }}</p>
                </div>
                <div class="flex gap-2">
                    <p class="font-bold">Typ:</p>
                    <p>{{ selectedFinding.documentType }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
