import * as SegmentationState from '../../../stateManagement/segmentation/segmentationState';
import { getSegmentationRepresentations } from '../../../stateManagement/segmentation/segmentationState';
import { getUniqueSegmentIndices } from '../../../utilities/segmentation';
import { triggerSegmentationRepresentationModified } from '../triggerSegmentationEvents';
function setSegmentationVisibility(toolGroupId, segmentationRepresentationUID, visibility) {
    const toolGroupSegmentationRepresentations = getSegmentationRepresentations(toolGroupId);
    if (!toolGroupSegmentationRepresentations) {
        return;
    }
    const representation = toolGroupSegmentationRepresentations.find((representation) => representation.segmentationRepresentationUID ===
        segmentationRepresentationUID);
    if (!representation) {
        return;
    }
    const { segmentsHidden, segmentationId } = representation;
    const indices = getUniqueSegmentIndices(segmentationId);
    if (visibility) {
        segmentsHidden.clear();
    }
    else {
        indices.forEach((index) => {
            segmentsHidden.add(index);
        });
    }
    triggerSegmentationRepresentationModified(toolGroupId, representation.segmentationRepresentationUID);
}
function getSegmentationVisibility(toolGroupId, segmentationRepresentationUID) {
    const toolGroupSegmentationRepresentations = getSegmentationRepresentations(toolGroupId);
    const representation = toolGroupSegmentationRepresentations.find((representation) => representation.segmentationRepresentationUID ===
        segmentationRepresentationUID);
    if (!representation) {
        return;
    }
    const { segmentsHidden, segmentationId } = representation;
    const indices = getUniqueSegmentIndices(segmentationId);
    const indicesSet = new Set(indices);
    segmentsHidden.forEach((segmentIndex) => indicesSet.delete(segmentIndex));
    return !!indicesSet.size;
}
function setSegmentsVisibility(toolGroupId, segmentationRepresentationUID, segmentIndices, visibility) {
    const segRepresentation = SegmentationState.getSegmentationRepresentationByUID(toolGroupId, segmentationRepresentationUID);
    if (!segRepresentation) {
        return;
    }
    segmentIndices.forEach((segmentIndex) => {
        visibility
            ? segRepresentation.segmentsHidden.delete(segmentIndex)
            : segRepresentation.segmentsHidden.add(segmentIndex);
    });
    triggerSegmentationRepresentationModified(toolGroupId, segmentationRepresentationUID);
}
function setSegmentVisibility(toolGroupId, segmentationRepresentationUID, segmentIndex, visibility) {
    const segRepresentation = SegmentationState.getSegmentationRepresentationByUID(toolGroupId, segmentationRepresentationUID);
    if (!segRepresentation) {
        return;
    }
    visibility
        ? segRepresentation.segmentsHidden.delete(segmentIndex)
        : segRepresentation.segmentsHidden.add(segmentIndex);
    triggerSegmentationRepresentationModified(toolGroupId, segmentationRepresentationUID);
}
function getSegmentVisibility(toolGroupId, segmentationRepresentationUID, segmentIndex) {
    const segRepresentation = SegmentationState.getSegmentationRepresentationByUID(toolGroupId, segmentationRepresentationUID);
    if (!segRepresentation) {
        return false;
    }
    return !segRepresentation.segmentsHidden.has(segmentIndex);
}
export { setSegmentationVisibility, getSegmentationVisibility, setSegmentVisibility, setSegmentsVisibility, getSegmentVisibility, };
