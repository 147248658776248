import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { DeviceFacadeService } from './facades/device-facade.service';
import { Cp2ApiService } from './cp2-api.service';
import { AccessFacadeService } from './facades/access-facade.service';
import { AlertService } from './alert.service';
import { Title } from '@angular/platform-browser';
import { Preferences } from '@capacitor/preferences';
import { LS_DEVICE } from '../shared/constants';

@Injectable({
    providedIn: 'root',
})
export class RegistrationService {
    public isRegistered = false;
    // Status wird hier zentral gespeichert:
    private registrationStatusSubject = new BehaviorSubject<boolean>(false);
    public registrationStatus$ = this.registrationStatusSubject.asObservable();
    private accessToken: string | undefined;
    private registrationChecked = false; // Damit der Check nur einmal durchgeführt wird

    constructor(
        private deviceFacade: DeviceFacadeService,
        private api: Cp2ApiService,
        private accessFacade: AccessFacadeService,
        private alert: AlertService,
        private titleService: Title
    ) {}

    /**
     * Führt den Registrierungscheck durch, sobald ein gültiger Token und gültige Device-Daten vorliegen.
     * Das Ergebnis wird in einem BehaviorSubject gespeichert.
     */
    async checkRegistration(): Promise<void> {
        if (this.registrationChecked) {
            return; // Check wurde bereits durchgeführt
        }
        this.registrationChecked = true;

        try {
            // Erstelle gefilterte Observables, die erst dann einen Wert liefern, wenn gültige Daten vorliegen.
            const token$ = this.accessFacade.userTokens$.pipe(
                filter((t) => !!t && !!t.token && !!t.token.access_token),
                take(1)
            );
            const device$ = this.deviceFacade.device$.pipe(
                filter((device) => !!device && !!device.device && !!device.device.deviceId),
                take(1)
            );

            // Warte, bis beide gefilterten Observables einen Wert liefern
            const [tokenData, device] = await firstValueFrom(combineLatest([token$, device$]));

            // Setze den AccessToken
            this.accessToken = tokenData.token?.access_token;
            const deviceId = device.device.deviceId;
            let registrationResult = false;

            if (this.accessToken && deviceId) {
                // API-Aufruf, um zu prüfen, ob das Gerät registriert ist
                registrationResult = await this.api.isDeviceLinked(deviceId, this.accessToken);
                if (!registrationResult) {
                    const currentTitle = this.titleService.getTitle();
                    this.titleService.setTitle(`Sie sind nicht registriert! - ${currentTitle}`);
                    await this.alert.show({
                        header: 'Gerät ist nicht registriert',
                        message:
                            'Ihr Gerät ist nicht registriert!<br>' +
                            'Ohne Registrierung können Sie keine Daten empfangen oder Aktualisierungen erhalten.',
                        buttons: [{ role: 'cancel', text: 'OK' }],
                    });
                }
            } else {
                console.warn('Kein AccessToken oder deviceId vorhanden!');
            }

            // Zusätzlich: Überprüfe lokale Gerätdaten (z. B. in Preferences)
            const prefResult = await Preferences.get({ key: LS_DEVICE });
            const localDeviceData = JSON.parse(prefResult.value ?? 'null');
            if (!localDeviceData?.deviceId) {
                const currentTitle = this.titleService.getTitle();
                this.titleService.setTitle(`Sie sind nicht registriert! - ${currentTitle}`);
                await this.alert.show({
                    header: 'Gerät ist nicht registriert',
                    message:
                        'Ihr Gerät ist nicht registriert!<br>' +
                        'Ohne Registrierung können Sie keine Daten empfangen oder Aktualisierungen erhalten.',
                    buttons: [{ role: 'cancel', text: 'OK' }],
                });
            }

            // Speichere das Ergebnis und benachrichtige alle Abonnenten
            this.isRegistered = registrationResult;
            this.registrationStatusSubject.next(registrationResult);
        } catch (error) {
            console.error('Fehler bei der Registrierung Überprüfung:', error);
            this.registrationStatusSubject.next(false);
        }
    }
}
