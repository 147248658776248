import getInterpolationData from './getInterpolationData';
function findAnnotationsForInterpolation(toolData, viewportData) {
    const interpolationData = getInterpolationData(viewportData, [
        {
            key: 'interpolationUID',
            value: viewportData.interpolationUID,
        },
    ]);
    const rangeToInterpolate = getRangeToInterpolate(interpolationData);
    if (!rangeToInterpolate) {
        console.warn('No annotations found to interpolate', interpolationData);
        return;
    }
    const sliceEdited = _getSlicePositionOfToolData(interpolationData, toolData.annotationUID);
    const interpolationList = [];
    for (let i = rangeToInterpolate[0] + 1; i < rangeToInterpolate[1]; i++) {
        if (_sliceNeedsInterpolating(interpolationData, i)) {
            const contourPair = _getBoundingPair(i, rangeToInterpolate, interpolationData);
            if (contourPair?.[0] === sliceEdited ||
                contourPair?.[1] === sliceEdited) {
                _appendInterpolationList(contourPair, interpolationList, i);
            }
        }
    }
    return {
        interpolationData,
        interpolationList,
    };
}
function getRangeToInterpolate(interpolationData) {
    let first = Infinity;
    let last = -Infinity;
    let found = false;
    for (const [sliceIndex, annotations] of interpolationData.entries()) {
        if (annotations.length) {
            first = Math.min(sliceIndex, first);
            last = Math.max(sliceIndex, last);
            found = true;
        }
    }
    if (!found) {
        return;
    }
    return [first, last];
}
function _getSlicePositionOfToolData(interpolationData, annotationUID) {
    for (const [sliceIndex, annotations] of interpolationData) {
        for (let j = 0; j < annotations.length; j++) {
            if (annotations[j].annotationUID === annotationUID) {
                return sliceIndex;
            }
        }
    }
    return;
}
function _sliceNeedsInterpolating(interpolationData, sliceIndex) {
    const annotations = interpolationData.get(sliceIndex);
    return (!annotations?.length ||
        (annotations.length === 1 && annotations[0].autoGenerated));
}
function _appendInterpolationList(contourPair, interpolationList, itemIndex) {
    const [startIndex] = contourPair;
    interpolationList[startIndex] ||= {
        pair: contourPair,
        list: [],
    };
    interpolationList[startIndex].list.push(itemIndex);
}
function _getBoundingPair(sliceIndex, sliceRange, interpolationData) {
    const annotationPair = [];
    let canInterpolate = true;
    for (let i = sliceIndex - 1; i >= sliceRange[0]; i--) {
        const annotations = interpolationData.get(i);
        if (annotations?.length) {
            if (annotations[0].autoGenerated) {
                continue;
            }
            if (annotations.length > 1) {
                canInterpolate = false;
            }
            annotationPair.push(i);
            break;
        }
    }
    if (!canInterpolate || !annotationPair.length) {
        return;
    }
    for (let i = sliceIndex + 1; i <= sliceRange[1]; i++) {
        const annotations = interpolationData.get(i);
        if (annotations?.length) {
            if (annotations[0].autoGenerated) {
                continue;
            }
            if (annotations.length > 1) {
                canInterpolate = false;
            }
            annotationPair.push(i);
            break;
        }
    }
    if (!canInterpolate || annotationPair.length < 2) {
        return;
    }
    return annotationPair;
}
export default findAnnotationsForInterpolation;
