import { utilities as csUtils } from '@cornerstonejs/core';
export default function createPolylineToolData(polyline, handlePoints, referencedToolData) {
    const annotation = csUtils.deepMerge({
        data: {},
        metadata: {},
    }, referencedToolData);
    Object.assign(annotation, {
        highlighted: false,
        invalidated: true,
        autoGenerated: true,
        annotationUID: undefined,
        cachedStats: {},
        childAnnotationUIDs: [],
        parentAnnotationUID: undefined,
    });
    Object.assign(annotation.data, {
        handles: {
            points: handlePoints.points || handlePoints || [],
            interpolationSources: handlePoints.sources,
            activeHandleIndex: null,
            textBox: {
                hasMoved: false,
                worldPosition: [0, 0, 0],
                worldBoundingBox: {
                    topLeft: [0, 0, 0],
                    topRight: [0, 0, 0],
                    bottomLeft: [0, 0, 0],
                    bottomRight: [0, 0, 0],
                },
            },
        },
        contour: {
            ...referencedToolData.data.contour,
            polyline,
        },
    });
    return annotation;
}
