<mat-card class="dialog-container">
    <mat-card-header class="mb-4">
        <div *ngIf="data.answersTo">Answers to {{ recipient?.first_name + ' ' + recipient?.last_name }}: {{data.answersTo.data.task_name}}</div>
    </mat-card-header>

    <mat-card-content>
        <mat-form-field appearance="outline" class="w-full">
            <mat-label>Empfänger</mat-label>
            <mat-select [(ngModel)]="recipient">
                <mat-option *ngFor="let item of data.recipientList" [value]="item"
                    >{{ item.last_name }}, {{ item.first_name }} ({{ item.user_id }})</mat-option
                >
            </mat-select>
        </mat-form-field>

        <app-formio-renderer [(data)]="formioRendererData" [form]="form" [i18n]="i18n"></app-formio-renderer>
    </mat-card-content>

    <mat-card-actions class="flex flex-row gap-2 justify-end">
        <button (click)="onClickOnCancel()" mat-stroked-button>Abbrechen</button>
        <button (click)="onClickOnSave()" mat-flat-button>Senden</button>
    </mat-card-actions>
</mat-card>
