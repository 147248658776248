import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as viewcontentSelectors from '../../store/selectors/viewcontent.selector';
import * as ViewContentActions from '../../store/actions/viewcontent.action';
import {AppState} from '../../store/appstate.model';
import {ViewContent} from '../../models/view-content.models/view-content.model';
import {CP2_User} from '../../models/view-content.models/view-content-personal-domain.model';

@Injectable({
    providedIn: 'root'
})
export class ViewContentFacadeService {
    /**
     * get locators fearture from the store.
     * return @param locatorsState
     */
    public locatorsState$ = this.store.select(viewcontentSelectors.selectViewContentFeature);


    /**
     * get locators from the store.
     * return @param locators
     */
    public viewContent = this.store.select(
        viewcontentSelectors.selectViewContent
    );

    public constructor(private store: Store<AppState>) {
    }

    /**
     * Loads the locators from persistent storage
     */
    public loadViewContent(locator: string): void {
        this.store.dispatch(ViewContentActions.loadViewContentState({locator}));
    }

    /**
     * Dispatches an action to set a language into the store.
     */
    public saveViewContent(viewContent: ViewContent<any>, user: CP2_User): void {
        this.store.dispatch(ViewContentActions.setViewContent({viewContent, user}));
    }
}
