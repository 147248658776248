<mat-chip-listbox
    [(ngModel)]="selectedToggle"
    aria-label="Selection"
    class="workflow-chips"
>
    <mat-chip-option
        (selectionChange)="onChipSelectChange($event)"
        value="tasks">{{
            "COMPONENT.PAGE_ORGA.tasks" | translate
        }}
    </mat-chip-option>
    <mat-chip-option value="medical_orders">{{
            "COMPONENT.PAGE_ORGA.medical_orders" | translate
        }}
    </mat-chip-option>

    <mat-chip-option value="comments">{{
            "COMPONENT.PAGE_ORGA.comments" | translate
        }}
    </mat-chip-option>
    <mat-chip-option value="diagnostic_findings">{{
            "COMPONENT.PAGE_ORGA.diagnostic_findings" | translate
        }}
    </mat-chip-option>
</mat-chip-listbox>
<div [ngSwitch]="selectedToggle" class="chip-container">
    <div *ngSwitchCase="'medical_orders'">
        <app-orga-tabs-medical-order
            [isWorkflowRoute]="isWorkflowRoute"
            [orgaPatients]="workflowDataPatients"
        ></app-orga-tabs-medical-order>
    </div>
    <div *ngSwitchCase="'tasks'">
        <app-orga-tabs-tasks
            [isWorkflowRoute]="isWorkflowRoute"
            [orgaPatient]="workflowDataPatients"
        ></app-orga-tabs-tasks>
    </div>
    <div *ngSwitchCase="'comments'">
        <app-orga-tabs-comments
            [isWorkflowRoute]="isWorkflowRoute"
            [orgaPatient]="workflowDataPatients"
        ></app-orga-tabs-comments>
    </div>
    <div *ngSwitchCase="'diagnostic_findings'">
        <app-orga-tabs-diag-findings
            [isWorkflowRoute]="isWorkflowRoute"
            [orgaPatient]="workflowDataPatients"
        ></app-orga-tabs-diag-findings>
    </div>

    <!-- <div *ngSwitchDefault class="all-patients">
        <app-orga-tabs
            [orgaPatients]="workflowDataPatients"
            [viewContentI18n]="viewContentI18n"
        ></app-orga-tabs>
    </div> -->
</div>
