<mat-sidenav-container [hasBackdrop]="true">
    <mat-sidenav #sidenav [disableClose]="true" class="min-w-[64px] max-w-[250px] !rounded-none sidenav-shadow h-[calc(100vh-64px)] !flex !flex-col justify-between !z-50 py-2 !bg-white" mode="over">
        <mat-nav-list>
            <!-- Top section -->
            <mat-card
                class="sidebar-up-name-wrapper !shadow-none !px-2 mb-4 !bg-white"
            >
                <div class="avatar-wrapper">
                    <!-- User avatar with dynamic background image -->
                    <div
                        [style.background-image]="
                            'url(' + userData.photoLink + ')'
                        "
                        class="avatar"
                    >
                        {{ getInitials(userData.name) }}
                    </div>
                </div>
                <p
                    class="sidebar-up-name text-center cursor-default"
                    matTooltip="{{ userData.name }}"
                >
                    {{ userData.name }}
                </p>
            </mat-card>

            <mat-list-item
                (click)="sidenav.toggle()"
                class="text-center items-center"
            >
                <div class="flex flex-row gap-2 items-center">
                    <mat-icon
                        [ngClass]="{ rotated: !sidenav.opened }"
                        aria-label="toggle_menu"
                        class="align-middle cursor-pointer toggle_menu"
                        mat-list-icon
                    >
                        {{ toggleButton.icon_name }}
                    </mat-icon>
                    <span
                        class="text-sm"
                        translate="{{ toggleButton.label | translate }}"
                    ></span>
                </div>
            </mat-list-item>

            <ng-container *ngFor="let button of topButtons">
                <mat-list-item
                    (click)="onClickOnItem(button)"
                    class="text-center"
                >
                    <div
                        [ngClass]="{ 'text-gray-500': button.disabled }"
                        class="flex flex-row gap-2 items-center"
                    >
                        <mat-icon
                            [attr.aria-label]="button.name"
                            [ngClass]="{ rotated: !sidenav.opened }"
                            class="align-middle cursor-pointer"
                            mat-list-icon
                        >{{ button.icon_name }}
                        </mat-icon
                        >
                        <span
                            class="text-sm"
                            translate="{{ button.label | translate }}"
                        ></span>
                    </div>
                </mat-list-item>
            </ng-container>
        </mat-nav-list>

        <!-- Middle section (can be empty or contain additional items) -->
        <div class="flex-grow"></div>

        <mat-nav-list>
            <!-- Bottom section -->
            <ng-container *ngFor="let button of bottomButtons">
                <mat-list-item
                    (click)="onClickOnItem(button)"
                    class="text-center"
                >
                    <div
                        [ngClass]="{ 'text-gray-500': button.disabled }"
                        class="flex flex-row gap-2 items-center"
                    >
                        <mat-icon
                            [attr.aria-label]="button.name"
                            [ngClass]="{ rotated: !sidenav.opened }"
                            class="align-middle cursor-pointer"
                            mat-list-icon
                        >{{ button.icon_name }}
                        </mat-icon
                        >
                        <span
                            class="text-sm"
                            translate="{{ button.label | translate }}"
                        ></span>
                    </div>
                </mat-list-item>
            </ng-container>

            <mat-list-item
                (click)="toggleTooltip($event, SEEStatus)"
            >
                <div
                    [ngClass]="{ '!text-left': sidenav.opened }"
                    class="flex flex-col text-sm text-center font-light"
                >
                    <div>W: {{ locatorStatus.remaining }}</div>
                    <div>V: {{ locatorStatus.processed }}</div>
                </div>
            </mat-list-item>

            <mat-list-item>
                <app-device-registered-indicator [ngClass]="{ '!justify-normal': sidenav.opened }"></app-device-registered-indicator>
            </mat-list-item>

            <mat-list-item>
                <app-network-quality
                    [ngClass]="{ '!justify-normal': sidenav.opened }"
                ></app-network-quality>
            </mat-list-item>
        </mat-nav-list>
    </mat-sidenav>

    <div *ngIf="currentPath !== '/login'"
         class="flex flex-row h-[calc(100vh-64px)] bg-[var(--color-background-primary-color)]"
    >
        <div
            class="sidenav-shadow max-w-[64px] min-w-[64px] flex flex-col justify-between z-40 py-2 overflow-y-auto bg-white"
        >
            <mat-nav-list>
                <!-- Top section -->
                <mat-card
                    class="sidebar-up-name-wrapper !shadow-none !px-2 mb-4 !bg-white"
                >
                    <div class="avatar-wrapper">
                        <!-- User avatar with dynamic background image -->
                        <div
                            [style.background-image]="
                                'url(' + userData.photoLink + ')'
                            "
                            class="avatar"
                        >
                            {{ getInitials(userData.name) }}
                        </div>
                    </div>
                    <p
                        class="sidebar-up-name text-center cursor-default"
                        matTooltip="{{ userData.name }}"
                    >
                        {{ userData.name }}
                    </p>
                </mat-card>

                <mat-list-item
                    (click)="sidenav.toggle()"
                    class="text-center items-center"
                >
                    <mat-icon
                        [ngClass]="{ rotated: !sidenav.opened }"
                        aria-label="toggle_menu"
                        class="align-middle cursor-pointer toggle_menu"
                        mat-list-icon
                    >
                        {{ toggleButton.icon_name }}
                    </mat-icon>
                </mat-list-item>

                <ng-container *ngFor="let button of topButtons">
                    <mat-list-item
                        (click)="onClickOnItem(button)"
                        class="text-center"
                    >
                        <div [ngClass]="{ 'text-gray-500': button.disabled }">
                            <mat-icon
                                [attr.aria-label]="button.name"
                                [ngClass]="{ rotated: !sidenav.opened }"
                                class="align-middle cursor-pointer"
                                mat-list-icon
                            >{{ button.icon_name }}
                            </mat-icon
                            >
                        </div>
                    </mat-list-item>
                </ng-container>
            </mat-nav-list>

            <!-- Middle section (can be empty or contain additional items) -->
            <div class="flex-grow"></div>

            <mat-nav-list>
                <!-- Bottom section -->
                

                <mat-list-item
                    (click)="toggleTooltip($event, SEEStatus)"
                    class="!p-0"
                >
                    <div
                        [ngClass]="{ '!text-left': sidenav.opened }"
                        class="flex flex-col text-sm text-center font-light"
                    >
                        <div>W: {{ locatorStatus.remaining }}</div>
                        <div>V: {{ locatorStatus.processed }}</div>
                    </div>
                </mat-list-item>

                <mat-list-item>
                    <app-device-registered-indicator></app-device-registered-indicator>
                </mat-list-item>

                <mat-list-item>
                    <app-network-quality></app-network-quality>
                </mat-list-item>
                <ng-container *ngFor="let button of bottomButtons">
                    <mat-list-item
                        (click)="onClickOnItem(button)"
                        class="text-center"
                    >
                        <div [ngClass]="{ 'text-gray-500': button.disabled }">
                            <mat-icon
                                [attr.aria-label]="button.name"
                                [ngClass]="{ rotated: !sidenav.opened }"
                                class="align-middle cursor-pointer"
                                mat-list-icon
                            >{{ button.icon_name }}
                            </mat-icon
                            >
                        </div>
                    </mat-list-item>
                </ng-container>
            </mat-nav-list>
        </div>
        <router-outlet></router-outlet>
    </div>
    <router-outlet *ngIf="currentPath === '/login'"></router-outlet>
</mat-sidenav-container>

<ng-template #SEEStatus>
    <div class="flex flex-col gap-2 max-h-screen">
        <div class="flex flex-col gap-1 text-sm">
            <div class="flex flex-row justify-between items-center">
                <div>Status</div>
                <mat-icon (click)="deleteLocatorQueueeInLS()" class="text-red-600 cursor-pointer text-md">delete</mat-icon>
            </div>
            <div class="font-light">
                Warteschlange: {{ locatorStatus.remaining }}
            </div>
            <div class="font-light">
                Verarbeitet: {{ locatorStatus.processed }}
            </div>
        </div>

        <div class="flex flex-col gap-1 text-sm">
            <div>Log</div>
            <div *ngFor="let log of locatorStatus.locatorLog">
                <div
                    *ngIf="log.locator"
                    [ngClass]="{ '!text-[#27ae60]': log.success }"
                    class="text-sm font-light text-[#c0392b]"
                >
                    {{ log.locator }}
                </div>
            </div>
        </div>
    </div>
</ng-template>


<!--<mat-sidenav-container [@slideInOut]="isExpanded.toString()" autosize class="sidenav-container">
    &lt;!&ndash; Sidenav container &ndash;&gt;
    <mat-sidenav #sidenav mode="side" opened="isExpanded">
        &lt;!&ndash; Navigation list with dynamic styling based on expansion state &ndash;&gt;
        <mat-nav-list [@navListAnimation]="isExpanded ? 'expanded' : 'collapsed'">

            &lt;!&ndash; Upper section of the sidebar &ndash;&gt;
            <div class="sidebar-up">
                &lt;!&ndash; User information card &ndash;&gt;
                <mat-card class="sidebar-up-name-wrapper">
                    <div class="avatar-wrapper">
                        &lt;!&ndash; User avatar with dynamic background image &ndash;&gt;
                        <div [style.background-image]="'url(' + userData.photoLink + ')'" class="avatar">{{ getInitials(userData.name) }}</div>
                    </div>
                    <p #tooltip="matTooltip" [matTooltipPosition]="'below'" class="sidebar-up-name" matTooltip="{{ userData.name }}">{{ userData.name }}</p>
                </mat-card>
                &lt;!&ndash; Expand/Collapse toggle button &ndash;&gt;
                <mat-list-item (click)="isExpanded = !isExpanded" *ngIf="currentBreakpoint !== 'handsetPortrait' && currentBreakpoint !== 'handsetLandscape'"
                               [ngStyle]="{ textAlign: !isExpanded ? 'center' : 'unset', width: !isExpanded ? 'calc(100% + 1rem)' : 'calc(100% + 2rem)', marginLeft: !isExpanded ? '-.5rem' : '-1rem', paddingLeft: !isExpanded ? '0' : '1rem' }">
                    <mat-icon [ngClass]="{ rotated: !isExpanded }" aria-label="toggle_menu" class="toggle_menu" mat-list-icon>{{ toggleButton.icon_name }}</mat-icon>
                    <span *ngIf="isExpanded" [ngStyle]="{ marginLeft: !isExpanded ? '0' : '.33rem' }" class="menu-item" translate="{{ toggleButton.label | translate }}"></span>
                </mat-list-item>
                &lt;!&ndash; List of top buttons &ndash;&gt;
                <mat-list-item (click)="onClickOnItem(button)" *ngFor="let button of topButtons"
                               [ngStyle]="{ textAlign: !isExpanded ? 'center' : 'unset', width: !isExpanded ? 'calc(100% + 1rem)' : 'calc(100% + 2rem)', marginLeft: !isExpanded ? '-.5rem' : '-1rem', paddingLeft: !isExpanded ? '0' : '1rem' }">
                    <mat-icon [attr.aria-label]="button.name" [ngClass]="{ rotated: !isExpanded }" class="{{ button.name }}" mat-list-icon>{{ button.icon_name }}</mat-icon>
                    <span *ngIf="isExpanded" [ngStyle]="{ marginLeft: !isExpanded ? '0' : '.33rem' }" class="menu-item" translate="{{ button.label | translate }}"></span>
                </mat-list-item>
            </div>
            &lt;!&ndash; Bottom section of the sidebar &ndash;&gt;
            <div [ngStyle]="{ 'margin-bottom': currentBreakpoint === 'handsetPortrait' ? '4em' : '0' }" class="sidebar-down mb-10">
                &lt;!&ndash; List of bottom buttons &ndash;&gt;
                <mat-list-item (click)="onClickOnItem(item)" *ngFor="let item of bottomButtons"
                               [ngStyle]="{ textAlign: !isExpanded ? 'center' : 'unset', width: !isExpanded ? 'calc(100% + 1rem)' : 'calc(100% + 2rem)', marginLeft: !isExpanded ? '-.5rem' : '-1rem', paddingLeft: !isExpanded ? '0' : '1rem' }">
                    <mat-icon [attr.aria-label]="item.name" mat-list-icon>
                        <img *ngIf="!item.icon_name" [src]="item.svg" alt="{{ item.name }}"/>
                        {{ item.svg ? "" : item.icon_name }}
                    </mat-icon>
                    <span *ngIf="isExpanded" [ngStyle]="{ marginLeft: !isExpanded ? '0' : '.33rem' }" class="menu-item">{{ item.label | translate }}</span>
                </mat-list-item>
                <mat-list-item (pointerleave)="onHideTooltip()" (pointerover)="onShowTooltip($event, SEEStatus)">
                    <div [ngStyle]="{ width: !isExpanded ? '100%' : 'fit-content' }"
                         class="flex flex-col text-sm text-center font-light">
                        <div>W: {{ locatorStatus.remaining }}</div>
                        <div>V: {{ locatorStatus.processed }}</div>
                    </div>
                </mat-list-item>
                <ng-template #SEEStatus>
                    <div class="flex flex-col gap-2">
                        <div class="flex flex-col gap-1 text-sm">
                            <div>Status</div>
                            <div class="font-light">Warteschlange: {{ locatorStatus.remaining }}</div>
                            <div class="font-light">Verarbeitet: {{ locatorStatus.processed }}</div>
                        </div>
                        <div class="flex flex-col gap-1 text-sm">
                            <div>Log</div>
                            <div *ngFor="let log of locatorStatus.locatorLog">
                                <div *ngIf="log.locator" [ngClass]="{'!text-[#27ae60]': log.success}" class="text-sm font-light text-[#c0392b]">
                                    {{ log.locator }}
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <mat-list-item [ngStyle]="{ width: !isExpanded ? '100%' : 'fit-content' }">
                    <app-network-quality></app-network-quality>
                </mat-list-item>
            </div>
        </mat-nav-list>
    </mat-sidenav>
</mat-sidenav-container>-->
