import { cache, eventTarget, triggerEvent, Enums, } from '@cornerstonejs/core';
import { getWebWorkerManager } from '@cornerstonejs/core';
import { computeVolumeSegmentationFromStack } from '../../convertStackToVolumeSegmentation';
import { WorkerTypes } from '../../../../enums';
const workerManager = getWebWorkerManager();
const triggerWorkerProgress = (eventTarget, progress) => {
    triggerEvent(eventTarget, Enums.Events.WEB_WORKER_PROGRESS, {
        progress,
        type: WorkerTypes.POLYSEG_LABELMAP_TO_SURFACE,
    });
};
export async function convertLabelmapToSurface(labelmapRepresentationData, segmentIndex, isVolume = true) {
    let volumeId;
    if (isVolume) {
        volumeId = labelmapRepresentationData
            .volumeId;
    }
    else {
        const { imageIdReferenceMap } = labelmapRepresentationData;
        ({ volumeId } = await computeVolumeSegmentationFromStack({
            imageIdReferenceMap,
        }));
    }
    const volume = cache.getVolume(volumeId);
    const scalarData = volume.getScalarData();
    const { dimensions, spacing, origin, direction } = volume;
    triggerWorkerProgress(eventTarget, 0);
    const results = await workerManager.executeTask('polySeg', 'convertLabelmapToSurface', {
        scalarData,
        dimensions,
        spacing,
        origin,
        direction,
        segmentIndex,
    }, {
        callbacks: [
            (progress) => {
                triggerWorkerProgress(eventTarget, progress);
            },
        ],
    });
    triggerWorkerProgress(eventTarget, 1);
    return results;
}
