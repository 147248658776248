<div class="w-full bg-white min-h-16 max-h-16 px-4 z-[3] relative flex flex-row items-center overflow-hidden overflow-x-auto" id="app-navigation">
    <div class="w-fit min-h-16 max-h-16 flex flex-row gap-8 items-center">
        <div class="max-w-36 min-w-36">
            <img src="../../assets/img/logo-checkpad-triosys.png" />
        </div>
        <div class="flex flex-row gap-2 items-center">
            <button
                (click)="onClickOnBack()"
                [disabled]="!canNavigateBack()"
                class="!shadow"
                mat-mini-fab
            >
                <mat-icon class="mat-18">chevron_left</mat-icon>
            </button>

            <button
                (click)="onClickOnForward()"
                [disabled]="!canNavigateForward()"
                class="!shadow"
                mat-mini-fab
            >
                <mat-icon class="mat-18">chevron_right</mat-icon>
            </button>
        </div>
    </div>
    <div class="w-full min-h-16 max-h-16 flex flex-row gap-2 items-center text-center justify-center">
        <ng-container *ngFor="let button of buttons">
            <button
                (click)="onClickOnButton(button)"
                *ngIf="isButtonActive(button); else nonActiveButton"
                class="lbShadowSmall"
                color="primary"
                mat-flat-button
            >
                <mat-icon>done</mat-icon>
                <ng-container>
                    {{ button.label | translate }}
                </ng-container>
            </button>

            <ng-template #nonActiveButton>
                <button
                    (click)="onClickOnButton(button)"
                    color="primary"
                    mat-stroked-button
                >
                    <ng-container>
                        {{ button.label | translate }}
                    </ng-container>
                </button>
            </ng-template>
        </ng-container>
    </div>
</div>


<!--
<mat-nav-list
    [ngClass]="{
        'nav-menu-handsetLandscape':
            currentHandyOrientation === 'handsetLandscape',
        'nav-menu':
            currentHandyOrientation !== 'handsetPortrait' &&
            currentHandyOrientation !== 'handsetLandscape'
    }"
    class="sm:relative"
    id="cp2-navigation"
>
    <div class="logo-back-button-container">
        &lt;!&ndash; Logo&ndash;&gt;
        <div class="nav-menu-logo">
            <img src="../../assets/img/logo-checkpad-triosys.png"/>
        </div>
    </div>

    &lt;!&ndash; Back and forward buttons and main buttons &ndash;&gt;
    <div class="nav-menu-buttons">
        <div
            *ngIf="
                currentHandyOrientation !== 'handsetPortrait' &&
                currentHandyOrientation !== 'handsetLandscape'
            "
            class="navigation-buttons"
        >
            <button
                (click)="onClickOnBack()"
                [disabled]="!canNavigateBack()"
                class="arrow-button !shadow"
                mat-mini-fab
            >
                <mat-icon class="mat-18">chevron_left</mat-icon>
            </button>

            <button
                (click)="onClickOnForward()"
                [disabled]="!canNavigateForward()"
                class="arrow-button !shadow"
                mat-mini-fab
            >
                <mat-icon class="mat-18">chevron_right</mat-icon>
            </button>
        </div>
        &lt;!&ndash; start, workflow and orga buttons &ndash;&gt;
        <div
            [ngClass]="{
                'navigation-buttons-handsetLandscape':
                    currentHandyOrientation === 'handsetLandscape'
            }"
            class="navigation-buttons"
        >
            <ng-container *ngFor="let button of buttons">
                <ng-container
                    *ngIf="
                        currentHandyOrientation !== 'handsetPortrait' &&
                        currentHandyOrientation !== 'handsetLandscape'
                    "
                >
                    <button
                        (click)="onClickOnButton(button)"
                        *ngIf="isButtonActive(button); else nonActiveButton"
                        class="lbShadowSmall"
                        color="primary"
                        mat-flat-button
                    >
                        <mat-icon>done</mat-icon>
                        <ng-container>
                            {{ button.label | translate }}
                        </ng-container>
                    </button>
                </ng-container
                >

                <ng-container
                    *ngIf="
                        currentHandyOrientation === 'handsetPortrait' ||
                        currentHandyOrientation === 'handsetLandscape'
                    "
                >
                    <div class="flex flex-col justify-center items-center">
                        <button
                            (click)="onClickOnButton(button)"
                            *ngIf="isButtonActive(button); else nonActiveButton"
                            mat-icon-button
                        >
                            <mat-icon class="active-icon-handy">{{
                                    button.handyLabel
                                }}
                            </mat-icon>
                        </button>
                        <div class="text-xs">{{ button.abbreviation }}</div>
                    </div>
                </ng-container>
                <ng-template #nonActiveButton>
                    <ng-container
                        *ngIf="
                            currentHandyOrientation === 'handsetPortrait' ||
                                currentHandyOrientation === 'handsetLandscape';
                            else strokedButton
                        "
                    >
                        <button
                            (click)="onClickOnButton(button)"
                            mat-icon-button
                        >
                            <mat-icon>{{ button.handyLabel }}</mat-icon>
                        </button>
                        &lt;!&ndash; <p>{{button.abbreviation}}</p> &ndash;&gt;
                    </ng-container>
                    <ng-template #strokedButton>
                        <button
                            (click)="onClickOnButton(button)"
                            color="primary"
                            mat-stroked-button
                        >
                            <mat-icon
                                *ngIf="
                                    currentHandyOrientation ===
                                        'handsetPortrait' ||
                                    currentHandyOrientation ===
                                        'handsetLandscape'
                                "
                            >
                                {{ button.handyLabel }}
                            </mat-icon>
                            <ng-container
                                *ngIf="
                                    currentHandyOrientation !==
                                        'handsetPortrait' &&
                                    currentHandyOrientation !==
                                        'handsetLandscape'
                                "
                            >
                                {{ button.label | translate }}
                            </ng-container>
                        </button>
                    </ng-template>
                </ng-template>
            </ng-container>
        </div>
        <div></div>
    </div>
</mat-nav-list>
-->

