<div *ngIf="place">
    <span
        *ngIf="place?.data?.room?.service_unit?.department?.[0]?.code"
        (click)="onClickOnBreadcrumb('department', place.data.room.service_unit.department[0].code)"
    >
        <span class="breadcrumb">{{ place.data.room.service_unit.department[0].code }}</span>
    </span>

    <span
        *ngIf="place?.data?.room?.service_unit"
        (click)="onClickOnBreadcrumb('service_unit', place.data.room.service_unit.code)"
    >
        > <span class="breadcrumb">{{ place.data.room.service_unit.code }}</span>
    </span>

    <span *ngIf="place?.data?.room?.code" (click)="onClickOnBreadcrumb('room', place.data.room.code)">
        > <span class="breadcrumb">{{ place.data.room.code }}</span>
    </span>

    <span *ngIf="place?.data?.code">
        > <span class="breadcrumb">{{ place.data.code }}</span>
    </span>
</div>
