import { SidebarButton } from './components/navigation/sidebar/sidebar.component';
import { SmartSettings, UserToken } from './models/auth.model';
import { Component, Inject, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NavController, Platform } from '@ionic/angular';
import { AccessFacadeService } from './services/facades/access-facade.service';
import { Subscription } from 'rxjs';
import { AuthService } from './services/auth.service';
import { SettingsFacadeService } from './services/facades/settings-facade.service';
import { Settings } from './models/settings.model';
import { ThemeService } from './services/theme.service';
import { DOCUMENT } from '@angular/common';
import { decodeJwt } from 'jose';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { DeviceFacadeService } from './services/facades/device-facade.service';
import { ServerSentEventHandlerService } from './services/mercure/server-sent-event-handler.service';
import { DataRepositoryService } from './services/datarepository.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CLIENT_ID } from './shared/constants';
import { CapacitorHttp } from '@capacitor/core';
import { ServerSettingsFacadeService } from './services/facades/server-settings-facade.service';
import { RegistrationService } from './services/registration.service';

// import {NgEventBus} from 'ng-event-bus';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    private static readonly TAG = 'AppComponent';

    public accessToken$ = this.accessFacade.userTokens$;
    public accessToken: UserToken | null = null;
    public username: string = '';
    public showSidebar: boolean = false;
    public currentBreakpoint: string = '';
    public selectedColorSchema: string = '';

    private allSubs: Subscription[] = [];

    public constructor(
        @Inject(DOCUMENT) private document: Document,
        private accessFacade: AccessFacadeService,
        private authService: AuthService,
        private deviceF: DeviceFacadeService,
        private navController: NavController,
        private platform: Platform,
        private renderer: Renderer2,
        private repo: DataRepositoryService,
        private router: Router,
        private serverSettingsFacade: ServerSettingsFacadeService,
        private settings: SettingsFacadeService,
        private snackBar: MatSnackBar,
        private SSEHService: ServerSentEventHandlerService,
        private themeService: ThemeService,
        private translate: TranslateService,
        private zone: NgZone,
        private registrationService: RegistrationService
    ) {
        this.setupDeepLinks();
        this.setupSettings();
        this.registrationService.checkRegistration();

        this.allSubs.push(
            this.themeService.theme$.subscribe((theme) => {
                // Ensure the document body is available before modifying classList
                if (this.document?.body) {
                    const body = this.document.body;

                    if (theme === 'light') {
                        this.renderer.addClass(body, 'cp2-theme');
                        this.renderer.removeClass(body, 'cp2-dark-theme');
                    } else if (theme === 'dark') {
                        this.renderer.addClass(body, 'cp2-dark-theme');
                        this.renderer.removeClass(body, 'cp2-theme');
                    }
                } else {
                    console.error('Document body is not available.');
                }
            })
        );
    }

    public get currentUrl() {
        return this.router.url;
    }

    public async ngOnInit(): Promise<void> {
        this.allSubs.push(
            this.accessToken$.subscribe(async (t) => {
                if (t.token) {
                    this.username = decodeJwt(t.token.access_token)['name'] as string;
                    this.accessToken = t.token;
                    // TODO: @Jose - Hier werden alle Topics Initialisiert und später Abonniert/Subscribed
                    this.SSEHService.initializeMercureTopicSubscriptions(this.accessToken.access_token);
                }
            })
        );

        this.platform.ready().then(async () => {
            this.accessFacade.loadData();
            this.deviceF.loadData();
        });

        setInterval(
            async () => {
                if (this.accessToken?.refresh_token) {
                    const refreshedToken = await this.authService.refreshAuth(this.accessToken.refresh_token);
                    this.accessFacade.setAccessToken(refreshedToken);
                }

                await this.checkAndRefreshToken();
            },
            5 * 60 * 1000
        );

        await this.checkAndRefreshToken();

        // TODO: @Jose - Hier ist ein Beispiel wie du den EventBus benutzen kannst. Der EventBus kann von überall aus genutzt werden. Wichtig ist, das der Key übereinstimmt
        /*this.allSubs.push(
            this.eventBus.on('sse:periodicPing').subscribe((event) => {
                console.log(event.data);
            })
        );*/
    }

    public ngOnDestroy(): void {
        this.allSubs.forEach((s) => s.unsubscribe());
    }

    public async handleNavigation(link: string): Promise<void> {
        await this.router.navigateByUrl(link);
    }

    public async handleSidebarButton(sidebarButton: SidebarButton): Promise<void> {
        switch (sidebarButton.name) {
            case 'logout':
                const logoutConfirmString = this.translate.instant('GENERAL.logout_confirm');
                if (window.confirm(logoutConfirmString)) {
                    this.accessFacade.removeUserToken();
                    await this.repo.deleteDatabase();

                    const authParams: any = {
                        client_id: CLIENT_ID,
                        post_logout_redirect_uri: `${this.serverSettingsFacade.snapshot?.cpad2Server}login`,
                        // post_logout_redirect_uri: `${environment.cpad2Server}login`,
                    };
                    const configUrl = this.serverSettingsFacade.snapshot?.loginConfigUrl;
                    if (configUrl) {
                        authParams['configUrl'] = configUrl;
                    }

                    const queryString = new URLSearchParams(authParams).toString();

                    const smartSettings = await this.fetchSmartSettings();

                    if (smartSettings) {
                        const logoutEndpoint = smartSettings['logout_endpoint'];
                        window.location.href = `${logoutEndpoint}${queryString}`;
                    }
                }
                break;
            case 'settings':
                await this.navController.navigateRoot('/settings');
                break;
            case 'admin':
                await this.navController.navigateRoot('/admin');
                break;
            case 'refresh_access':
                if (this.accessToken?.refresh_token) {
                    const at = await this.authService.refreshAuth(this.accessToken.refresh_token);
                    this.accessFacade.setAccessToken(at);
                }
                break;
            case 'back':
                this.navController.back();
                break;
            default:
                console.log(AppComponent.TAG, 'sidebar button clicked: ', sidebarButton);
        }
    }

    /**
     * Loads and retrieves the FHIR configuration from a specified URL.
     * Attempts to handle errors gracefully and logs them for debugging.
     * @returns A promise containing the loaded configuration or undefined if an error occurs.
     */
    private async fetchSmartSettings(): Promise<SmartSettings | undefined> {
        try {
            const url = this.serverSettingsFacade.snapshot?.smartSettingsServer;
            if (!url) throw new Error('No SMART configuration URL provided');
            const response = await CapacitorHttp.get({ url });
            // const response = await CapacitorHttp.get({url: "assets/smartSettings.json"});
            if (!response.data) throw new Error('Response data is empty');
            return response.data;
        } catch (e) {
            console.error('Error loading SMART configuration:', e);
            return undefined;
        }
    }

    private async checkAndRefreshToken() {
        setInterval(async () => {
            if (this.accessToken?.access_token) {
                const accessToken = this.accessToken.access_token;

                try {
                    const decoded = decodeJwt(accessToken);
                    const currentTime = Math.floor(Date.now() / 1000); // Aktuelle Zeit in Sekunden

                    if (decoded.exp && decoded.exp < currentTime) {
                        if (this.accessToken?.refresh_token) {
                            const at = await this.authService.refreshAuth(this.accessToken.refresh_token);

                            if (!at) {
                                const warningStringI18n = this.translate.instant('GENERAL.snackbar_token_is_expired');

                                this.snackBar.open(warningStringI18n, undefined, {
                                    duration: 5000,
                                    panelClass: ['warning-snackbar'],
                                });

                                await this.router.navigateByUrl('/login');
                            } else {
                                this.accessFacade.setAccessToken(at);
                            }
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }, 5000);
    }

    // Private methods
    private setupDeepLinks(): void {
        App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
            await this.zone.run(async () => {
                const slug = event.url.split('app.cpad2.de').pop();
                if (slug && slug.includes('login/redirect')) {
                    await this.authService.initializeLoginCallback(window.location.origin + slug);
                }
            });
        });
    }

    private setupSettings() {
        this.settings.loadSettings();
        this.allSubs.push(
            this.settings.settings$.subscribe((s: Settings) => {
                this.translate.use(s.language);
                this.selectedColorSchema = s.colorScheme;
                this.getColorScheme(this.selectedColorSchema);
            })
        );
    }

    private getColorScheme(colorScheme: string) {
        if (colorScheme === 'rostock') {
            this.renderer.removeClass(this.document.body, 'cp2-theme');
            this.renderer.addClass(this.document.body, 'cp2-rostock-theme');
        } else {
            this.renderer.addClass(this.document.body, 'cp2-theme');
            this.renderer.removeClass(this.document.body, 'cp2-rostock-theme');
        }
    }
}
