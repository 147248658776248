import {Injectable, NgZone, OnDestroy} from '@angular/core';
import {fromEvent, merge, of, Subject, Subscription} from 'rxjs';
import {NgEventBus} from 'ng-event-bus';
import {Preferences} from '@capacitor/preferences';
import {CapacitorHttp, HttpResponse} from '@capacitor/core';
import {environment} from '../../../environments/environment';

interface EventBusPayload {
    operation: string;
    topic: string;
}

@Injectable({
    providedIn: 'root'
})
export class ServerSentEventService implements OnDestroy {
    private allSubs: Subscription[] = [];
    private retryCounts = new Map<string, number>();
    private retryTimeouts = new Map<string, ReturnType<typeof setTimeout>>();
    private topicSubjects = new Map<string, Subject<any>>();
    private eventSources = new Map<string, EventSource>();
    private isOffline = false;
    private lastMessageTimestamps = new Map<string, number>();
    private heartbeatIntervals = new Map<string, ReturnType<typeof setInterval>>();
    private readonly INITIAL_RETRY_DELAY = 1000;

    constructor(private zone: NgZone, private eventBus: NgEventBus) {
        this.monitorNetworkStatus();
    }

    public getServerSentEvents(topic: string): Subject<any> {
        let subject = this.topicSubjects.get(topic);

        // If no subject exists for this topic, create one and initialize SSE
        if (!subject) {
            subject = new Subject<any>();
            this.topicSubjects.set(topic, subject);

            // Handle asynchronous initialization without awaiting at the method level
            this.getLastEventId(topic).then(lastEventId => {
                this.initializeEventSource(topic, lastEventId).catch(error =>
                    console.error(`Failed to initialize EventSource for topic ${topic}:`, error)
                );
            }).catch(() => {
                this.initializeEventSource(topic).catch(error =>
                    console.error(`Failed to initialize EventSource for topic ${topic}:`, error)
                );
            });
        }

        return subject;
    }


    ngOnDestroy(): void {
        this.cleanupAllTopics();
        this.allSubs.forEach(sub => sub.unsubscribe());
        this.allSubs = [];
    }

    private emitEventBus(eventType: string, operation: string, topic: string): void {
        const payload: EventBusPayload = {operation, topic};
        this.eventBus.cast(eventType, payload);
    }

    private async initializeEventSource(topic: string, lastEventId?: string): Promise<void> {
        const url = new URL(environment.mercureBaseUrl);
        url.searchParams.append('topic', topic);
        url.searchParams.append('authorization', await this.getMercureToken());
        if (lastEventId) {
            url.searchParams.append('lastEventID', lastEventId);
        }
        this.setupEventSource(topic, url.toString());
    }

    private setupEventSource(topic: string, url: string): void {
        const eventSource = new EventSource(url);
        const subject = this.topicSubjects.get(topic);
        if (!subject) {
            console.error(`No subject found for topic: ${topic}`);
            return;
        }

        eventSource.onopen = async () => {
            await this.handleEventSourceOpen(topic);
            await this.startHeartbeatCheck(topic);
        };

        eventSource.onmessage = async (event: MessageEvent) => {
            await this.handleEventSourceMessage(event, subject);
            this.updateLastMessageTimestamp(topic);
        };

        eventSource.onerror = async () => {
            await this.handleEventSourceError(topic, eventSource);
            this.stopHeartbeatCheck(topic);
        };

        this.eventSources.set(topic, eventSource);
    }

    private updateLastMessageTimestamp(topic: string): void {
        this.lastMessageTimestamps.set(topic, Date.now());
    }

    private async startHeartbeatCheck(topic: string): Promise<void> {
        this.stopHeartbeatCheck(topic);
        const interval = setInterval(() => {
            this.checkAndSendHeartbeat(topic).catch(error =>
                console.error(`Heartbeat check failed for topic ${topic}:`, error)
            );
        }, 45000);
        this.heartbeatIntervals.set(topic, interval);
    }

    private stopHeartbeatCheck(topic: string): void {
        const interval = this.heartbeatIntervals.get(topic);
        if (interval) {
            clearInterval(interval);
            this.heartbeatIntervals.delete(topic);
        }
    }

    private async checkAndSendHeartbeat(topic: string): Promise<void> {
        const lastMessageTimestamp = this.lastMessageTimestamps.get(topic) || 0;
        const currentTime = Date.now();
        if (currentTime - lastMessageTimestamp > 45000) {
            await this.sendHeartbeat(topic);
        }
    }

    private async sendHeartbeat(topic: string): Promise<void> {
        try {
            const url = new URL(environment.mercureBaseUrl);
            const lastEventId = await this.getLastEventId(topic);

            url.searchParams.append('topic', topic);
            if (lastEventId) {
                url.searchParams.append('lastEventID', lastEventId);
            }

            const response: HttpResponse = await CapacitorHttp.get({
                url: url.toString(),
                headers: {
                    'Authorization': `Bearer ${await this.getMercureToken()}`,
                    'Cache-Control': 'no-cache',
                    'Accept': 'text/event-stream'
                }
            });

            if (response.status === 200) {
                this.updateLastMessageTimestamp(topic);
            } else {
                await this.reopenConnection(topic);
            }
        } catch (error) {
            console.error(`Error sending heartbeat for topic: ${topic}`, error);
            await this.reopenConnection(topic);
        }
    }

    private async reopenConnection(topic: string): Promise<void> {
        const oldEventSource = this.eventSources.get(topic);
        if (oldEventSource) {
            oldEventSource.close();
            this.eventSources.delete(topic);
        }
        const lastEventId = await this.getLastEventId(topic);
        await this.initializeEventSource(topic, lastEventId);
    }

    private async getLastEventId(topic: string): Promise<string | undefined> {
        const result = await Preferences.get({key: 'TOPIC_' + topic});
        return result.value ?? undefined;
    }

    private async handleEventSourceOpen(topic: string): Promise<void> {
        await this.zone.run(async () => {
            this.emitEventBus('SSE_EVENT', 'OPENED', topic);
            this.resetRetryCount(topic);
            await this.startHeartbeatCheck(topic);
        });
    }

    private async handleEventSourceMessage(event: MessageEvent | any, subject: Subject<any>): Promise<void> {
        await this.zone.run(async () => {
            try {
                const parsedData = event.data ? JSON.parse(event.data) : {};
                subject.next(parsedData);
                const urlParams = new URLSearchParams(event.target?.url.split('?')[1]);
                const topic = urlParams.get('topic');
                if (topic) {
                    const lastEventId = event.lastEventId || '-1';
                    await Preferences.set({
                        key: 'TOPIC_' + topic,
                        value: lastEventId
                    });
                }
                this.updateLastMessageTimestamp(topic!);
            } catch (err) {
                console.error('Error parsing message in EventSource', err);
            }
        });
    }

    private async handleEventSourceError(topic: string, eventSource: EventSource): Promise<void> {
        await this.zone.run(async () => {
            this.emitEventBus('SSE_EVENT', 'CLOSED', topic);
            eventSource.close();
            await this.retryConnection(topic);
            this.stopHeartbeatCheck(topic);
        });
    }

    private monitorNetworkStatus(): void {
        const sub = merge(
            of(null),
            fromEvent(window, 'online'),
            fromEvent(window, 'offline')
        ).subscribe(() => {
            const isOnline = navigator.onLine;
            if (isOnline && this.isOffline) {
                this.handleOnline();
            } else if (!isOnline) {
                this.handleOffline();
            }
        });
        this.allSubs.push(sub);
    }

    private handleOnline(): void {
        this.isOffline = false;
        this.topicSubjects.forEach(async (_, topic) => {
            await this.startHeartbeatCheck(topic);
            const lastEventId = (await Preferences.get({key: 'TOPIC_' + topic})).value;
            if (lastEventId) {
                await this.retryConnection(topic, lastEventId);
            }
        });
    }

    private handleOffline(): void {
        this.isOffline = true;

        this.topicSubjects.forEach((_, topic) => {
            this.stopHeartbeatCheck(topic);
            this.emitEventBus('SSE_EVENT', 'CLOSED', topic);
            this.eventSources.get(topic)?.close();
        });
    }

    private async retryConnection(topic: string, lastEventId?: string): Promise<void> {
        const retryCount = this.retryCounts.get(topic) || 0;
        this.clearRetryTimeout(topic);

        await new Promise<void>(resolve => {
            const nextRetryTimeout = setTimeout(async () => {
                this.retryCounts.set(topic, retryCount + 1);
                await this.initializeEventSource(topic, lastEventId);
                resolve();
            }, this.calculateRetryDelay(retryCount));
            this.retryTimeouts.set(topic, nextRetryTimeout);
        });
    }

    private calculateRetryDelay(retryCount: number): number {
        const jitter = Math.random() * 1000;
        return Math.min(this.INITIAL_RETRY_DELAY * Math.pow(2, retryCount) + jitter, 30000);
    }

    private resetRetryCount(topic: string): void {
        this.retryCounts.set(topic, 0);
    }

    private clearRetryTimeout(topic: string): void {
        const retryTimeout = this.retryTimeouts.get(topic);
        if (retryTimeout) {
            clearTimeout(retryTimeout);
            this.retryTimeouts.delete(topic);
        }
    }

    private cleanupTopic(topic: string): void {
        const subject = this.topicSubjects.get(topic);
        if (subject) {
            subject.complete();
            this.topicSubjects.delete(topic);
        }
        const eventSource = this.eventSources.get(topic);
        if (eventSource) {
            eventSource.close();
            this.eventSources.delete(topic);
        }
        this.clearRetryTimeout(topic);
        this.retryCounts.delete(topic);
        this.stopHeartbeatCheck(topic);
    }

    private cleanupAllTopics(): void {
        this.topicSubjects.forEach((_, topic) => this.cleanupTopic(topic));
    }

    private async getMercureToken(): Promise<string> {
        const token = environment.mercureSubscriberToken;
        if (!this.validateToken(token)) {
            throw new Error('Invalid Mercure Subscriber Token');
        }
        return token;
    }

    private validateToken(token: string): boolean {
        if (!token) return false;
        const parts = token.split('.');
        if (parts.length !== 3) return false;
        try {
            const decodedToken = JSON.parse(atob(parts[1]));
            if (decodedToken.exp && Date.now() >= decodedToken.exp * 1000) {
                return false;
            }
        } catch {
            return false;
        }
        return true;
    }
}
