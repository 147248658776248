"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const metaData_1 = require("../metaData");
let state = {};
const metadataProvider = {
    add: (imageId, payload) => {
        metadataProvider.addRaw(imageId, Object.assign(Object.assign({}, payload), { metadata: structuredClone(payload.metadata) }));
    },
    addRaw: (imageId, payload) => {
        const type = payload.type;
        if (!state[imageId]) {
            state[imageId] = {};
        }
        state[imageId][type] = payload.metadata;
    },
    get: (type, imageId) => {
        var _a;
        return (_a = state[imageId]) === null || _a === void 0 ? void 0 : _a[type];
    },
    clear: () => {
        state = {};
    },
};
(0, metaData_1.addProvider)(metadataProvider.get);
exports.default = metadataProvider;
