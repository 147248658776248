import { StackViewport, VolumeViewport, eventTarget, EVENTS, utilities as csUtils, getEnabledElement, } from '@cornerstonejs/core';
export default function scroll(viewport, options) {
    const enabledElement = getEnabledElement(viewport.element);
    if (!enabledElement) {
        throw new Error('Scroll::Viewport is not enabled (it might be disabled)');
    }
    if (viewport instanceof StackViewport &&
        viewport.getImageIds().length === 0) {
        throw new Error('Scroll::Stack Viewport has no images');
    }
    const { volumeId, delta, scrollSlabs } = options;
    if (viewport instanceof VolumeViewport) {
        scrollVolume(viewport, volumeId, delta, scrollSlabs);
    }
    else {
        const imageIdIndex = viewport.getCurrentImageIdIndex();
        if (imageIdIndex + delta >
            viewport.getImageIds().length - 1 ||
            imageIdIndex + delta < 0) {
            const eventData = {
                imageIdIndex,
                direction: delta,
            };
            csUtils.triggerEvent(eventTarget, EVENTS.STACK_SCROLL_OUT_OF_BOUNDS, eventData);
        }
        viewport.scroll(delta, options.debounceLoading, options.loop);
    }
}
export function scrollVolume(viewport, volumeId, delta, scrollSlabs = false) {
    const useSlabThickness = scrollSlabs;
    const { numScrollSteps, currentStepIndex, sliceRangeInfo } = csUtils.getVolumeViewportScrollInfo(viewport, volumeId, useSlabThickness);
    if (!sliceRangeInfo) {
        return;
    }
    const { sliceRange, spacingInNormalDirection, camera } = sliceRangeInfo;
    const { focalPoint, viewPlaneNormal, position } = camera;
    const { newFocalPoint, newPosition } = csUtils.snapFocalPointToSlice(focalPoint, position, sliceRange, viewPlaneNormal, spacingInNormalDirection, delta);
    viewport.setCamera({
        focalPoint: newFocalPoint,
        position: newPosition,
    });
    viewport.render();
    const desiredStepIndex = currentStepIndex + delta;
    const VolumeScrollEventDetail = {
        volumeId,
        viewport,
        delta,
        desiredStepIndex,
        currentStepIndex,
        numScrollSteps,
        currentImageId: viewport.getCurrentImageId(),
    };
    if ((desiredStepIndex > numScrollSteps || desiredStepIndex < 0) &&
        viewport.getCurrentImageId()) {
        csUtils.triggerEvent(eventTarget, EVENTS.VOLUME_SCROLL_OUT_OF_BOUNDS, VolumeScrollEventDetail);
    }
    else {
        csUtils.triggerEvent(eventTarget, EVENTS.VOLUME_VIEWPORT_SCROLL, VolumeScrollEventDetail);
    }
}
