import { getEnabledElement, addVolumesToViewports, addImageSlicesToViewports, Enums, } from '@cornerstonejs/core';
import { isVolumeSegmentation } from '../../segmentation/strategies/utils/stackVolumeCheck';
async function addLabelmapToElement(element, labelMapData, segmentationRepresentationUID) {
    const enabledElement = getEnabledElement(element);
    const { renderingEngine, viewport } = enabledElement;
    const { id: viewportId } = viewport;
    const visibility = true;
    const immediateRender = false;
    const suppressEvents = true;
    if (isVolumeSegmentation(labelMapData, viewport)) {
        const volumeInputs = [
            {
                volumeId: labelMapData.volumeId,
                actorUID: segmentationRepresentationUID,
                visibility,
                blendMode: Enums.BlendModes.MAXIMUM_INTENSITY_BLEND,
            },
        ];
        await addVolumesToViewports(renderingEngine, volumeInputs, [viewportId], immediateRender, suppressEvents);
    }
    else {
        const segmentationImageId = labelMapData.imageIdReferenceMap.get(viewport.getCurrentImageId());
        const stackInputs = [
            {
                imageId: segmentationImageId,
                actorUID: segmentationRepresentationUID,
            },
        ];
        await addImageSlicesToViewports(renderingEngine, stackInputs, [viewportId]);
    }
}
export default addLabelmapToElement;
