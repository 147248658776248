import { getEnabledElement } from '@cornerstonejs/core';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkPolyData from '@kitware/vtk.js/Common/DataModel/PolyData';
import vtkCellArray from '@kitware/vtk.js/Common/Core/CellArray';
import { getSurfaceActorUID } from '../../../stateManagement/segmentation/helpers/clipAndCacheSurfacesForViewport';
function addOrUpdateSurfaceToElement(element, surface, segmentationRepresentationUID) {
    const actorUID = getSurfaceActorUID(segmentationRepresentationUID, surface.id);
    const enabledElement = getEnabledElement(element);
    const { viewport } = enabledElement;
    const surfaceActor = viewport.getActor(actorUID)?.actor;
    if (surfaceActor) {
        const surfaceMapper = surfaceActor.getMapper();
        const currentPolyData = surfaceMapper.getInputData();
        const newPoints = surface.getPoints();
        const newPolys = surface.getPolys();
        const currentPoints = currentPolyData.getPoints().getData();
        const currentPolys = currentPolyData.getPolys().getData();
        if (newPoints.length === currentPoints.length &&
            newPolys.length === currentPolys.length) {
            return;
        }
        const polyData = vtkPolyData.newInstance();
        polyData.getPoints().setData(newPoints, 3);
        const triangles = vtkCellArray.newInstance({
            values: Float32Array.from(newPolys),
        });
        polyData.setPolys(triangles);
        surfaceMapper.setInputData(polyData);
        surfaceMapper.modified();
        setTimeout(() => {
            viewport.getRenderer().resetCameraClippingRange();
        }, 0);
        return;
    }
    const points = surface.getPoints();
    const polys = surface.getPolys();
    const color = surface.getColor();
    const surfacePolyData = vtkPolyData.newInstance();
    surfacePolyData.getPoints().setData(points, 3);
    const triangles = vtkCellArray.newInstance({
        values: Float32Array.from(polys),
    });
    surfacePolyData.setPolys(triangles);
    const mapper = vtkMapper.newInstance({});
    let clippingFilter;
    mapper.setInputData(surfacePolyData);
    const actor = vtkActor.newInstance();
    actor.setMapper(mapper);
    actor.getProperty().setColor(color[0] / 255, color[1] / 255, color[2] / 255);
    actor.getProperty().setLineWidth(2);
    viewport.addActor({
        actor: actor,
        uid: actorUID,
        clippingFilter,
    });
    viewport.resetCamera();
    viewport.render();
    setTimeout(() => {
        viewport.getRenderer().resetCameraClippingRange();
    }, 0);
}
export default addOrUpdateSurfaceToElement;
