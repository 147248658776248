import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, interval, Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Network} from '@capacitor/network';
import {CapacitorHttp} from '@capacitor/core';
import {AccessFacadeService} from '../facades/access-facade.service';
import {AccessState} from '../../store/reducers/access.reducer';

export interface PingInfo {
    performedAt: string;
    milliseconds: number;
}

export interface PingPayloadInfo extends PingInfo {
    payloadSizeKb: number;
    connectionSpeedKbS: number;
}

export interface PingPayloadInfo extends PingInfo {
    payloadSizeKb: number;
    connectionSpeedKbS: number;
}

@Injectable({
    providedIn: 'root'
})
export class NetworkService implements OnDestroy {
    /** `true` if the app detects internet (online), `false` otherwise */
    public networkOnline$ = new BehaviorSubject<boolean>(false);
    /** The information of the last ping to the app's API */
    public pingToApi$ = new BehaviorSubject<PingInfo>({
        performedAt: new Date().toISOString(),
        milliseconds: -1
    });
    /** The information of the last ping with payload to the app's API */
    public pingPayloadToApi$ = new BehaviorSubject<PingPayloadInfo>({
        performedAt: new Date().toISOString(),
        milliseconds: -1,
        payloadSizeKb: -1,
        connectionSpeedKbS: -1
    });

    private pingSubscription: Subscription | undefined;
    private allSubs: Subscription[] = [];
    private token: AccessState | undefined;

    public constructor(private access: AccessFacadeService) {
        this.init().then();
        this.allSubs.push(
            this.access.userTokens$.subscribe((t) => (this.token = t))
        );
    }

    public ngOnDestroy(): void {
        this.allSubs.forEach((s) => s.unsubscribe());
    }

    /** Sets the interval time in seconds in which the service will ping the API to check the latency (default: 2 seconds) */
    public setPingInterval(seconds: number): void {
        this.initPing(seconds);
    }

    /** Sets the interval time in seconds in which the service will ping the API to check the latency (default: 2 seconds) */
    public setPingPayloadInterval(seconds: number): void {
        this.initPingPayload(seconds);
    }

    public async pingWithPayload(): Promise<PingPayloadInfo> {
        const before = new Date();
        const url = environment.apiServer + '/ping';
        const token = this.token?.token?.access_token;
        const headers = {Authorization: 'Bearer ' + token};
        const params = {payload: 'true'};

        const res = await CapacitorHttp.get({url, headers, params});

        const after = new Date();
        let blob = new Blob([res.data], {type: 'text/plain'});
        let payloadSizeKb = blob.size / 1024;
        const milliseconds = after.getTime() - before.getTime();

        return {
            performedAt: before.toISOString(),
            milliseconds: after.getTime() - before.getTime(),
            payloadSizeKb,
            connectionSpeedKbS: 1000 * (payloadSizeKb / milliseconds)
        };
    }

    private async init(): Promise<void> {
        await this.initNetworkStatus();
        await this.initPing();
        await this.initPingPayload();
        this.pingPayloadToApi$.next(await this.pingWithPayload());
    }

    private async initNetworkStatus(): Promise<void> {
        const isOnline = (await Network.getStatus()).connected;
        this.networkOnline$.next(isOnline);
        Network.addListener('networkStatusChange', (s) =>
            this.networkOnline$.next(s.connected)
        );
    }

    private async initPing(i = 2): Promise<void> {
        this.pingSubscription?.unsubscribe();

        this.pingSubscription = interval(i * 1000).subscribe(async () => {
            const before = new Date();
            const baseUrl = environment.apiServer + '/ping';
            const token = this.token?.token?.access_token;
            const headers = {Authorization: 'Bearer ' + token};
            try {
                const res = await CapacitorHttp.get({url: baseUrl, headers});
            } catch (e) {
            }
            const after = new Date();

            this.pingToApi$.next({
                performedAt: before.toISOString(),
                milliseconds: after.getTime() - before.getTime()
            });
        });
        if (!this.allSubs.includes(this.pingSubscription))
            this.allSubs.push(this.pingSubscription);
    }

    private async initPingPayload(i = 60): Promise<void> {
        this.pingSubscription?.unsubscribe();
        this.pingSubscription = interval(i * 1000).subscribe(async () => {
            try {
                this.pingPayloadToApi$.next(await this.pingWithPayload());
            } catch (e) {
            }
        });
        if (!this.allSubs.includes(this.pingSubscription))
            this.allSubs.push(this.pingSubscription);
    }
}
