import { CommonModule } from '@angular/common';
import { NetworkService } from './../../services/mercure/network.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { of, Subscription, switchMap, timer } from 'rxjs';
import { MatIcon, MatIconRegistry } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DomSanitizer } from '@angular/platform-browser';

const WIFIOFF_ICON = `
 <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M790-56 414-434q-47 11-87.5 33T254-346l-84-86q32-32 69-56t79-42l-90-90q-41 21-76.5 46.5T84-516L0-602q32-32 66.5-57.5T140-708l-84-84 56-56 736 736-58 56Zm-310-64q-42 0-71-29.5T380-220q0-42 29-71t71-29q42 0 71 29t29 71q0 41-29 70.5T480-120Zm236-238-29-29-29-29-144-144q81 8 151.5 41T790-432l-74 74Zm160-158q-77-77-178.5-120.5T480-680q-21 0-40.5 1.5T400-674L298-776q44-12 89.5-18t92.5-6q142 0 265 53t215 145l-84 86Z"/></svg>
`;
@Component({
    selector: 'app-network-quality',
    templateUrl: './network-quality.component.html',
    styleUrls: ['./network-quality.component.scss'],
    standalone: true,
    imports: [CommonModule, MatIcon, MatButtonModule],
})
export class NetworkQualityComponent implements OnInit, OnDestroy {
    public online = true;
    public connectionQuality: number = 0;
    private pingTime = -1;
    private netSpeed = -1;
    private allSubs: Subscription[] = [];

    public constructor(
        private networkService: NetworkService,
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer
    ) {
        iconRegistry.addSvgIconLiteral('wifi_off', sanitizer.bypassSecurityTrustHtml(WIFIOFF_ICON));
    }

    public get pingPercent(): number {
        if (this.pingTime < 70) return 100;
        return 100 * (70 / this.pingTime);
    }

    public get speedPercent(): number {
        if (this.netSpeed > 1000) return 100;
        return 100 * (this.netSpeed / 1000);
    }

    public ngOnInit(): void {
        this.allSubs.push(
            this.networkService.networkOnline$
                .pipe(
                    switchMap((o: boolean) => {
                        if (o) {
                            return of(o);
                        } else {
                            return timer(10000).pipe(switchMap(() => of(o)));
                        }
                    })
                )
                .subscribe((o: boolean) => (this.online = o))
        );
        this.allSubs.push(this.networkService.pingToApi$.subscribe((p) => (this.pingTime = p.milliseconds)));
        this.allSubs.push(
            this.networkService.pingPayloadToApi$.subscribe((p) => (this.netSpeed = p.connectionSpeedKbS))
        );
    }

    public ngOnDestroy(): void {
        this.allSubs.forEach((s) => s.unsubscribe());
    }

    public getColorForPercent(percent: number): string {
        if (percent > 80) return '#27ae60';
        if (percent > 60) return '#f1c40f';
        if (percent > 40) return '#f39c12';
        return '#e74c3c';
    }
}
