import { state } from '../../stateManagement/segmentation';
export function removeContourSegmentationAnnotation(annotation) {
    if (!annotation.data.segmentation) {
        throw new Error('removeContourSegmentationAnnotation: annotation does not have a segmentation data');
    }
    const { segmentationId, segmentIndex } = annotation.data.segmentation;
    const segmentation = state.getSegmentation(segmentationId);
    const { annotationUIDsMap } = segmentation?.representationData.CONTOUR || {};
    const annotationsUIDsSet = annotationUIDsMap?.get(segmentIndex);
    if (!annotationsUIDsSet) {
        return;
    }
    annotationsUIDsSet.delete(annotation.annotationUID);
    if (!annotationsUIDsSet.size) {
        annotationUIDsMap.delete(segmentIndex);
    }
}
