import { BaseVolumeViewport, getEnabledElement } from '@cornerstonejs/core';
import { BaseTool } from './base';
import scroll from '../utilities/scroll';
class StackScrollMouseWheelTool extends BaseTool {
    constructor(toolProps = {}, defaultToolProps = {
        supportedInteractionTypes: ['Mouse', 'Touch'],
        configuration: {
            invert: false,
            debounceIfNotLoaded: true,
            loop: false,
            scrollSlabs: false,
        },
    }) {
        super(toolProps, defaultToolProps);
    }
    mouseWheelCallback(evt) {
        const { wheel, element } = evt.detail;
        const { direction } = wheel;
        const { invert } = this.configuration;
        const { viewport } = getEnabledElement(element);
        const delta = direction * (invert ? -1 : 1);
        let volumeId;
        if (viewport instanceof BaseVolumeViewport) {
            volumeId = this.getTargetVolumeId(viewport);
        }
        scroll(viewport, {
            delta,
            debounceLoading: this.configuration.debounceIfNotLoaded,
            loop: this.configuration.loop,
            volumeId,
            scrollSlabs: this.configuration.scrollSlabs,
        });
    }
}
StackScrollMouseWheelTool.toolName = 'StackScrollMouseWheel';
export default StackScrollMouseWheelTool;
