import {CommonModule} from '@angular/common';
import {Component, ContentChild, Input, TemplateRef} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {TranslateModule} from '@ngx-translate/core';
import {inOutExpandY} from '../../../shared/animations';
import {Router} from '@angular/router';
import {MatIcon} from '@angular/material/icon';

/**
 * A component that renders a dynamically adjustable list of items.
 * This component supports displaying a limited number of items initially
 * and toggling between showing more or less items.
 *
 * ##### Example 1
 * ```html
 * <app-dynamic-list [list]="items" [clipToWhenSmall]="5">
 *   <ng-template #itemTemplate let-item="item">
 *     <some-list-item-component [item]="item"></some-list-item-component>
 *   </ng-template>
 * </app-dynamic-list>
 * ```
 *
 * ##### Example 2
 * ```html
 * <app-dynamic-list [list]="items">
 *   <ng-template #itemTemplate let-item="item">
 *     <h1>{{ item.title }}</h1>
 *     <div>{{ item.description }}</div>
 *   </ng-template>
 * </app-dynamic-list>
 * ```
 */
@Component({
    selector: 'app-dynamic-list',
    standalone: true,
    templateUrl: './dynamic-list.component.html',
    styleUrls: ['./dynamic-list.component.scss'],
    imports: [MatButtonModule, CommonModule, TranslateModule, MatIcon],
    animations: [inOutExpandY]
})
export class DynamicListComponent {
    /**
     * The list of items to display.
     */
    @Input() list: any[] = [];

    /**
     * The number of items to show when the list is clipped.
     * @default 2
     */
    @Input() clipToWhenSmall = 2;

    /**
     * A flag indicating whether the list is currently clipped (i.e., showing a reduced number of items).
     */
    @Input() isClipped = true;

    /* control the button to show more lists or go to the page of the patient detail */
    @Input() showButton: boolean = true;
    /**
     * A template reference to the content that will be rendered for each item in the list.
     */
    @Input() patientId: string = '';
    @ContentChild(TemplateRef) template!: TemplateRef<any>;

    constructor(private router: Router) {
    }

    public get listToShow(): any[] {
        if (Array.isArray(this.list)) {
            const sorted = this.list.sort((a, b) => {
                if (!a.updated_at) return 1;
                if (!b.updated_at) return -1;
                return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
            });
            return sorted.slice(0, this.clipToWhenSmall);
        }
        return [];
    }

    ngOnInit(): void {
    }

    /**
     * Toggles the clipped state of the list, showing more or less items accordingly.
     */
    public toggleClip(): void {
        this.isClipped = !this.isClipped;
    }
}
