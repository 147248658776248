"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ImageQualityStatus;
(function (ImageQualityStatus) {
    ImageQualityStatus[ImageQualityStatus["FAR_REPLICATE"] = 1] = "FAR_REPLICATE";
    ImageQualityStatus[ImageQualityStatus["ADJACENT_REPLICATE"] = 3] = "ADJACENT_REPLICATE";
    ImageQualityStatus[ImageQualityStatus["SUBRESOLUTION"] = 6] = "SUBRESOLUTION";
    ImageQualityStatus[ImageQualityStatus["LOSSY"] = 7] = "LOSSY";
    ImageQualityStatus[ImageQualityStatus["FULL_RESOLUTION"] = 8] = "FULL_RESOLUTION";
})(ImageQualityStatus || (ImageQualityStatus = {}));
exports.default = ImageQualityStatus;
