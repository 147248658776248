<!-- settings.component.html -->
<div class="pt-4 px-4">
    <h1 class="!bg-white shadow p-2 text-xl">
        {{ 'COMPONENT.SETTINGS.header' | translate }}
    </h1>

    <mat-list class="space-y-4">
        <!-- Oberflächen-Einstellungen -->
        <mat-accordion>
            <div class="text-gray-600" mat-subheader>Oberfläche</div>

            <!-- Language -->
            <mat-expansion-panel class="!bg-white shadow !rounded-none">
                <mat-expansion-panel-header class="p-2">
                    <mat-panel-title class="flex items-center">
                        <mat-icon class="mr-2">translate</mat-icon>
                        {{ 'COMPONENT.SETTINGS.LANGUAGE.language_label' | translate }}
                    </mat-panel-title>
                    <mat-panel-description class="flex justify-end">
                        {{ 'GENERAL.language_name_' + selectedLanguage | translate }}
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-selection-list (selectionChange)="onSettingLangChange($event)" [multiple]="false" class="p-2">
                    <mat-list-option *ngFor="let l of languages" [selected]="selectedLanguage === l.lang" [value]="l.lang">
                        {{ l.title }}
                    </mat-list-option>
                </mat-selection-list>
            </mat-expansion-panel>

            <!-- Theme Change -->
            <mat-expansion-panel class="!bg-white shadow !rounded-none">
                <mat-expansion-panel-header class="p-2">
                    <mat-panel-title class="flex items-center">
                        <mat-icon class="mr-2">palette</mat-icon>
                        {{ 'COMPONENT.SETTINGS.theme_change' | translate }}
                    </mat-panel-title>
                    <mat-panel-description class="flex justify-end">
                        <!-- Optional: weitere Informationen -->
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-selection-list (selectionChange)="changeColorScheme($event)" [multiple]="false" class="p-2">
                    <mat-list-option
                        *ngFor="let colorScheme of colorSchemes"
                        [selected]="selectedColorSchemas === colorScheme.color"
                        [value]="colorScheme.color"
                    >
                        {{ colorScheme.label }}
                    </mat-list-option>
                </mat-selection-list>
            </mat-expansion-panel>

            <!-- Anonymize Data Checkbox (neu) -->
            <mat-list-item class="!bg-white shadow cursor-pointer hover:!bg-[var(--mat-expansion-header-hover-state-layer-color)] pl-2">
                <div class="flex items-center justify-between w-full">
                    <div class="w-full flex items-center">
                        <mat-icon class="mr-2">theater_comedy</mat-icon>
                        <span>{{ 'COMPONENT.SETTINGS.anonymize_data' | translate }}</span>
                    </div>
                    <mat-checkbox
                        (ngModelChange)="onAnonymizeDataClick($event)"
                        [(ngModel)]="activeAnonymous"
                        class="w-fit scale-125"
                    ></mat-checkbox>
                </div>
            </mat-list-item>
        </mat-accordion>

        <!-- Daten-Einstellungen -->
        <mat-accordion class="mt-4">
            <div class="text-gray-600" mat-subheader>Daten</div>

            <!-- Synchronization -->
            <mat-expansion-panel class="!bg-white shadow !rounded-none">
                <mat-expansion-panel-header class="p-2">
                    <mat-panel-title class="flex items-center">
                        <mat-icon class="mr-2">sync</mat-icon>
                        {{ 'COMPONENT.SETTINGS.label_synchronization' | translate }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-selection-list class="p-2">
                    <mat-list-option
                        (selectedChange)="onSyncItemChange($event, item)"
                        *ngFor="let item of syncArray"
                        [selected]="item.selected"
                    >
                        {{ item.name }}
                    </mat-list-option>
                </mat-selection-list>
            </mat-expansion-panel>

            <!-- Interaction Modules -->
            <mat-expansion-panel class="!bg-white shadow !rounded-none">
                <mat-expansion-panel-header class="p-2">
                    <mat-panel-title class="flex items-center">
                        <mat-icon class="mr-2">view_module</mat-icon>
                        {{ 'COMPONENT.SETTINGS.label_interaction_modules' | translate }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-list class="p-2">
                    <mat-list-item *ngFor="let item of interactionModule">
                        {{ item.name }}
                    </mat-list-item>
                </mat-list>
            </mat-expansion-panel>

            <!-- Reset DB -->
            <mat-list-item
                (click)="onClickOnResetDb()"
                class="!bg-white shadow !cursor-pointer hover:!bg-[var(--mat-expansion-header-hover-state-layer-color)] p-2"
            >
                <div class="flex items-center justify-between w-full">
                    <div class="flex items-center">
                        <mat-icon class="mr-2">restart_alt</mat-icon>
                        <span>{{ 'COMPONENT.SETTINGS.reset_local_db' | translate }}</span>
                    </div>
                    <mat-icon>chevron_right</mat-icon>
                </div>
            </mat-list-item>

            <!-- Sync All Data -->
            <mat-list-item
                (click)="syncAllData()"
                [ngClass]="{'shadow opacity-50 pointer-events-none !cursor-default': !isRegistered}"
                class="!bg-white shadow !cursor-pointer hover:!bg-[var(--mat-expansion-header-hover-state-layer-color)] p-2"
            >
                <div class="flex items-center justify-between w-full">
                    <div class="flex items-center">
                        <mat-icon class="mr-2">cloud_sync</mat-icon>
                        <span>{{ 'COMPONENT.SETTINGS.sync_all_data' | translate }}</span>
                    </div>
                    <mat-icon>chevron_right</mat-icon>
                </div>
            </mat-list-item>

            <!-- Simulate Offline Checkbox (neu) -->
            <mat-list-item class="!bg-white shadow cursor-pointer hover:!bg-[var(--mat-expansion-header-hover-state-layer-color)] pl-2">
                <div class="flex items-center justify-between w-full">
                    <div class="w-full flex items-center">
                        <mat-icon class="mr-2">flight</mat-icon>
                        <span>{{ 'COMPONENT.SETTINGS.simulate_offline' | translate }}</span>
                    </div>
                    <mat-checkbox
                        (ngModelChange)="onSimulateOfflineChange($event)"
                        [ngModel]="(settings$ | async)?.simulateOffline"
                        class="w-fit scale-125"
                    ></mat-checkbox>
                </div>
            </mat-list-item>

            <!-- Server Setup -->
            <mat-list-item
                (click)="onClickOnServerSetup()"
                class="!bg-white shadow !cursor-pointer hover:!bg-[var(--mat-expansion-header-hover-state-layer-color)] p-2"
            >
                <div class="flex items-center justify-between w-full">
                    <div class="flex items-center">
                        <mat-icon class="mr-2">cloud</mat-icon>
                        <span>{{ 'COMPONENT.SETTINGS.server_setup' | translate }}</span>
                    </div>
                    <mat-icon>chevron_right</mat-icon>
                </div>
            </mat-list-item>
        </mat-accordion>
    </mat-list>

    <div class="my-4"></div>

    <div class="text-right text-gray-500">
        <div>Checkpad MED 2 <span class="text-sm font-light">v{{ appVersion }}</span></div>
        <div class="text-sm font-light">
            Letzte Aktualisierung: {{ buildTime | date: 'dd.MM.YYYY, H' }} Uhr
        </div>
    </div>
</div>
