<div class="w-full h-full flex flex-col overflow-y-auto">
    <div *ngFor="let element of findingsList">
        <!-- Header für Gruppierungen -->
        <div *ngIf="element.type === 'header'" class="flex flex-row gap-2 items-center">
            <span class="text-gray-700">{{ element.text }}</span>
            <span class="text-gray-400 font-light text-sm">({{ element?.count || 0 }})</span>
        </div>

        <!-- Einträge -->
        <div
            (click)="selectFinding(element)"
            *ngIf="element.type === 'item' && element.data.record?.name === currentRecord?.name"
            [ngClass]="{'!border-[var(--primary-color)]': element.data.selected}"
            class="border-transparent mb-4 p-2 bg-white shadow-md border-2 cursor-pointer flex flex-col gap-1 relative"
        >
            <!-- Titel -->
            <span class="text-gray-700 text-lg font-light">{{ element?.text ?? '-' }}</span>

            <!-- Allgemeine Informationen -->
            <div *ngIf="element.data?.documentType !== 'lab-report'" class="flex flex-row gap-1">
                <span class="text-gray-500 font-light text-sm">B: {{ (element.data?.created_at | date: 'dd.MM.yyyy HH:mm') ?? '-' }}</span>
                <span class="text-gray-500 font-light text-sm">E: {{ (element.data?.dateTimeOfRecord | date: 'dd.MM.yyyy HH:mm') ?? '-' }}</span>
            </div>

            <!-- Laborbefund-spezifische Anzeige -->
            <div *ngIf="element.data?.documentType === 'lab-report'">
                <div class="text-gray-700 font-light text-sm">{{ element.data?.result_groups ?? '-' }}</div>
                <div class="flex flex-row gap-1">
                    <span class="text-gray-500 font-light text-sm">B: {{ (element.data?.reported_date_time | date: 'dd.MM.yyyy HH:mm') ?? '-' }}</span>
                    <span class="text-gray-500 font-light text-sm">E: {{ (element.data?.received_date_time | date: 'dd.MM.yyyy HH:mm') ?? '-' }}</span>
                </div>
            </div>

            <!-- Löschen-Button -->
            <div [matTooltip]="'Befund löschen'" class="absolute right-1 top-1">
                <mat-icon class="icon-color">delete</mat-icon>
            </div>
        </div>
    </div>
</div>
