var Events;
(function (Events) {
    Events["ERROR_EVENT"] = "CORNERSTONE_ERROR";
    Events["CACHE_SIZE_EXCEEDED"] = "CACHE_SIZE_EXCEEDED";
    Events["IMAGE_LOAD_ERROR"] = "IMAGE_LOAD_ERROR";
    Events["CAMERA_MODIFIED"] = "CORNERSTONE_CAMERA_MODIFIED";
    Events["CAMERA_RESET"] = "CORNERSTONE_CAMERA_RESET";
    Events["VOI_MODIFIED"] = "CORNERSTONE_VOI_MODIFIED";
    Events["PRESET_MODIFIED"] = "CORNERSTONE_VIEWPORT_RENDERING_PRESET_MODIFIED";
    Events["DISPLAY_AREA_MODIFIED"] = "CORNERSTONE_DISPLAY_AREA_MODIFIED";
    Events["ELEMENT_DISABLED"] = "CORNERSTONE_ELEMENT_DISABLED";
    Events["ELEMENT_ENABLED"] = "CORNERSTONE_ELEMENT_ENABLED";
    Events["IMAGE_RENDERED"] = "CORNERSTONE_IMAGE_RENDERED";
    Events["IMAGE_VOLUME_MODIFIED"] = "CORNERSTONE_IMAGE_VOLUME_MODIFIED";
    Events["IMAGE_VOLUME_LOADING_COMPLETED"] = "CORNERSTONE_IMAGE_VOLUME_LOADING_COMPLETED";
    Events["IMAGE_LOADED"] = "CORNERSTONE_IMAGE_LOADED";
    Events["IMAGE_RETRIEVAL_STAGE"] = "CORNERSTONE_IMAGE_RETRIEVAL_STAGE";
    Events["IMAGE_LOAD_FAILED"] = "CORNERSTONE_IMAGE_LOAD_FAILED";
    Events["VOLUME_VIEWPORT_NEW_VOLUME"] = "CORNERSTONE_VOLUME_VIEWPORT_NEW_VOLUME";
    Events["VOLUME_LOADED"] = "CORNERSTONE_VOLUME_LOADED";
    Events["VOLUME_LOADED_FAILED"] = "CORNERSTONE_VOLUME_LOADED_FAILED";
    Events["IMAGE_CACHE_IMAGE_ADDED"] = "CORNERSTONE_IMAGE_CACHE_IMAGE_ADDED";
    Events["IMAGE_CACHE_IMAGE_REMOVED"] = "CORNERSTONE_IMAGE_CACHE_IMAGE_REMOVED";
    Events["VOLUME_CACHE_VOLUME_ADDED"] = "CORNERSTONE_VOLUME_CACHE_VOLUME_ADDED";
    Events["VOLUME_CACHE_VOLUME_REMOVED"] = "CORNERSTONE_VOLUME_CACHE_VOLUME_REMOVED";
    Events["STACK_NEW_IMAGE"] = "CORNERSTONE_STACK_NEW_IMAGE";
    Events["VOLUME_NEW_IMAGE"] = "CORNERSTONE_VOLUME_NEW_IMAGE";
    Events["PRE_STACK_NEW_IMAGE"] = "CORNERSTONE_PRE_STACK_NEW_IMAGE";
    Events["IMAGE_SPACING_CALIBRATED"] = "CORNERSTONE_IMAGE_SPACING_CALIBRATED";
    Events["STACK_VIEWPORT_NEW_STACK"] = "CORNERSTONE_STACK_VIEWPORT_NEW_STACK";
    Events["STACK_VIEWPORT_SCROLL"] = "CORNERSTONE_STACK_VIEWPORT_SCROLL";
    Events["STACK_SCROLL_OUT_OF_BOUNDS"] = "STACK_SCROLL_OUT_OF_BOUNDS";
    Events["GEOMETRY_CACHE_GEOMETRY_ADDED"] = "CORNERSTONE_GEOMETRY_CACHE_GEOMETRY_ADDED";
    Events["VOLUME_SCROLL_OUT_OF_BOUNDS"] = "VOLUME_SCROLL_OUT_OF_BOUNDS";
    Events["VOLUME_VIEWPORT_SCROLL"] = "VOLUME_VIEWPORT_SCROLL";
    Events["CLIPPING_PLANES_UPDATED"] = "CORNERSTONE_CLIPPING_PLANES_UPDATED";
    Events["WEB_WORKER_PROGRESS"] = "CORNERSTONE_WEB_WORKER_PROGRESS";
    Events["COLORMAP_MODIFIED"] = "CORNERSTONE_COLORMAP_MODIFIED";
})(Events || (Events = {}));
export default Events;
