<div class="patient-task-info !items-center w-full !p-0 h-full">
    <div (click)="onClickOnElement(getPatientNameAndDob())" class="flex w-full flex-row items-center justify-between">
        <mat-list *ngIf="latestTask?.data; else noDataBlock" class="patient-task">
            <div class="patient-task-name table-text">
                {{ latestTask?.data?.task_name }}
            </div>
            <div
                [ngClass]="{ 'color-danger': latestTask?.data?.priority === 'veryHigh' }"
                class="table-date patient-task-date"
            >
                {{ latestTask?.data?.contractor?.first_name }}
                {{ latestTask?.data?.contractor?.last_name }}
                {{ latestTask?.data?.goalDateOn | date: ('GENERAL.date_time_format' | translate) }}
            </div>
        </mat-list>

        <ng-template #noDataBlock>
            <div>Keine Aufgaben</div>
        </ng-template>

        <div class="patient-task-button">
            <button (click)="onClickOnAddTask($event)" *ngIf="patient && latestTask" mat-icon-button>
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>

    <!-- Clip toggle button outside the loop -->
    <div class="patient-task-button">
        <button (click)="toggleClip(); $event.stopPropagation()" *ngIf="showButton" mat-icon-button>
            <mat-icon>{{ isClipped ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</mat-icon>
        </button>
    </div>
</div>
