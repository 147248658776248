import { Cp2ApiService } from './../../services/cp2-api.service';
import { buildTime } from './../../build-info';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { SettingsFacadeService } from '../../services/facades/settings-facade.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Settings } from '../../models/settings.model';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { firstValueFrom, Subscription } from 'rxjs';
import { packageJson } from '../../../environments/env';
import { take, tap } from 'rxjs/operators';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ThemeService } from '../../services/theme.service';
import { MatChipsModule } from '@angular/material/chips';
import { RecordsFacadeService } from '../../services/facades/records-facade.service';
import { AreaFacadeService } from '../../services/facades/areas-facade.service';
import { Record, Region, SubRegion } from '../../models/patient-records.model';
import { DataRepositoryService } from '../../services/datarepository.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
    ServerSettingsModalComponent,
    ServerSettingsModalData,
    ServerSettingsModalResult,
} from '../../modals/server-settings-modal/server-settings-modal.component';
import { ServerSettingsFacadeService } from '../../services/facades/server-settings-facade.service';
import { AccessFacadeService } from '../../services/facades/access-facade.service';
import { ViewContentCacheService } from '../../services/cache/view-content-cache.service';
import { ToolboxService } from '../../services/toolbox.service';
import { AlertService } from '../../services/alert.service';
import { RegistrationService } from '../../services/registration.service';

interface LanguageTranslations {
    COMPONENT: {
        SETTINGS: {
            LANGUAGE: {
                german_label: string;
                english_label: string;
            };
        };
    };
}

/**
 * Component responsible for displaying and managing application settings.
 * Allows users to change the application's language setting.
 */
@Component({
    selector: 'app-setting',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatCardModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatSelectModule,
        RouterModule,
        TranslateModule,
        MatCheckboxModule,
        MatChipsModule,
    ],
})
export class SettingsComponent implements OnInit, OnDestroy {
    /** Currently selected language for the application interface. */
    public selectedLanguage: string = '';
    /** Available languages for the application interface. */
    public languages: Array<{ lang: string; title: string }> = [];

    /** Available color schemes for the application interface. */
    public colorSchemes: Array<{ color: string; mode: string; label: string }> = [
        { color: 'default', mode: 'light', label: 'Standard' },
        { color: 'rostock', mode: 'light', label: 'Rostock' },
    ];

    /** Currently selected color scheme for the application interface. */
    public selectedColorSchemas: string = '';

    /** Indicates whether data anonymization is active. */
    public activeAnonymous!: boolean;

    /** Array of synchronization items for the application interface. */
    public syncArray: { name: string; selected: boolean }[] = [
        { name: 'ACH Station 11', selected: true },
        { name: 'GAST Station 33', selected: true },
        { name: 'Kardiologie Privat', selected: true },
        { name: 'KARD Station 22', selected: true },
        { name: 'Ambulanz', selected: true },
        { name: 'TX', selected: true },
        { name: 'Prä-OP-Präsentation', selected: false },
        { name: 'Post-OP-Präsentation', selected: false },
        { name: 'Dienstpräsentation', selected: false },
        { name: 'Gespeicherte Patienten', selected: false },
        { name: 'Tagesklinik ITZ Alle', selected: false },
        { name: 'Tagesklinik ITZ Unzugeordnet', selected: false },
        { name: 'Tagesklinik ITZ Meine', selected: false },
        { name: 'Tagesklinik ITZ Zugeordnet', selected: false },
    ];

    /** Array of interaction modules for the application interface. */
    public interactionModule: { name: string }[] = [
        { name: 'Neues Interacktionsmodul' },
        { name: 'Registrierter Patient' },
        { name: 'Rektum Ca' },
        { name: 'Shilddrüse' },
        { name: 'Stroke' },
    ];

    /** New patient record area details. */
    public newPatientRecordArea: Region = {
        id: 0, // Changed to number, as per your Area interface
        code: '', // Added this as it's in your Area interface
        record: '', // This replaces recordId
        long_text: '', // This replaces name
        short_text: '', // Added this as it's in your Area interface
        valid_until: '', // This replaces validUntil
        // Optional fields that are in your Area interface
        center: undefined,
        location: undefined,
        created_by: undefined,
        department: undefined,
        updated_by: undefined,
        corporation: undefined,
        serviceunit: undefined,
    };

    /** Array of areas for patient records. */
    public areas: Region[] = [];

    /** Array of subAreas for patient records. */
    public subAreas: SubRegion[] = [];

    /** Array of records for patient records. */
    public records: Record[] = [];

    /** Selected record for patient records. */
    public selectedRecord: Record = {} as Record;

    /** Application version. */
    public appVersion = '';

    /** Dictionary to manage visibility of add sub-area forms by area ID. */
    public showAddSubArea: { [key: string]: boolean } = {};

    /** Name of the new sub-area being added. */
    public newSubAreaName: string = '';

    /** Indicates whether the dropdown is open. */
    public isDropdownOpen = false;
    displayedColumns: string[] = ['name', 'subAreas', 'validFrom', 'validUntil', 'actions'];

    public buildTime = buildTime;
    public settings$ = this.settingsFacade.settings$;
    public isRegistered: boolean = false;
    private registrationSub!: Subscription;
    /** List of subscriptions to manage subscriptions. */
    private allSubs: Subscription[] = [];

    /**
     * Initializes the component with necessary services.
     * @param api
     * @param alert
     * @param accesFacade
     * @param dialog
     * @param repo
     * @param serverSettingsFacade
     * @param settingsFacade Service to interact with application settings.
     * @param tb
     * @param themeService Service to handle theme changes.
     * @param translate Service to handle internationalization.
     * @param recordsFacade
     * @param areasFacade
     * @param vcCache
     * @param registrationService
     */
    public constructor(
        private api: Cp2ApiService,
        private alert: AlertService,
        private areasFacade: AreaFacadeService,
        private accesFacade: AccessFacadeService,
        private readonly dialog: MatDialog,
        private recordsFacade: RecordsFacadeService,
        private repo: DataRepositoryService,
        private serverSettingsFacade: ServerSettingsFacadeService,
        private settingsFacade: SettingsFacadeService,
        private tb: ToolboxService,
        private themeService: ThemeService,
        private translate: TranslateService,
        private vcCache: ViewContentCacheService,
        private registrationService: RegistrationService
    ) {
        this.appVersion = packageJson.version;

        this.areasFacade.loadAreas();
        this.areasFacade.loadSubAreas();
        this.recordsFacade.loadSelectedRecord();
        this.recordsFacade.loadRecords();
    }

    /**
     * Loads initial settings from the facade and subscribes to settings changes.
     */
    public async ngOnInit(): Promise<void> {
        this.registrationSub = this.registrationService.registrationStatus$.subscribe((status) => {
            this.isRegistered = status;
            this.loadInitialSettings();
        });

        this.allSubs.push(this.registrationSub);
    }

    /**
     * Cleans up subscriptions when the component is destroyed.
     */
    public ngOnDestroy(): void {
        this.allSubs.forEach((s: Subscription) => {
            s.unsubscribe();
        });
        this.allSubs = [];
    }

    //#region Listeners

    /**
     * Updates the selected language based on user selection.
     * @param $event Material select change event containing the new language value.
     */
    public onSettingLangChange($event: any): void {
        const sub: Subscription = this.settingsFacade.settings$
            .pipe(
                take(1), // Takes the first emission from settings$ and automatically unsubscribes
                tap((currentSettings) => {
                    const updatedSettings = {
                        ...currentSettings,
                        language: $event.options[0].value,
                    };
                    this.settingsFacade.saveSettings(updatedSettings);
                })
            )
            .subscribe();
        this.allSubs.push(sub);
    }

    /**
     * Updates the selected state of a synchronization item.
     * @param $event New selected state of the item.
     * @param item Synchronization item being updated.
     */
    public onSyncItemChange($event: boolean, item: { name: string; selected: boolean }) {
        item.selected = $event;
        // TODO: Save settings
    }

    /**
     * Updates the active state of data anonymization.
     * @param checked New state of data anonymization.
     */
    public onAnonymizeDataClick(checked: boolean) {
        this.activeAnonymization(checked);
    }

    public async onClickOnResetDb(): Promise<void> {
        const res = confirm(this.translate.instant('COMPONENT.SETTINGS.reset_local_db_alert_msg'));
        if (res) {
            await this.repo.deleteDatabase();
            location.reload();
        }
    }

    public onSimulateOfflineChange($event: boolean) {
        this.settingsFacade.settings$.pipe(take(1)).subscribe((s) => {
            const newSettings: Settings = { ...s, simulateOffline: $event };
            this.settingsFacade.saveSettings(newSettings);
        });
    }

    public async onClickOnRequestAllLocators(): Promise<void> {
        const token = (await firstValueFrom(this.accesFacade.userTokens$)).token;
        if (!token) {
            console.error('No token found');
            return;
        }
        const device = await this.repo.getDevice();
        const device_uuid = device.deviceId;
        const user_uuid = token.related_user_id;

        let alertText = this.translate.instant('COMPONENT.SETTINGS.alert_message_locators_resend_success');
        try {
            await this.api.requestLocatorsResend({ device_uuid, user_uuid }, token.access_token);
        } catch (error) {
            alertText = this.translate.instant('COMPONENT.SETTINGS.alert_message_locators_resend_fail');
        }
        await this.alert.show({ message: alertText, buttons: [{ role: 'cancel', text: 'OK' }] });
    }

    public async onClickOnSyncCaseList(): Promise<void> {
        await this.tb.executeInLoading(async () => await this.vcCache.fetchAndSyncCaseListFromApi());
    }

    public async syncAllData() {
        if (this.isRegistered) {
            await this.onClickOnSyncCaseList();
            await this.onClickOnRequestAllLocators();
            location.reload();
        }
    }

    //#endregion

    /**
     * Updates the settings to activate or deactivate data anonymization.
     * @param $event New state of data anonymization.
     */
    public activeAnonymization($event: any) {
        const sub = this.settingsFacade.settings$
            .pipe(
                take(1), // Takes the first emission from settings$ and automatically unsubscribes
                tap((currentSettings) => {
                    const updatedSettings = {
                        ...currentSettings,
                        activeAnonymous: $event,
                    };
                    this.settingsFacade.saveSettings(updatedSettings);
                })
            )
            .subscribe();
        this.allSubs.push(sub);
    }

    /**
     * Changes the color scheme of the application.
     * @param colorScheme The new color scheme to be applied.
     */
    public changeColorScheme(colorScheme: any) {
        this.themeService.changeColorScheme(colorScheme.source._value?.toString());

        const sub: Subscription = this.settingsFacade.settings$
            .pipe(
                take(1), // Takes the first emission from settings$ and automatically unsubscribes
                tap((currentSettings) => {
                    const updatedSettings = {
                        ...currentSettings,
                        colorScheme: colorScheme.source._value?.toString(),
                    };
                    this.settingsFacade.saveSettings(updatedSettings);
                })
            )
            .subscribe();
        this.allSubs.push(sub);
    }

    public async editSubArea(subArea: SubRegion): Promise<void> {
        const updatedSubArea = {
            ...subArea,
            validUntil: subArea.valid_until,
        };

        try {
            this.areasFacade.updateSubArea(updatedSubArea);
        } catch (error) {
            console.error('Failed to edit subArea:', error);
        }
    }

    public async updateValidUntil(event: any, subArea: SubRegion) {
        const isoDate = new Date(event.target.value).toISOString();
        // Create a new object with the updated validUntil property in ISO format
        const updatedSubArea = {
            ...subArea,
            validUntil: isoDate,
        };
        await this.editSubArea(updatedSubArea);
    }

    public toggleAddSubArea(areaId: string): void {
        this.showAddSubArea[areaId] = !this.showAddSubArea[areaId];
    }

    /*public getFilteredAreasBySelectedRecord(): Area[] {
        return this.areas.filter(
            (area) => area.recordId === this.selectedRecord.id
        );
    }*/

    /**
     * Toggles the state of the dropdown menu.
     */
    toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
    }

    selectRecord(record: Record) {
        this.selectedRecord = record;
        this.isDropdownOpen = false;
    }

    /** Open server settings dialog and process the results */
    public async onClickOnServerSetup(): Promise<void> {
        const serverSettings = this.serverSettingsFacade.snapshot;
        const dialogConfig: MatDialogConfig<ServerSettingsModalData> = { width: '80%' };
        if (serverSettings) dialogConfig.data = { serverSettings };
        const dialogRef = this.dialog.open<
            ServerSettingsModalComponent,
            ServerSettingsModalData,
            ServerSettingsModalResult
        >(ServerSettingsModalComponent, dialogConfig);

        const res = await firstValueFrom(dialogRef.afterClosed());
        if (res?.role === 'cancel' || !res?.serverSettings) return;

        this.serverSettingsFacade.saveServerSettings(res.serverSettings);
    }

    /**
     * Loads the initial language settings and available languages from the settings service.
     * Initializes the language options for the UI based on available translations.
     */
    private loadInitialSettings(): void {
        const sub: Subscription = this.settingsFacade.settings$.subscribe((settings: Settings) => {
            this.selectedLanguage = settings.language;
            this.activeAnonymous = settings.activeAnonymous;
            this.selectedColorSchemas = settings.colorScheme;
            this.translate.getTranslation(this.selectedLanguage).subscribe((translations: LanguageTranslations) => {
                this.languages = [
                    {
                        lang: 'de',
                        title: translations.COMPONENT.SETTINGS.LANGUAGE.german_label,
                    },
                    {
                        lang: 'en',
                        title: translations.COMPONENT.SETTINGS.LANGUAGE.english_label,
                    },
                ];
            });
        });
        this.allSubs.push(sub);
    }
}
