import { getToolGroup } from '../../store/ToolGroupManager';
import triggerAnnotationRenderForViewportIds from '../triggerAnnotationRenderForViewportIds';
import { getRenderingEngine } from '@cornerstonejs/core';
import { getBrushToolInstances } from './utilities';
export function invalidateBrushCursor(toolGroupId) {
    const toolGroup = getToolGroup(toolGroupId);
    if (toolGroup === undefined) {
        return;
    }
    const brushBasedToolInstances = getBrushToolInstances(toolGroupId);
    brushBasedToolInstances.forEach((tool) => {
        tool.invalidateBrushCursor();
    });
    const viewportsInfo = toolGroup.getViewportsInfo();
    const viewportsInfoArray = Object.keys(viewportsInfo).map((key) => viewportsInfo[key]);
    if (!viewportsInfoArray.length) {
        return;
    }
    const { renderingEngineId } = viewportsInfoArray[0];
    const viewportIds = toolGroup.getViewportIds();
    const renderingEngine = getRenderingEngine(renderingEngineId);
    triggerAnnotationRenderForViewportIds(renderingEngine, viewportIds);
}
