import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { VC_CommunicationMessage } from '../../../models/view-content.models/view-content.model';
import { MatTableModule } from '@angular/material/table';

@Component({
    selector: 'app-orga-tabs-tasks',
    templateUrl: './orga-tabs-tasks.component.html',
    styleUrls: ['./orga-tabs-tasks.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        TranslateModule,
    ],
})
export class OrgaTabsTasksComponent implements OnChanges {
    @ViewChild(MatSort) sort: MatSort | null = null;

    @Input() tasks: VC_CommunicationMessage[] = [];

    @Output() clickOnTask = new EventEmitter<VC_CommunicationMessage>();

    public displayedColumns: string[] = ['task_name', 'details', 'goalDateOn', 'priority', 'contractor', 'done'];

    public constructor() {}

    public ngOnChanges(changes: SimpleChanges): void {}

    public onRowClicked(row: VC_CommunicationMessage): void {
        this.clickOnTask.emit(row);
    }
}
