import { vec3 } from 'gl-matrix';
export default function pointInShapeCallback(imageData, pointInShapeFn, callback, boundsIJK) {
    let iMin, iMax, jMin, jMax, kMin, kMax;
    let scalarData;
    const { numComps } = imageData;
    if (imageData.getScalarData) {
        scalarData = imageData.getScalarData();
    }
    else {
        scalarData = imageData
            .getPointData()
            .getScalars()
            .getData();
    }
    if (!scalarData) {
        console.warn('No scalar data found for imageData', imageData);
        return;
    }
    const dimensions = imageData.getDimensions();
    if (!boundsIJK) {
        iMin = 0;
        iMax = dimensions[0];
        jMin = 0;
        jMax = dimensions[1];
        kMin = 0;
        kMax = dimensions[2];
    }
    else {
        [[iMin, iMax], [jMin, jMax], [kMin, kMax]] = boundsIJK;
    }
    const start = vec3.fromValues(iMin, jMin, kMin);
    const direction = imageData.getDirection();
    const rowCosines = direction.slice(0, 3);
    const columnCosines = direction.slice(3, 6);
    const scanAxisNormal = direction.slice(6, 9);
    const spacing = imageData.getSpacing();
    const [rowSpacing, columnSpacing, scanAxisSpacing] = spacing;
    const worldPosStart = imageData.indexToWorld(start);
    const rowStep = vec3.fromValues(rowCosines[0] * rowSpacing, rowCosines[1] * rowSpacing, rowCosines[2] * rowSpacing);
    const columnStep = vec3.fromValues(columnCosines[0] * columnSpacing, columnCosines[1] * columnSpacing, columnCosines[2] * columnSpacing);
    const scanAxisStep = vec3.fromValues(scanAxisNormal[0] * scanAxisSpacing, scanAxisNormal[1] * scanAxisSpacing, scanAxisNormal[2] * scanAxisSpacing);
    const xMultiple = numComps ||
        scalarData.length / dimensions[2] / dimensions[1] / dimensions[0];
    const yMultiple = dimensions[0] * xMultiple;
    const zMultiple = dimensions[1] * yMultiple;
    const pointsInShape = [];
    const currentPos = vec3.clone(worldPosStart);
    for (let k = kMin; k <= kMax; k++) {
        const startPosJ = vec3.clone(currentPos);
        for (let j = jMin; j <= jMax; j++) {
            const startPosI = vec3.clone(currentPos);
            for (let i = iMin; i <= iMax; i++) {
                const pointIJK = [i, j, k];
                if (pointInShapeFn(currentPos, pointIJK)) {
                    const index = k * zMultiple + j * yMultiple + i * xMultiple;
                    let value;
                    if (xMultiple > 2) {
                        value = [
                            scalarData[index],
                            scalarData[index + 1],
                            scalarData[index + 2],
                        ];
                    }
                    else {
                        value = scalarData[index];
                    }
                    pointsInShape.push({
                        value,
                        index,
                        pointIJK,
                        pointLPS: currentPos.slice(),
                    });
                    if (callback) {
                        callback({ value, index, pointIJK, pointLPS: currentPos });
                    }
                }
                vec3.add(currentPos, currentPos, rowStep);
            }
            vec3.copy(currentPos, startPosI);
            vec3.add(currentPos, currentPos, columnStep);
        }
        vec3.copy(currentPos, startPosJ);
        vec3.add(currentPos, currentPos, scanAxisStep);
    }
    return pointsInShape;
}
