import pointInShapeCallback from '../../../../utilities/pointInShapeCallback';
import StrategyCallbacks from '../../../../enums/StrategyCallbacks';
export default {
    [StrategyCallbacks.Fill]: (operationData) => {
        const { segmentsLocked, segmentationImageData, segmentationVoxelManager: segmentationVoxelManager, previewVoxelManager: previewVoxelManager, imageVoxelManager: imageVoxelManager, brushStrategy, centerIJK, } = operationData;
        const isWithinThreshold = brushStrategy.createIsInThreshold?.(operationData);
        const { setValue } = brushStrategy;
        const callback = isWithinThreshold
            ? (data) => {
                const { value, index } = data;
                if (segmentsLocked.includes(value) || !isWithinThreshold(index)) {
                    return;
                }
                setValue(operationData, data);
            }
            : (data) => setValue(operationData, data);
        pointInShapeCallback(segmentationImageData, imageVoxelManager?.isInObject || segmentationVoxelManager.isInObject, callback, segmentationVoxelManager.boundsIJK);
        previewVoxelManager.addPoint(centerIJK);
    },
};
