<mat-nav-list
    [ngClass]="{
        'nav-menu-handsetLandscape':
            currentHandyOrientation === 'handsetLandscape',
        'nav-menu':
            currentHandyOrientation !== 'handsetPortrait' &&
            currentHandyOrientation !== 'handsetLandscape'
    }"
    class="sm:relative"
    id="cp2-navigation"
>
    <div class="logo-back-button-container">
        <!-- Logo-->
        <div class="nav-menu-logo">
            <img src="../../assets/img/logo-checkpad-triosys.png"/>
        </div>
    </div>

    <!-- Back and forward buttons and main buttons -->
    <div class="nav-menu-buttons">
        <div
            *ngIf="
                currentHandyOrientation !== 'handsetPortrait' &&
                currentHandyOrientation !== 'handsetLandscape'
            "
            class="navigation-buttons"
        >
            <button
                (click)="onClickOnBack()"
                [disabled]="!canNavigateBack()"
                class="arrow-button !shadow"
                mat-mini-fab
            >
                <mat-icon class="mat-18">chevron_left</mat-icon>
            </button>

            <button
                (click)="onClickOnForward()"
                [disabled]="!canNavigateForward()"
                class="arrow-button !shadow"
                mat-mini-fab
            >
                <mat-icon class="mat-18">chevron_right</mat-icon>
            </button>
        </div>
        <!-- start, workflow and orga buttons -->
        <div
            [ngClass]="{
                'navigation-buttons-handsetLandscape':
                    currentHandyOrientation === 'handsetLandscape'
            }"
            class="navigation-buttons"
        >
            <ng-container *ngFor="let button of buttons">
                <ng-container
                    *ngIf="
                        currentHandyOrientation !== 'handsetPortrait' &&
                        currentHandyOrientation !== 'handsetLandscape'
                    "
                >
                    <button
                        (click)="onClickOnButton(button)"
                        *ngIf="isButtonActive(button); else nonActiveButton"
                        class="lbShadowSmall"
                        color="primary"
                        mat-flat-button
                    >
                        <mat-icon>done</mat-icon>
                        <ng-container>
                            {{ button.label | translate }}
                        </ng-container>
                    </button>
                </ng-container
                >

                <ng-container
                    *ngIf="
                        currentHandyOrientation === 'handsetPortrait' ||
                        currentHandyOrientation === 'handsetLandscape'
                    "
                >
                    <div class="flex flex-col justify-center items-center">
                        <button
                            (click)="onClickOnButton(button)"
                            *ngIf="isButtonActive(button); else nonActiveButton"
                            mat-icon-button
                        >
                            <mat-icon class="active-icon-handy">{{
                                    button.handyLabel
                                }}
                            </mat-icon>
                        </button>
                        <div class="text-xs">{{ button.abbreviation }}</div>
                    </div>
                </ng-container>
                <ng-template #nonActiveButton>
                    <ng-container
                        *ngIf="
                            currentHandyOrientation === 'handsetPortrait' ||
                                currentHandyOrientation === 'handsetLandscape';
                            else strokedButton
                        "
                    >
                        <button
                            (click)="onClickOnButton(button)"
                            mat-icon-button
                        >
                            <mat-icon>{{ button.handyLabel }}</mat-icon>
                        </button>
                        <!-- <p>{{button.abbreviation}}</p> -->
                    </ng-container>
                    <ng-template #strokedButton>
                        <button
                            (click)="onClickOnButton(button)"
                            color="primary"
                            mat-stroked-button
                        >
                            <mat-icon
                                *ngIf="
                                    currentHandyOrientation ===
                                        'handsetPortrait' ||
                                    currentHandyOrientation ===
                                        'handsetLandscape'
                                "
                            >
                                {{ button.handyLabel }}
                            </mat-icon>
                            <ng-container
                                *ngIf="
                                    currentHandyOrientation !==
                                        'handsetPortrait' &&
                                    currentHandyOrientation !==
                                        'handsetLandscape'
                                "
                            >
                                {{ button.label | translate }}
                            </ng-container>
                        </button>
                    </ng-template>
                </ng-template>
            </ng-container>
        </div>
        <div></div>
    </div>
</mat-nav-list>
