import {ActionReducerMap, MetaReducer} from '@ngrx/store';
import {AppState} from '../appstate.model';
import {authzReducer} from './authz.reducer';
import {accessReducer} from './access.reducer';
import {settingsReducer} from './settings.reducer';
import {deviceReducer} from './device.reducer';
import {mercureReducer} from './mercure.reducer';
import {recordsReducer} from './records.reducer';
import {locatorsReducer} from './locators.reducer';
import {viewContentReducer} from './viewcontent.reducer';

/**
 * Aggregated reducer map for the application.
 * This ActionReducerMap combines reducers from different features
 * into a single object, mapping each reducer to its corresponding state slice.
 */
export const reducers: ActionReducerMap<AppState> = {
    authz: authzReducer, // Reducer for authorization token management
    access: accessReducer, // Reducer for access control management
    device: deviceReducer, // Reducer for device info
    settings: settingsReducer,
    mercure: mercureReducer,
    records: recordsReducer,
    /*areas: areaReducer,*/
    locators: locatorsReducer,
    viewContent: viewContentReducer
    // Add your new reducers to this map when introducing new features.
};

/**
 * Meta reducers for the application.
 * Can be used to add additional functionality like logging, state freeze, etc.
 */
export const metaReducers: MetaReducer<AppState>[] = [
    // To add a meta reducer, push it to this array.
];
