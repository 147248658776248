import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VC_CommunicationMessage } from '../../../../models/view-content.models/view-content.model';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { CP2_User } from '../../../../models/view-content.models/view-content-personal-domain.model';

export interface ConversationTreeNode {
    m: VC_CommunicationMessage;
    responses: ConversationTreeNode[];
}

@Component({
    selector: 'app-conversation-tree-node',
    templateUrl: './conversation-tree-node.component.html',
    styleUrls: ['./conversation-tree-node.component.scss'],
    standalone: true,
    imports: [CommonModule, MatButtonModule, TranslateModule],
})
export class ConversationTreeNodeComponent implements OnInit {
    @Input() node: ConversationTreeNode | undefined;
    @Input() currentUser: CP2_User | undefined;

    @Output() clickOnRespond = new EventEmitter<ConversationTreeNode>();

    public constructor() {}

    public ngOnInit() {}

    public onClickOnRespond(): void {
        this.clickOnRespond.emit(this.node);
    }

    public forwardClick($event: ConversationTreeNode): void {
        this.clickOnRespond.emit($event);
    }
}
