import { invalidateBrushCursor } from '../../utilities/segmentation/';
import { getSegmentation, getToolGroupIdsWithSegmentation, } from './segmentationState';
import { triggerSegmentationModified } from './triggerSegmentationEvents';
function setActiveSegmentIndex(segmentationId, segmentIndex) {
    const segmentation = getSegmentation(segmentationId);
    if (typeof segmentIndex === 'string') {
        console.warn('segmentIndex is a string, converting to number');
        segmentIndex = Number(segmentIndex);
    }
    if (segmentation?.activeSegmentIndex !== segmentIndex) {
        segmentation.activeSegmentIndex = segmentIndex;
        triggerSegmentationModified(segmentationId);
    }
    const toolGroups = getToolGroupIdsWithSegmentation(segmentationId);
    toolGroups.forEach((toolGroupId) => {
        invalidateBrushCursor(toolGroupId);
    });
}
function getActiveSegmentIndex(segmentationId) {
    const segmentation = getSegmentation(segmentationId);
    if (segmentation) {
        return segmentation.activeSegmentIndex;
    }
}
export { getActiveSegmentIndex, setActiveSegmentIndex };
