import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {CommonModule, DatePipe} from '@angular/common';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {Router} from '@angular/router';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSort, MatSortModule} from '@angular/material/sort';
import {CalAgePipe} from '@lohmann-birkner/luic';
import {PatientListItem} from '../../../models/patient.model';
import {MatDialog} from '@angular/material/dialog';
import {FormModalComponent} from '../../../modals/form-modal/form-modal.component';
import {firstValueFrom} from 'rxjs';
import {SettingsFacadeService} from '../../../services/facades/settings-facade.service';
import {HomePatientBasicInfoComponent} from '../../home-page/home-patient-basic-info/home-patient-basic-info.component';
import {Settings} from '../../../models/settings.model';

export interface OrgaTasks {
    id: string;
    table_header_basic_info: PatientListItem;
    firstName: string;
    lastName: string;
    fullname: string;
    dob: string;
    bed: string;
    room: string;
    task_name: string;
    details: string;
    editor: string;
    goalDateOn: string;
    goalDate?: 'yesterday' | 'today' | 'tomorrow' | 'on';
    status: boolean;
    gender: string;
    contractor: string;
    ward: string;
    priority: string;
}

@Component({
    selector: 'app-orga-tabs-tasks',
    templateUrl: './orga-tabs-tasks.component.html',
    styleUrls: ['./orga-tabs-tasks.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatTableModule,
        TranslateModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        CalAgePipe,
        MatButtonModule,
        MatButtonToggleModule,
        MatSortModule,
        DatePipe,
        HomePatientBasicInfoComponent
    ]
})
export class OrgaTabsTasksComponent implements OnInit, AfterViewInit, OnChanges {
    @ViewChild(MatSort) sort: MatSort | null = null;

    @Input() orgaPatient: PatientListItem[] = [];
    @Input() filterValue: string = '';
    @Input() isWorkflowRoute: boolean = false;
    dataSource = new MatTableDataSource<OrgaTasks>([]);
    totalRecords: number = 0;

    currentLang: string = '';
    displayedColumns: string[] = [
        'table_header_basic_info',
        'task_name',
        'goalDateOn',
        'contractor',
        'status'
    ];
    private priorityMapping: { [key: string]: number } = {
        'sehr hoch': 4,
        hoch: 3,
        normal: 2,
        niedrig: 1
    };

    constructor(
        public dialog: MatDialog,
        private router: Router,
        private translate: TranslateService,
        private datePipe: DatePipe,
        private settings: SettingsFacadeService
    ) {
    }

    ngOnInit() {
        this.dataSource = this.transformDataForTasks();
        this.totalRecords = this.dataSource.data.length;
    }

    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item: any, property: string) => {
            switch (property) {
                case 'priority':
                    return this.priorityMapping[item[property]] || 0;
                case 'goalDateOn': {
                    let newDate = new Date(item.goalDateOn);
                    return newDate;
                }
                case 'dob': {
                    let newDate = new Date(item.dob);
                    return newDate;
                }
                default: {
                    return item[property];
                }
            }
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['orgaPatient'] || changes['filterValue']) {
            this.applyFilter();
        }
    }

    public async onClickOnElement(
        formFileName: string,
        data: any[]
    ): Promise<void> {
        const dialogRef = this.dialog.open(FormModalComponent, {
            data: {
                form_file_name: formFileName,
                form_data: {data}
            }
        });
        const res = await firstValueFrom(dialogRef.afterClosed());
    }

    private transformDataForTasks() {
        let tasks: OrgaTasks[] = [];

        this.orgaPatient?.forEach((patient) => {
            if (patient.tasks) {
                patient.tasks.forEach((task) => {
                    this.settings.settings$.subscribe((settings: Settings) => {
                        this.currentLang = settings.language;
                    });
                    let formatedDob;
                    const translatedGender = this.translate.instant(
                        'GENERAL.' + patient.gender
                    );

                    if (this.currentLang === 'en') {
                        formatedDob = this.datePipe.transform(
                            patient.dob,
                            'MM/dd/yyyy'
                        );
                    } else {
                        formatedDob = this.datePipe.transform(
                            patient.dob,
                            'dd.MM.yyyy'
                        );
                    }
                    tasks.push({
                        id: patient.id,
                        table_header_basic_info: patient,
                        firstName: patient.firstName,
                        lastName: patient.lastName,
                        fullname: `${patient.lastName}, ${patient.firstName}`,
                        dob: formatedDob ?? '',
                        ward: patient.ward,
                        gender: patient.gender,
                        task_name: task.task_name,
                        priority: task.priority,
                        editor: task.editor,
                        goalDateOn: task.goalDateOn,
                        details: task.details,
                        status: task.done,
                        contractor: task.contractor,
                        goalDate: task.goalDate,
                        bed: patient.bed,
                        room: patient.room
                    });
                });
            }
        });
        this.dataSource.data = tasks;
        return this.dataSource;
    }

    private applyFilter(): void {
        const filterValue = this.filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }
}
