import StrategyCallbacks from '../../../../enums/StrategyCallbacks';
export default {
    [StrategyCallbacks.INTERNAL_setValue]: (operationData, { value, index }) => {
        const { segmentsLocked, segmentIndex, previewVoxelManager: previewVoxelManager, previewSegmentIndex, segmentationVoxelManager: segmentationVoxelManager, } = operationData;
        const existingValue = segmentationVoxelManager.getAtIndex(index);
        if (segmentIndex === null) {
            const oldValue = previewVoxelManager.getAtIndex(index);
            if (oldValue !== undefined) {
                previewVoxelManager.setAtIndex(index, oldValue);
            }
            return;
        }
        if (existingValue === segmentIndex || segmentsLocked.includes(value)) {
            return;
        }
        if (existingValue === previewSegmentIndex) {
            if (previewVoxelManager.getAtIndex(index) === undefined) {
                segmentationVoxelManager.setAtIndex(index, segmentIndex);
            }
            else {
                return;
            }
        }
        const useSegmentIndex = previewSegmentIndex ?? segmentIndex;
        previewVoxelManager.setAtIndex(index, useSegmentIndex);
    },
};
