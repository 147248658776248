<div class="patient-task-info !items-center w-full !p-0 h-full">
    <div (click)="onClickOnElement(getPatientNameAndDob())" class="flex w-full flex-row items-center justify-between">
        <mat-list class="patient-task">
            <div class="patient-task-name table-text">
                {{ latestTasks?.data?.task_name }}
            </div>
            <div
                [ngClass]="{'color-danger': latestTasks?.data?.priority === 'veryHigh'}"
                class="table-date patient-task-date"
            >
                {{ latestTasks?.data?.contractor?.name }}
                {{ latestTasks?.data?.contractor?.surname }};
                {{ latestTasks?.data?.goalDateOn | date : ("GENERAL.date_time_format" | translate) }}
            </div>
        </mat-list>

        <div class="patient-task-button">
            <button
                (click)="openFormDialog('form_tasks.json', latestTasks, getPatientNameAndDob()); $event.stopPropagation()"
                *ngIf="patient && latestTasks"
                mat-icon-button
            >
                <mat-icon>add</mat-icon>
            </button>
        </div>

        <!--@for (task of patient?.tasks; track task.id) {
            <app-dynamic-list
                class="w-full"
                [list]="[task.data]"
                [clipToWhenSmall]="2"
                [patientId]="patient?.patient_details?.patient?.id ?? ''"
                [showButton]="false"
                [isClipped]="isClipped"
            >
                <ng-template #itemTemplate let-item="item">
                    <mat-list class="patient-task">
                        <div class="patient-task-name table-text">
                            {{ item.task_name }}
                        </div>
                        <div
                            class="table-date patient-task-date"
                            [ngClass]="{'color-danger': item.priority === 'veryHigh'}"
                        >
                            {{ item.contractor?.name }}
                            {{ item.contractor?.surname }};
                            {{ item.goalDateOn | date : ("GENERAL.date_time_format" | translate) }}
                        </div>
                    </mat-list>
                </ng-template>
            </app-dynamic-list>
            &lt;!&ndash; Add Button for each task &ndash;&gt;
            <div class="patient-task-button">
                <button
                    *ngIf="patient"
                    (click)="openFormDialog('form_tasks.json', task, getPatientNameAndDob()); $event.stopPropagation()"
                    mat-icon-button
                >
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        }-->
    </div>
    <!-- Clip toggle button outside the loop -->
    <div class="patient-task-button">
        <button
            (click)="toggleClip(); $event.stopPropagation()"
            *ngIf="showButton"
            mat-icon-button
        >
            <mat-icon>{{ isClipped ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</mat-icon>
        </button>
    </div>
</div>
