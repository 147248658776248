<div class="communication-conversations-container">
    <mat-accordion
        *ngFor="let tree of conversationTrees"
        [ngClass]="{ 'communication-own-conversation-tree': isOwnMessage(tree.m) }"
    >
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ tree.m.data.task_name }}
                </mat-panel-title>
                <mat-panel-description class="font-weight: 400">
                    {{ tree.m.data.goalDateOn | date: 'dd.MM.yyyy' }} -
                    {{ tree.m.data.details }}
                </mat-panel-description>
            </mat-expansion-panel-header>

            <app-conversation-tree-node
                (clickOnRespond)="onClickOnRespondMessage($event)"
                [currentUser]="user"
                [node]="tree"
            ></app-conversation-tree-node>
        </mat-expansion-panel>
    </mat-accordion>
</div>

<div class="communication-footer">
    <button (click)="onClickOnSend()" class="" extended mat-fab>
        <mat-icon>add</mat-icon>
        neue Nachricht
    </button>
</div>
