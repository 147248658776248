import triggerAnnotationRender from './triggerAnnotationRender';
export function triggerAnnotationRenderForViewportIds(renderingEngine, viewportIdsToRender) {
    if (!viewportIdsToRender.length || !renderingEngine) {
        return;
    }
    viewportIdsToRender.forEach((viewportId) => {
        const viewport = renderingEngine.getViewport(viewportId);
        if (!viewport) {
            console.warn(`Viewport not available for ${viewportId}`);
            return;
        }
        const { element } = viewport;
        triggerAnnotationRender(element);
    });
}
export default triggerAnnotationRenderForViewportIds;
