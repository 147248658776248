import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class MetadataService {
    public viewMetaData: boolean = false;

    constructor() {}
}
