import {Component, Input, OnInit} from '@angular/core';
import {MatDialog, MatDialogContent, MatDialogTitle} from '@angular/material/dialog';
import {FormModalComponent} from '../../../modals/form-modal/form-modal.component';
import {firstValueFrom} from 'rxjs';
import {PatientNameAndDob} from '../../../pages/patient-details/patient-details.component';
import {DynamicListComponent} from '../../data-interaction/dynamic-list/dynamic-list.component';
import {CommonModule} from '@angular/common';
import {MatIcon, MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {StartPagePopupListComponent} from '../../../modals/start-page-visit-popup-list/start-page-popup-list.component';
import dayjs from 'dayjs';
import {FormioRendererI18n} from '../../data-interaction/formio-renderer/formio-renderer.component';
import {VcPatientListItem} from '../../../models/view-content.models/view-content.model';
import {Visit_Record} from '../../../models/view-content.models/view-content-clinic-domain.model';

// export interface VisitRecord {
//     data: { id: string; note: string; date_and_time: string };
//     modifiedAt: string;
//     modifiedBy: CP2_User;
// }
@Component({
    selector: 'app-home-patient-visit',
    templateUrl: './home-patient-visit.component.html',
    styleUrls: ['./home-patient-visit.component.scss'],
    imports: [
        DynamicListComponent,
        CommonModule,
        MatIcon,
        MatListModule,
        TranslateModule,
        MatButtonModule,
        MatIconModule,
        MatDialogTitle,
        MatDialogContent
    ],
    standalone: true
})
export class HomePatientVisitComponent implements OnInit {
    @Input() patient: VcPatientListItem | undefined;
    @Input() viewContentI18n: FormioRendererI18n | undefined;

    @Input() currentBreakpoint: string = '';
    //#region control dynamic list


    /**
     * A flag indicating whether the list is currently clipped (i.e., showing a reduced number of items).
     */
    isClipped = true;
    //#end region
    latestRecordOfRecords: Visit_Record[] = [];

    public constructor(public dialog: MatDialog) {
    }

    public getPatientNameAndDob(): PatientNameAndDob {
        return {
            firstName: this.patient?.patient_details.patient.data.name ?? '',
            lastName: this.patient?.patient_details.patient.data.surname ?? '',
            dob: this.patient?.patient_details.patient.data.birth_date ?? '',
            gender: this.patient?.patient_details.patient.data.gender ?? '',
            ward:
                this.patient?.patient_details.current_place.data.room?.service_unit?.long_text ?? '',
            bed: this.patient?.patient_details.current_place.data.name ?? '',
            room:
                this.patient?.patient_details.current_place.data?.room?.long_text ?? ''
        };
    }

    public ngOnInit() {
        this.processVisits();
    }

    // open the existing visite formular
    public async onClickOnElement(
        currentVisit: any,
        patientInfo?: PatientNameAndDob
    ): Promise<void> {
        const dialogConfig: any = {
            data: {
                currentVisit: currentVisit,
                data: this.patient?.visit_record, //all the data in the history include the current one
                patient_info: patientInfo,
                viewContentI18n: this.viewContentI18n,
                currentBreakpoint: this.currentBreakpoint
            },
            panelClass: 'home-visite-list-dialog'
        };

        // Add logic for dialog position based on the currentBreakpoint
        if (
            this.currentBreakpoint === 'handsetPortrait' ||
            this.currentBreakpoint === 'handsetLandscape'
        ) {
            dialogConfig.position = {bottom: '0'};
            dialogConfig.height = '80%';
        }

        const dialogRef = this.dialog.open(
            StartPagePopupListComponent,
            dialogConfig
        );
        const res = await firstValueFrom(dialogRef.afterClosed());
    }

    /* open a new formular
    show the current time and date when user open a new formular
    */
    public async openFormDialog(
        formFileName: string,
        patientInfo?: PatientNameAndDob
    ): Promise<void> {
        const dialogConfig: any = {
            data: {
                form_file_name: formFileName,
                patient_info: patientInfo,
                form_data: {
                    data: {
                        id: '', //TODO:assign a uuid later
                        note: '',
                        date_and_time: this.getFormattedDate(new Date())
                    }
                }
            },
            panelClass: 'home-visite-list-dialog'
        };

        // Add logic for dialog position based on the currentBreakpoint
        if (
            this.currentBreakpoint === 'handsetPortrait' ||
            this.currentBreakpoint === 'handsetLandscape'
        ) {
            dialogConfig.position = {bottom: '0'};
            dialogConfig.height = '80%';
        }
        const dialogRef = this.dialog.open(FormModalComponent, dialogConfig);
        const res = await firstValueFrom(dialogRef.afterClosed());
        // TODO: Do something with the results
    }

    /* remove the :ss in the date data,because then the time can be shown in the form */
    public getFormattedDate(date: string | Date): string {
        if (!date) return '';

        // Convert the ISO date string to a formatted string
        return dayjs(date).format('YYYY-MM-DDTHH:mm');
    }

    /**
     * Toggles the clipped state of the list, showing more or less items accordingly.
     */
    public toggleClip(): void {
        this.isClipped = !this.isClipped;
    }


    private processVisits(): void {
        // Check if history exists and has elements
        if (
            !this.patient?.visit_record.history ||
            this.patient.visit_record.history.length === 0
        ) {
            return;
        }

        // Sort the history records by modifiedAt
        const sortedRecords = this.patient.visit_record.history.sort(
            (a, b) =>
                new Date(b.modifiedAt).getTime() -
                new Date(a.modifiedAt).getTime()
        );

        // // Reduce the sorted records to get the latest record per referenceId
        // const reducedRecords = sortedRecords.reduce((acc, record) => {
        //     // Assuming 'referenceId' exists in the record's data
        //     const referenceId = record.data.id;
        //     if (!acc[referenceId]) {
        //         acc[referenceId] = record;
        //     }
        //     return acc;
        // }, {} as { [key: string]: any });

        // Convert the object back to an array of records

        // Sort the final array by modifiedAt

    }

    // private sortVisitsByModifiedAt(records: any[]): void {
    //     records.sort(
    //         (a, b) =>
    //             new Date(b.modifiedAt).getTime() -
    //             new Date(a.modifiedAt).getTime()
    //     );
    // }
}
