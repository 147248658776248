import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FormModalComponent } from '../../../modals/form-modal/form-modal.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { filter, firstValueFrom, Subscription } from 'rxjs';
import { PatientNameAndDob } from '../../../pages/patient-details/patient-details.component';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { StartPageTaskPopupComponent } from '../../../modals/start-page-task-popup/start-page-task-popup.component';
import { FormioRendererI18n } from '../../data-interaction/formio-renderer/formio-renderer.component';
import { VC_Task, VcPatientListItem } from '../../../models/view-content.models/view-content.model';
import { Cp2ApiService } from '../../../services/cp2-api.service';
import { switchMap } from 'rxjs/operators';
import { AccessFacadeService } from '../../../services/facades/access-facade.service';
import { UserToken } from '../../../models/auth.model';
import { Task } from '../../../models/view-content.models/view-content-clinic-domain.model';
import { ToolboxService } from '../../../services/toolbox.service';
import dayjs from 'dayjs';

const historyI18n: FormioRendererI18n = {
    de: {
        done: 'Done',
        editor_name: 'Bearbeitername',
        editor_surname: 'Bearbeater Familienname',
        details: 'Details',
        priority: 'Piorität',
        task_name: 'Aufgabenname',
        contractor_name: 'Auftraggeber Name',
        contractor_surname: 'Auftraggeber Familienname',
        goalDateOn: 'Zieldatum',
    },
    en: {
        done: 'Done',
        editor_name: 'Editor name',
        editor_surname: 'Editor surname',
        details: 'Details',
        priority: 'Piority',
        task_name: 'Task name',
        contractor_name: 'Contractor name',
        contractor_surname: 'Contractor surname',
        goalDateOn: 'Goal date',
    },
};

@Component({
    selector: 'app-home-patient-tasks',
    templateUrl: './home-patient-tasks.component.html',
    styleUrls: ['./home-patient-tasks.component.scss'],
    standalone: true,
    imports: [CommonModule, MatIcon, TranslateModule, MatListModule, MatButtonModule, MatIconModule],
})
export class HomePatientTasksComponent implements OnInit, OnChanges, OnDestroy {
    @Input() patient: VcPatientListItem | undefined;
    @Input() viewContentI18n: FormioRendererI18n | undefined;
    @Input() currentBreakpoint: string = '';

    @Output() columnClickEvent = new EventEmitter<Promise<void>>();

    public showButton: boolean = false;
    public isClipped = true;
    public latestTaskofTasks: Task[] = [];
    public latestTask: VC_Task | undefined;
    public allSubs: Subscription[] = [];

    private accessToken: UserToken | undefined;

    public constructor(
        private accessFacade: AccessFacadeService,
        private api: Cp2ApiService,
        private tb: ToolboxService,
        public dialog: MatDialog
    ) {}

    public ngOnInit(): void {
        this.allSubs.push(
            this.accessFacade.userTokens$
                .pipe(
                    filter((e) => !!e.token),
                    switchMap(async (t) => {
                        this.accessToken = t.token ?? undefined;
                    })
                )
                .subscribe()
        );

        if (this.latestTaskofTasks.length > 3) {
            this.showButton = true;
        }
    }

    public ngOnChanges(): void {
        if (this.patient && this.patient?.tasks.length > 0) {
            this.latestTask = this.patient?.tasks.reduce((latest, task) => {
                return dayjs(task.created_at).isAfter(dayjs(latest.created_at)) ? task : latest;
            });
        } else {
            this.latestTask = {} as VC_Task;
        }
    }

    public ngOnDestroy(): void {
        this.allSubs.forEach((s) => s.unsubscribe());
    }

    public getPatientNameAndDob(): PatientNameAndDob {
        return {
            firstName: this.patient?.patient_details.patient.data.name ?? '',
            lastName: this.patient?.patient_details.patient.data.surname ?? '',
            dob: this.patient?.patient_details.patient.data.birth_date ?? '',
            gender: this.patient?.patient_details.patient.data.gender ?? '',
            ward: this.patient?.patient_details.current_place.data.room?.service_unit?.long_text ?? '',
            bed: this.patient?.patient_details.current_place.data.name ?? '',
            room: this.patient?.patient_details.current_place.data?.room?.long_text ?? '',
        };
    }

    public async onClickOnElement(patientInfo?: PatientNameAndDob): Promise<void> {
        const dialogConfig: MatDialogConfig = {
            data: {
                data: this.patient?.tasks,
                patient_info: patientInfo,
                viewContentI18n: this.viewContentI18n
                    ? this.tb.mergeI18n(historyI18n, this.viewContentI18n)
                    : historyI18n,
                currentBreakpoint: this.currentBreakpoint,
            },
            panelClass: 'home-task-list-dialog',
        };

        // Add logic for dialog position based on the currentBreakpoint
        if (this.currentBreakpoint === 'handsetPortrait' || this.currentBreakpoint === 'handsetLandscape') {
            dialogConfig.position = { bottom: '0' };
            dialogConfig.height = '80%';
        }

        const dialogRef = this.dialog.open(StartPageTaskPopupComponent, dialogConfig);
        const res = await firstValueFrom(dialogRef.afterClosed());
    }

    public async onClickOnAddTask($event: Event): Promise<void> {
        if (this.latestTask) {
            this.openDialog('form_tasks.json', this.latestTask, this.getPatientNameAndDob());
            $event.stopPropagation();
        }
    }

    public async openDialog(formFileName: string, task: VC_Task, patientInfo?: PatientNameAndDob): Promise<void> {
        const dialogConfig: any = {
            data: {
                form_file_name: formFileName,
                patient_info: patientInfo,
            },
            panelClass: 'home-task-list-dialog',
        };

        // Add logic for dialog position based on the currentBreakpoint
        if (this.currentBreakpoint === 'handsetPortrait' || this.currentBreakpoint === 'handsetLandscape') {
            dialogConfig.position = { bottom: '0' };
            dialogConfig.height = '80%';
        }

        const dialogRef = this.dialog.open(FormModalComponent, dialogConfig);
        const res = await firstValueFrom(dialogRef.afterClosed());

        const request: any = {
            related_patient_id: task.related_patient_id,
            related_case_id: task.related_case_id,
            details: task.data.details,
            task_name: task.data.task_name,
            priority: task.data.priority,
            goalDateOn: task.data.goalDateOn,
            done: task.data.done,
            editor: task.data.editor,
            contractor: task.data.contractor,
            answer_to: 0,
        };

        if (this.accessToken) {
            await this.api.saveTask(request, this.accessToken?.access_token);
        }
        // TODO: Do something with the results
    }

    /**
     * Toggles the clipped state of the list, showing more or less items accordingly.
     */
    public toggleClip(): void {
        this.isClipped = !this.isClipped;
    }
}
