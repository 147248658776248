import { Injectable } from '@angular/core';
import { CapacitorHttp, HttpResponse } from '@capacitor/core';
import { environment } from '../../environments/environment';
import { CanvasJson } from '@lohmann-birkner/luic';
import {
    FormioRendererData,
    FormioRendererForm,
} from '../components/data-interaction/formio-renderer/formio-renderer.component';

export interface ApiDocument {
    documenttext?: string;
    formioRendererData?: FormioRendererData[];
    formioRendererForm?: FormioRendererForm;
    mimetype: string;
    case_id: string;
    filename: string;
    document_type?: string;
    area?: string;
    subarea?: string;
}

@Injectable({
    providedIn: 'root',
})
export class DocsService {
    constructor() {}

    public async createDocument(accessToken: string, data: ApiDocument): Promise<HttpResponse> {
        const url = `${environment.apiServer}/docs-files/create_documents/`;

        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        };
        const request = {
            url,
            headers,
            data,
        };
        const response = await CapacitorHttp.post(request);

        return response;
    }

    public async saveDrawing(
        accessToken: string,
        data: {
            annotations: CanvasJson;
            document_id: string;
            case_id: string;
        }
    ): Promise<HttpResponse> {
        const url = `${environment.apiServer}/docs-files/save_draw/`;

        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        };

        return CapacitorHttp.post({
            url,
            headers,
            data,
        });
    }

    public async getDocuments(accessToken: string, patient_id: number, document_id: number): Promise<HttpResponse> {
        const url = `${environment.apiServer}/docs-files/get/?patient_id=${patient_id}&document_id=${document_id}`;

        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        };

        return CapacitorHttp.get({
            url,
            headers,
        });
    }
}
