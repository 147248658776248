import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as ViewContentActions from '../actions/viewcontent.action';
import { DataRepositoryService } from '../../services/datarepository.service';
import { AppState } from '../appstate.model';
import { Store } from '@ngrx/store';

/**
 * Effects for handling authorization tokens related operations.
 */
@Injectable()
export class ViewContentEffects {
    /** Effect for loading view content based on the locator */

    /** Effect for saving or updating view content */
        // saveViewContent$ = createEffect(() =>
        //     this.actions$.pipe(
        //         ofType(ViewContentActions.setViewContent), // extrahiere die Action
        //         switchMap(({ viewContent, user }) => {
        //             console.log('Saving view content and user:', viewContent, user); // Logging für Debugging
        //             // Speichere ViewContent und User in die SQLiteDB
        //             return from(
        //                 this.repo.savePatientListItem(viewContent, user)
        //             ).pipe(
        //                 map(() =>
        //                     ViewContentActions.setViewContentSuccess({
        //                         viewContent, // Erfolgreich gespeicherter ViewContent
        //                         user, // Erfolgreich gespeicherter User
        //                     })
        //                 ),
        //                 catchError((error) => {
        //                     console.error('Error saving view content:', error);
        //                     return of(
        //                         ViewContentActions.setViewContentFail({ error })
        //                     );
        //                 })
        //             );
        //         })
        //     )
        // );

    saveViewContent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ViewContentActions.setViewContent), // Listen for the action to set view content
            map(({ viewContent, user }) =>
                ViewContentActions.setViewContentSuccess({
                    viewContent,
                    user,
                })
            ) // Directly emit success when content is received
        )
    );

    public constructor(
        private actions$: Actions
    ) {}
}
