import { utilities as csUtils } from '@cornerstonejs/core';
import * as math from '../math';
import { getParentAnnotation, invalidateAnnotation, } from '../../stateManagement';
export default function updateContourPolyline(annotation, polylineData, transforms, options) {
    const { canvasToWorld, worldToCanvas } = transforms;
    const { data } = annotation;
    const { targetWindingDirection } = polylineData;
    let { points: polyline } = polylineData;
    if (options?.decimate?.enabled) {
        polyline = math.polyline.decimate(polylineData.points, options?.decimate?.epsilon);
    }
    let { closed } = polylineData;
    const numPoints = polyline.length;
    const polylineWorldPoints = new Array(numPoints);
    const currentPolylineWindingDirection = math.polyline.getWindingDirection(polyline);
    const parentAnnotation = getParentAnnotation(annotation);
    if (closed === undefined) {
        let currentClosedState = false;
        if (polyline.length > 3) {
            const lastToFirstDist = math.point.distanceToPointSquared(polyline[0], polyline[numPoints - 1]);
            currentClosedState = csUtils.isEqual(0, lastToFirstDist);
        }
        closed = currentClosedState;
    }
    let windingDirection = parentAnnotation
        ? parentAnnotation.data.contour.windingDirection * -1
        : targetWindingDirection;
    if (windingDirection === undefined) {
        windingDirection = currentPolylineWindingDirection;
    }
    if (windingDirection !== currentPolylineWindingDirection) {
        polyline.reverse();
    }
    const handlePoints = data.handles.points.map((p) => worldToCanvas(p));
    if (handlePoints.length > 2) {
        const currentHandlesWindingDirection = math.polyline.getWindingDirection(handlePoints);
        if (currentHandlesWindingDirection !== windingDirection) {
            data.handles.points.reverse();
        }
    }
    for (let i = 0; i < numPoints; i++) {
        polylineWorldPoints[i] = canvasToWorld(polyline[i]);
    }
    data.contour.polyline = polylineWorldPoints;
    data.contour.closed = closed;
    data.contour.windingDirection = windingDirection;
    invalidateAnnotation(annotation);
}
