import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { MatButton, MatIconButton, MatMiniFabButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { JsonPipe, NgIf } from '@angular/common';
import { PdfViewerComponent } from '../../components/utility/pdf-viewer/pdf-viewer.component';
import { LabTableViewerComponent } from '../../components/patient-info/lab-table-viewer/lab-table-viewer.component';
import { DicomViewerComponent } from '../../components/dicom-viewer/dicom-viewer.component';
import { MetadataService } from '../../components/dicom-viewer/helper/metadata.service';

@Component({
    selector: 'app-fullscreen-dialog',
    templateUrl: './fullscreen-dialog.component.html',
    styleUrls: ['./fullscreen-dialog.component.scss'],
    imports: [
        MatDialogContent,
        MatDialogActions,
        MatButton,
        MatDialogClose,
        MatIconButton,
        MatIcon,
        MatMiniFabButton,
        JsonPipe,
        NgIf,
        PdfViewerComponent,
        LabTableViewerComponent,
        DicomViewerComponent,
    ],
    standalone: true,
})
export class FullscreenDialogComponent {
    public constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            data: any;
            fullScreen: boolean;
        },
        public metaDataService: MetadataService
    ) {}
}
