import { OrientationAxis, Events } from '../enums';
import { RENDERING_DEFAULTS } from '../constants';
import cache from '../cache';
import setDefaultVolumeVOI from './helpers/setDefaultVolumeVOI';
import { triggerEvent, isImageActor } from '../utilities';
import { setTransferFunctionNodes } from '../utilities/transferFunctionUtils';
import BaseVolumeViewport from './BaseVolumeViewport';
class VolumeViewport3D extends BaseVolumeViewport {
    constructor(props) {
        super(props);
        this.getRotation = () => 0;
        this.getCurrentImageIdIndex = () => {
            return undefined;
        };
        this.getCurrentImageId = () => {
            return null;
        };
        const { parallelProjection, orientation } = this.options;
        const activeCamera = this.getVtkActiveCamera();
        if (parallelProjection != null) {
            activeCamera.setParallelProjection(parallelProjection);
        }
        if (orientation && orientation !== OrientationAxis.ACQUISITION) {
            this.applyViewOrientation(orientation);
        }
    }
    resetCamera(resetPan = true, resetZoom = true, resetToCenter = true) {
        super.resetCamera(resetPan, resetZoom, resetToCenter);
        this.resetVolumeViewportClippingRange();
        return;
    }
    setSlabThickness(slabThickness, filterActorUIDs) {
        return null;
    }
    setBlendMode(blendMode, filterActorUIDs, immediate) {
        return null;
    }
    resetProperties(volumeId) {
        const volumeActor = volumeId
            ? this.getActor(volumeId)
            : this.getDefaultActor();
        if (!volumeActor) {
            throw new Error(`No actor found for the given volumeId: ${volumeId}`);
        }
        if (volumeActor.slabThickness) {
            volumeActor.slabThickness = RENDERING_DEFAULTS.MINIMUM_SLAB_THICKNESS;
            this.viewportProperties.slabThickness = undefined;
            this.updateClippingPlanesForActors(this.getCamera());
        }
        const imageVolume = cache.getVolume(volumeActor.uid);
        if (!imageVolume) {
            throw new Error(`imageVolume with id: ${volumeActor.uid} does not exist in cache`);
        }
        setDefaultVolumeVOI(volumeActor.actor, imageVolume, false);
        if (isImageActor(volumeActor)) {
            const transferFunction = volumeActor.actor
                .getProperty()
                .getRGBTransferFunction(0);
            setTransferFunctionNodes(transferFunction, this.initialTransferFunctionNodes);
        }
        this.setCamera(this.initialCamera);
        triggerEvent(this.element, Events.VOI_MODIFIED, super.getVOIModifiedEventDetail(volumeId));
    }
    resetSlabThickness() {
        return null;
    }
}
export default VolumeViewport3D;
