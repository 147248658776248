import { triggerEvent } from '@cornerstonejs/core';
import * as annotationStateManagement from '../../../stateManagement/annotation';
import interpolate from '../../contours/interpolation/interpolate';
import getInterpolationData from '../../contours/interpolation/getInterpolationData';
import EventTypes from '../../../enums/Events';
export default function deleteRelatedAnnotations(viewportData) {
    const { annotation } = viewportData;
    const interpolationAnnotations = getInterpolationData(viewportData, [
        { key: 'interpolationUID', value: viewportData.interpolationUID },
    ]);
    const referencedSliceIndex = annotation.metadata.sliceIndex;
    let minInterpolation = -1;
    let maxInterpolation = viewportData.sliceData.numberOfSlices;
    for (const [sliceIndex, annotations] of interpolationAnnotations.entries()) {
        if (sliceIndex === referencedSliceIndex) {
            continue;
        }
        const nonInterpolated = annotations.find((annotation) => !annotation.autoGenerated);
        if (!nonInterpolated) {
            continue;
        }
        if (sliceIndex < referencedSliceIndex) {
            minInterpolation = Math.max(sliceIndex, minInterpolation);
        }
        else {
            maxInterpolation = Math.min(sliceIndex, maxInterpolation);
        }
    }
    const removedAnnotations = [];
    for (const [sliceIndex, annotations] of interpolationAnnotations.entries()) {
        if (sliceIndex <= minInterpolation ||
            sliceIndex >= maxInterpolation ||
            sliceIndex === referencedSliceIndex) {
            continue;
        }
        annotations.forEach((annotationToDelete) => {
            if (annotationToDelete.autoGenerated) {
                annotationStateManagement.state.removeAnnotation(annotationToDelete.annotationUID);
                removedAnnotations.push(annotationToDelete);
            }
        });
    }
    if (removedAnnotations.length) {
        const eventDetails = {
            annotations: removedAnnotations,
            element: viewportData.viewport.element,
            viewportId: viewportData.viewport.id,
            renderingEngineId: viewportData.viewport.getRenderingEngine().id,
        };
        triggerEvent(viewportData.viewport.element, EventTypes.INTERPOLATED_ANNOTATIONS_REMOVED, eventDetails);
    }
    if (minInterpolation >= 0 &&
        maxInterpolation < viewportData.sliceData.numberOfSlices) {
        const nextAnnotation = interpolationAnnotations.get(maxInterpolation)[0];
        const viewportNewData = {
            viewport: viewportData.viewport,
            sliceData: {
                numberOfSlices: viewportData.sliceData.numberOfSlices,
                imageIndex: nextAnnotation.metadata.sliceIndex,
            },
            annotation: nextAnnotation,
            interpolationUID: nextAnnotation.interpolationUID,
        };
        interpolate(viewportNewData);
    }
}
