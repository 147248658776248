import { Component, Input } from '@angular/core';
import {
    LabGroup,
    LabResult,
    LabValue,
} from '../../../models/view-content.models/view-content-clinic-domain.model';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { StringReplacePipe } from "../../../pipes/string-replace.pipe";

@Component({
    selector: 'app-lab-result-viewer',
    templateUrl: './lab-result-viewer.component.html',
    styleUrls: ['./lab-result-viewer.component.scss'],
    imports: [CommonModule, MatListModule, StringReplacePipe],
    standalone: true,
})
export class LabResultViewerComponent {
    @Input() labResult: LabResult | undefined;

    public constructor() {}

    public getValueUnitLabel(v: LabValue): string | void {
        if (v.unit.long_text) return v.unit.long_text;
        if (v.unit.short_text) return v.unit.short_text;
        if (v.unit.code) return v.unit.code;
    }

    public getValueTitle(v: LabValue): string | void {
        if (v.method.long_text) return v.method.long_text;
        if (v.method.short_text) return v.method.short_text;
        if (v.method.code) return v.method.code;
    }

    public getGroupLabel(g: LabGroup): string | void {
        if (g.text_long) return g.text_long;
        if (g.text_short) return g.text_short;
    }
}
