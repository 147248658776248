import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-text-viewer',
    templateUrl: './text-viewer.component.html',
    styleUrls: ['./text-viewer.component.scss'],
    standalone: true,
})
export class TextViewerComponent implements OnInit {
    @Input() findingBase64: string | undefined;

    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit() {}

    /**
     * Wandelt eine Base64-kodierte Zeichenfolge in einen HTML-sicheren Text um.
     * Dabei werden auch spezielle Formatierungen (z. B. Zeilenumbrüche, Tabs) konvertiert.
     */
    public decodeBase64(base64String: string | undefined): SafeHtml {
        if (!base64String) {
            return '';
        }

        let decodedText = this.b64DecodeUnicode(base64String.trim());

        // Ersetze den speziellen Marker "\E\.br\E\" durch ein HTML-Zeilenumbruch (<br>)
        let htmlFormatted = decodedText.replace(/\\E\\.br\\E\\/g, '<br>');

        // Ersetze alle Zeilenumbrüche durch <br>-Tags
        htmlFormatted = htmlFormatted.replace(/\r?\n/g, '<br>');

        // Ersetze Tabulatoren durch vier non-breaking spaces
        htmlFormatted = htmlFormatted.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');

        // Sanitizer verwenden, um den HTML-String als "sicher" zu markieren
        return this.sanitizer.bypassSecurityTrustHtml(htmlFormatted);
    }

    /**
     * Dekodiert eine Base64-kodierte Zeichenfolge unter Berücksichtigung von Unicode-Zeichen.
     */
    private b64DecodeUnicode(str: string): string {
        try {
            // Umwandlung: atob → Prozent-Encoding → decodeURIComponent
            return decodeURIComponent(
                atob(str)
                    .split('')
                    .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
                    .join('')
            );
        } catch (e) {
            console.error('Fehler beim Dekodieren der Base64-Zeichenfolge:', e);
            return '';
        }
    }
}
