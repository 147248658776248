<div class="w-full h-full bg-white">
    <!-- Andere Inhalte anzeigen -->
    <ng-container [ngSwitch]="selectedFinding?.data?.documentType">
        <!-- PDF -->
        <ng-container *ngSwitchCase="'pdf'">
            <app-media-toolbar
                (clickOnOpenFullscreen)="onClickOnOpenFullscreen()"
                (clickOnOpenPaintingTool)="onClickOnOpenPaintingTool()"
            ></app-media-toolbar>
            <div [ngClass]="{'!overflow-y-auto': canvasRendererService.getCanvasInitializationStatus()}" class="w-full h-[calc(100%-64px)] !overflow-x-hidden overflow-y-hidden">
                <div class="w-full h-full" id="previewWrapperPDF">
                    <div *ngIf="!canvasRendererService.getCanvasInitializationStatus()" class="w-full h-full flex flex-col items-center justify-center bg-white shadow gap-0">
                        <mat-spinner class="!h-12 !w-12"></mat-spinner>
                        <div class="text-sm text-gray-600 font-light mt-4">L&auml;dt...</div>
                    </div>

                    <div [ngClass]="{'hidden': !canvasRendererService.getCanvasInitializationStatus()}">
                        <canvas class="!w-full !h-full" id="previewCanvasPDF"></canvas>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- Image -->
        <ng-container *ngSwitchCase="'image'">
            <app-media-toolbar
                (clickOnOpenFullscreen)="onClickOnOpenFullscreen()"
                (clickOnOpenPaintingTool)="onClickOnOpenPaintingTool()"
            ></app-media-toolbar>
            <div [ngClass]="{'!overflow-y-auto': canvasRendererService.getCanvasInitializationStatus()}" class="w-full h-[calc(100%-64px)] !overflow-x-hidden overflow-y-hidden">
                <div class="w-full h-full" id="previewWrapperIMG">
                    <div *ngIf="!canvasRendererService.getCanvasInitializationStatus()" class="w-full h-full flex flex-col items-center justify-center bg-white shadow gap-0">
                        <mat-spinner class="!h-12 !w-12"></mat-spinner>
                        <div class="text-sm text-gray-600 font-light mt-4">L&auml;dt...</div>
                    </div>

                    <div [ngClass]="{'hidden': !canvasRendererService.getCanvasInitializationStatus()}">
                        <canvas class="!w-full !h-full" id="previewCanvasIMG"></canvas>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- DICOM -->
        <ng-container *ngSwitchCase="'dicom'">
            <app-dicom-viewer
                (onClickMakeScreenshot)="clickMakeScreenshot($event)"
                (onClickOpenFullscreen)="onClickOnOpenFullscreen()"
                [base64]="selectedFinding?.data?.base64"
                [finding]="selectedFinding?.data"
            ></app-dicom-viewer>
        </ng-container>

        <!-- Lab Report -->
        <ng-container *ngIf="allLabResults?.data?.documentType !== 'lab-table'">
            <ng-container *ngSwitchCase="'lab-report'">
                <div class="w-full h-full overflow-y-auto">
                    <app-lab-result-viewer [labResult]="selectedFinding?.data"></app-lab-result-viewer>
                </div>
            </ng-container>
        </ng-container>

        <!-- Form -->
        <ng-container *ngSwitchCase="'form'">
            <div class="w-full h-full overflow-y-auto p-2">
                <app-formio-renderer
                    (dataChange)="onChangeFormData($event)"
                    [data]="selectedFinding?.data?.formioRendererData ?? []"
                    [form]="selectedFinding?.data?.formioRendererForm"
                    [i18n]="selectedFinding?.data?.formioRendererI18n"
                    [maxRows]="10"
                    [readonly]="selectedFinding?.data?.edit_status === 'completed'"
                ></app-formio-renderer>
                <div *ngIf="selectedFinding?.data?.edit_status !== 'completed'">
                    <mat-card-actions class="justify-end">
                        <button (click)="clickSaveForm(selectedFinding)" mat-flat-button>
                            {{ 'Speichern' }}
                        </button>
                    </mat-card-actions>
                </div>
            </div>
        </ng-container>

        <!-- Text -->
        <ng-container *ngSwitchCase="'text'">
            <div class="w-full h-full overflow-y-auto p-2">
                <app-text-viewer [findingBase64]="selectedFinding?.data?.base64"></app-text-viewer>
            </div>
        </ng-container>

    </ng-container>

    <!-- Lab Table für Lab Report -->
    <ng-container *ngIf="selectedFinding?.data?.documentType === 'lab-report'" [ngSwitch]="allLabResults?.data?.documentType">
        <ng-container *ngSwitchCase="'lab-table'">
            <mat-toolbar>
                <span>Laborviewer</span>
                <span class="spacer-xy"></span>
                <span style="font-size: 0.7em">
                    Normalwerte {{ isLabTableShowLimits ? 'ausblenden' : 'anzeigen' }}
                </span>
                <button
                    (click)="isLabTableShowLimits = !isLabTableShowLimits"
                    mat-icon-button
                >
                    <mat-icon *ngIf="isLabTableShowLimits">toggle_on</mat-icon>
                    <mat-icon *ngIf="!isLabTableShowLimits">toggle_off</mat-icon>
                </button>
                <button
                    (click)="onClickOnOpenLabTableFullscreen()"
                    mat-icon-button
                >
                    <mat-icon>fullscreen</mat-icon>
                </button>
            </mat-toolbar>

            <div class="w-full h-full overflow-y-auto">
                <app-lab-table-viewer
                    [labResults]="allLabResults?.data?.allLabResults || []"
                    [showLimits]="isLabTableShowLimits"
                ></app-lab-table-viewer>
            </div>
        </ng-container>
    </ng-container>
</div>
