import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LuicModule } from '@lohmann-birkner/luic';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { Subscription } from 'rxjs';

/**
 * Interface representing a menu item on the top.
 */
export interface NavigationButton {
    label: string;
    handyLabel: string;
    link: string;
    activeOnRoutes: string[];
    abbreviation: string;
}

/**
 * AppNavigationComponent is responsible for rendering the application navigation bar.
 *
 * @export
 * @class AppNavigationComponent
 */
@Component({
    selector: 'app-app-navigation',
    templateUrl: './app-navigation.component.html',
    styleUrls: ['./app-navigation.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        LuicModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatListModule,
        MatSelectModule,
        MatSidenavModule,
        MatToolbarModule,
        RouterModule,
        TranslateModule,
    ],
})
export class AppNavigationComponent implements OnInit, OnDestroy {
    @Output() navigate = new EventEmitter<string>();

    // #region Variables
    public buttons: NavigationButton[] = [
        {
            label: 'COMPONENT.SIDEBAR.start',
            link: '/start',
            activeOnRoutes: ['/start'],
            handyLabel: 'home',
            abbreviation: 'Home',
        },
        {
            label: 'COMPONENT.SIDEBAR.orga',
            link: '/organization',
            activeOnRoutes: ['/organization', '/patient-details'],
            handyLabel: 'medical_services',
            abbreviation: 'Orga',
        },
        {
            label: 'COMPONENT.SIDEBAR.workflow',
            link: '/workflow',
            activeOnRoutes: ['/workflow'],
            abbreviation: 'Workflow',
            handyLabel: 'account_tree',
        },
    ];
    public selectedButton = this.buttons[0];

    private canGoBack: boolean = false;
    private canGoForward: boolean = false;
    private historyStack: string[] = [];
    private currentPosition: number = -1;
    private allSubs: Subscription[] = [];
    // #endregion

    // #region Methods
    public constructor(public router: Router) {
        this.allSubs.push(
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.updateHistory(event.urlAfterRedirects);
                    this.checkNavigation();
                }
            })
        );
    }

    public async ngOnInit(): Promise<void> {
        this.allSubs.push(
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.checkNavigation();
                }
            })
        );
    }

    public ngOnDestroy(): void {
        this.allSubs.forEach((s) => s.unsubscribe());
    }

    public async onClickOnBack(): Promise<void> {
        if (this.canGoBack) {
            this.currentPosition--;
            const previousUrl = this.historyStack[this.currentPosition];
            await this.router.navigateByUrl(previousUrl);
        }
    }

    public async onClickOnForward(): Promise<void> {
        if (this.canGoForward) {
            this.currentPosition++;
            const nextUrl = this.historyStack[this.currentPosition];
            await this.router.navigateByUrl(nextUrl);
        }
    }

    public canNavigateBack(): boolean {
        return this.canGoBack;
    }

    public canNavigateForward(): boolean {
        return this.canGoForward;
    }

    public onClickOnButton(button: NavigationButton): void {
        this.selectedButton = button;
        this.navigate.emit(button.link);
    }

    public isButtonActive(button: NavigationButton): boolean {
        const url = this.router.url;
        for (const r of button.activeOnRoutes) {
            if (url.startsWith(r)) return true;
        }
        return false;
    }
    // #endregion

    // #region Private Methods
    private updateHistory(url: string): void {
        if (this.currentPosition === -1 || this.historyStack[this.currentPosition] !== url) {
            this.historyStack = this.historyStack.slice(0, this.currentPosition + 1);
            this.historyStack.push(url);
            this.currentPosition = this.historyStack.length - 1;
        }
    }

    private checkNavigation(): void {
        this.canGoBack = this.currentPosition > 0;
        this.canGoForward = this.currentPosition < this.historyStack.length - 1;
    }

    // #endregion
}
