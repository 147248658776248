import { getSegmentationRepresentationByUID, getSegmentation, } from '../../../stateManagement/segmentation/segmentationState';
import { removeAnnotation } from '../../../stateManagement';
function removeContourFromElement(segmentationRepresentationUID, toolGroupId, removeFromCache = false) {
    const segmentationRepresentation = getSegmentationRepresentationByUID(toolGroupId, segmentationRepresentationUID);
    const { segmentationId } = segmentationRepresentation;
    const segmentation = getSegmentation(segmentationId);
    const { annotationUIDsMap } = segmentation.representationData.CONTOUR;
    annotationUIDsMap.forEach((annotationSet) => {
        annotationSet.forEach((annotationUID) => {
            removeAnnotation(annotationUID);
        });
    });
}
export default removeContourFromElement;
