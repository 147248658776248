import { SegmentationRepresentations } from '../../../enums';
function normalizeSegmentationInput(segmentationInput) {
    const { segmentationId, representation } = segmentationInput;
    const isContourRepresentation = representation.type === SegmentationRepresentations.Contour;
    let data = representation.data ? { ...representation.data } : null;
    data = !data && isContourRepresentation ? {} : data;
    if (!data) {
        throw new Error('Segmentation representation data may not be undefined');
    }
    if (isContourRepresentation) {
        const contourData = data;
        contourData.geometryIds = contourData.geometryIds ?? [];
        contourData.annotationUIDsMap = contourData.annotationUIDsMap ?? new Map();
    }
    return {
        segmentationId,
        cachedStats: {},
        segmentLabels: {},
        label: null,
        segmentsLocked: new Set(),
        type: representation.type,
        activeSegmentIndex: 1,
        representationData: {
            [representation.type]: {
                ...data,
            },
        },
    };
}
export default normalizeSegmentationInput;
