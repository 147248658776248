<form (submit)="onSubmit($event)" [formGroup]="formGroup" class="example-form">
    <ng-container *ngFor="let component of components">
        <ng-container [ngSwitch]="component.type">
            <!-- Label. will render a label for the current component -->
            <ng-container *ngSwitchCase="'label'">
                <div *ngIf="component['x-show']" @inOutExpandY class="{{ component.customClass }}">
                    <mat-label>{{ getComponentLabel(component.key) }}</mat-label>
                </div>
            </ng-container>

            <!-- Content -->
            <ng-container *ngSwitchCase="'content'">
                <div
                    *ngIf="component['x-show']"
                    @inOutExpandY
                    [innerHtml]="component.html"
                    class="{{ component.customClass }}"
                ></div>
            </ng-container>

            <!-- Textfield -->
            <ng-container *ngSwitchCase="'textfield'">
                <mat-form-field
                    *ngIf="component['x-show']"
                    @inOutExpandY
                    appearance="outline"
                    class="{{ component.customClass }}"
                >
                    <mat-label>{{ getComponentLabel(component.key) }}</mat-label>
                    <input
                        [formControlName]="component.key"
                        [ngClass]="{'readonly': readonly}"
                        [readonly]="readonly"
                        cdkAutosizeMaxRows="3"
                        cdkTextareaAutosize
                        matInput
                    />
                </mat-form-field>
            </ng-container>

            <!-- Number -->
            <ng-container *ngSwitchCase="'number'">
                <mat-form-field *ngIf="component['x-show']" appearance="outline" class="{{ component.customClass }}">
                    <mat-label>{{ getComponentLabel(component.key) }}</mat-label>
                    <input
                        [formControlName]="component.key"
                        [placeholder]="component.placeholder"
                        matInput
                        type="number"
                    />
                </mat-form-field>
            </ng-container>

            <!-- Password -->
            <ng-container *ngSwitchCase="'password'">
                <mat-form-field *ngIf="component['x-show']" appearance="outline" class="{{ component.customClass }}">
                    <mat-label>{{ getComponentLabel(component.key) }}</mat-label>
                    <input
                        [formControlName]="component.key"
                        [placeholder]="component.placeholder"
                        matInput
                        type="password"
                    />
                </mat-form-field>
            </ng-container>

            <!-- Textarea -->
            <ng-container *ngSwitchCase="'textarea'">
                <mat-form-field
                    *ngIf="component['x-show']"
                    @inOutExpandY
                    @inOutExpandY
                    appearance="outline"
                    class="{{ component.customClass }}"
                >
                    <mat-label>{{ getComponentLabel(component.key) }}</mat-label>
                    <textarea
                        #autosize="cdkTextareaAutosize"
                        [cdkAutosizeMinRows]="currentBreakpoint === 'handsetLandscape' ? 1 : component.rows"
                        [formControlName]="component.key"
                        [ngClass]="{'readonly': readonly}"
                        [readonly]="readonly"
                        cdkAutosizeMaxRows="10"
                        cdkTextareaAutosize
                        matInput
                        matInput
                    ></textarea>
                </mat-form-field>
            </ng-container>

            <!-- Checkbox -->
            <ng-container *ngSwitchCase="'checkbox'">
                <section *ngIf="component['x-show']" @inOutExpandY class="{{ component.customClass }}">
                    <mat-checkbox [formControlName]="component.key" matInput>
                        {{ getComponentLabel(component.key) }}
                    </mat-checkbox>
                </section>
            </ng-container>

            <!-- Select -->
            <ng-container *ngSwitchCase="'select'">
                <mat-form-field
                    *ngIf="component['x-show']"
                    @inOutExpandY
                    appearance="outline"
                    class="{{ component.customClass }}"
                >
                    <mat-label>{{ getComponentLabel(component.key) }}</mat-label>
                    <mat-select [formControlName]="component.key" matNativeControl>
                        <mat-option *ngFor="let s of component.data?.values" [value]="s.value">
                            {{ getComponentLabel(s.value) }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>

            <!-- Select boxes -->
            <ng-container *ngSwitchCase="'selectboxes'">
                <section *ngIf="component['x-show']" @inOutExpandY class="{{ component.customClass }}">
                    <h4>{{ getComponentLabel(component.key) }}</h4>
                    <p>
                        <mat-checkbox *ngFor="let s of component['values']" [formControlName]="component.key">
                            {{ getComponentLabel(s.value) }}
                        </mat-checkbox>
                    </p>
                </section>
            </ng-container>

            <!-- Radio -->
            <ng-container *ngSwitchCase="'radio'">
                <div *ngIf="component['x-show']" @inOutExpandY class="{{ component.customClass }}">
                    <label>{{ getComponentLabel(component.key) }}</label>
                    <mat-radio-group [formControlName]="component.key">
                        <mat-radio-button *ngFor="let s of component.values" [value]="s.value">
                            {{ s.label }}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </ng-container>

            <!-- Date picker -->
            <!-- Not working with time. Deactivated, using date/time instead
            <ng-container *ngSwitchCase="'datetime'">
                <mat-form-field
                    *ngIf="component['x-show']"
                    @inOutExpandY
                    appearance="outline"
                    class="{{ component.customClass }}"
                >
                    <mat-label
                    >{{ getComponentLabel(component.key) }}
                    </mat-label>
                    <input
                        [formControlName]="component.key"
                        [matDatepicker]="picker"
                        [readonly]="readonly"
                        matInput
                    />
                    <mat-datepicker-toggle
                        *ngIf="!readonly"
                        [for]="picker"
                        matIconSuffix
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </ng-container> -->

            <!-- Date and time picker -->
            <!-- TODO: Add FormGroup support a LUIC Datetime Picker -->
            <ng-container *ngSwitchCase="'datetime'">
                <!-- <luic-datetimepicker-input
                    (inputFieldValueChanged)="
                        onDateAndTimeChange($event, component)
                    "
                    *ngIf="component['x-show']"
                    @inOutExpandY
                    [lbDisable]="isComponentDisabled(component)"
                    [lbLabel]="getComponentLabel(component.key) ?? ''"
                    [lbPlaceholder]="'Date and time'"
                    [lbValue]="component['x-data']"
                ></luic-datetimepicker-input> -->
                <mat-form-field *ngIf="component['x-show']" appearance="outline">
                    <input
                        @inOutExpandY
                        [formControlName]="component.key"
                        [readonly]="readonly"
                        matInput
                        placeholder="'Date and time'"
                        type="datetime-local"
                    />
                </mat-form-field>
            </ng-container>

            <!-- Button -->
            <ng-container *ngSwitchCase="'button'">
                <button
                    (click)="onClickOnButton(component.key)"
                    *ngIf="component['x-show'] && !readonly"
                    @inOutExpandY
                    class="{{ component.customClass }}"
                    mat-flat-button
                >
                    {{ getComponentLabel(component.key) }}
                </button>
            </ng-container>

            <!-- Fieldset -->
            <ng-container *ngSwitchCase="'fieldset'">
                <app-formio-renderer
                    (formButtonClick)="onClickOnButton($event)"
                    (formSubmitted)="onSubmit($event)"
                    *ngIf="component['x-show']"
                    @inOutExpandY
                    [(data)]="data"
                    [formGroup]="formGroup"
                    [form]="component"
                    [i18n]="i18n"
                    [readonly]="readonly"
                    class="form-renderer-fieldset {{ component.customClass }}"
                ></app-formio-renderer>
            </ng-container>

            <!-- Datagrid -->
            <ng-container *ngSwitchCase="'datagrid'">
                <div *ngIf="component['x-show']" @inOutExpandY class="{{ component.customClass }}">
                    <app-formio-renderer
                        (formButtonClick)="onClickOnButton($event)"
                        (formSubmitted)="onSubmit($event)"
                        [(data)]="data"
                        [formGroup]="formGroup"
                        [form]="component"
                        [i18n]="i18n"
                        [readonly]="readonly"
                    ></app-formio-renderer>
                </div>
            </ng-container>

            <!-- Default fallback -->
            <!-- <ng-container *ngSwitchDefault>
                {{ component | json }}
            </ng-container> -->
        </ng-container>
    </ng-container>
</form>
