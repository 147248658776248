import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as fabric from 'fabric';
import { Canvas, FabricObject } from 'fabric';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatIconButton, MatMiniFabButton } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatDivider } from '@angular/material/divider';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { TooltipService } from '@babybeet/angular-tooltip';
import { MatTooltip } from '@angular/material/tooltip';
import { CanvasJson } from '@lohmann-birkner/luic/lib/components/luic-painting-tool/painting-tool.component';
import { CanvasRendererService } from '../../services/canvas-renderer.service';
import { FindingItem } from '../../med-view.component';
import * as pdfjsLib from 'pdfjs-dist';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

export interface ExtendedCanvasJson extends CanvasJson {
    originalWidth: number;
    originalHeight: number;
}

@Component({
    selector: 'app-image-doc-drawer',
    templateUrl: './image-doc-drawer.component.html',
    styleUrls: ['./image-doc-drawer.component.scss'],
    standalone: true,
    providers: [CanvasRendererService],
    imports: [
        MatIcon,
        MatIconButton,
        TranslateModule,
        MatDivider,
        MatButton,
        MatMiniFabButton,
        NgIf,
        MatTooltip,
        NgClass,
        NgStyle,
        MatProgressSpinner,
    ],
})
export class ImageDocDrawerComponent implements OnInit {
    public isDrawingMode = false;
    public isMarkingMode = false;
    public fabricSettings: any = {
        color: 'black',
        width: 4,
    };
    public areAnnotationsVisible = true;
    public isCanvasInitialized: boolean = false;
    public selectedFinding: FindingItem<any> = {} as FindingItem<any>;
    public canvasElementId: string = '';
    private fabricCanvas!: Canvas;
    private isColorPaletteTooltipOpen: boolean = false;
    private isStrokeWidthTooltipOpen: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ImageDocDrawerComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            canvasElementId: string;
            selectedFinding: FindingItem<any>;
        },
        public canvasRendererService: CanvasRendererService,
        private readonly tooltipService: TooltipService
    ) {
        pdfjsLib.GlobalWorkerOptions.workerSrc = 'assets/pdf.worker.mjs';

        this.selectedFinding = data.selectedFinding;
        this.canvasElementId = data.canvasElementId;
    }

    async ngOnInit(): Promise<void> {
        const fabricCanvas = await this.canvasRendererService.initCanvas(
            'myCanvas',
            'PaintingToolWrapper',
            this.selectedFinding,
            this.selectedFinding.data.annotations,
            true
        );

        if (fabricCanvas) {
            this.fabricCanvas = fabricCanvas;
        }
    }

    public enableTextAdding(): void {
        const overlayMessage = document.createElement('div');
        overlayMessage.innerText = 'Klicken Sie auf den Canvas, um den Text hinzuzufügen.';
        overlayMessage.style.position = 'absolute';
        overlayMessage.style.top = '50%';
        overlayMessage.style.left = '50%';
        overlayMessage.style.transform = 'translate(-50%, -50%)';
        overlayMessage.style.background = 'rgba(0, 0, 0, 0.7)';
        overlayMessage.style.color = 'white';
        overlayMessage.style.padding = '10px 20px';
        overlayMessage.style.borderRadius = '8px';
        overlayMessage.style.zIndex = '1000';
        document.body.appendChild(overlayMessage);

        const onMouseDown = (event: fabric.TEvent) => {
            event.e.preventDefault();
            event.e.stopPropagation();

            const pointer = this.fabricCanvas.getPointer(event.e);
            const x = pointer.x;
            const y = pointer.y;

            const text = new fabric.IText('', {
                left: x,
                top: y,
                fontSize: 24,
                fill: this.fabricSettings.color || 'black',
                fontFamily: 'Arial',
                editable: true,
            });

            this.fabricCanvas.add(text);
            this.fabricCanvas.setActiveObject(text);

            text.enterEditing();
            text.hiddenTextarea?.focus();

            this.fabricCanvas.renderAll();

            this.fabricCanvas.off('mouse:down', onMouseDown);
            document.body.removeChild(overlayMessage);
        };

        this.fabricCanvas.on('mouse:down', onMouseDown);
    }

    public toggleDrawingMode(): void {
        if (this.isMarkingMode) {
            this.isMarkingMode = false;
            this.fabricCanvas.isDrawingMode = false;
        }

        this.isDrawingMode = !this.isDrawingMode;
        this.fabricCanvas.freeDrawingBrush = new fabric.PencilBrush(this.fabricCanvas);
        this.fabricCanvas.isDrawingMode = this.isDrawingMode;

        if (this.isDrawingMode && this.fabricCanvas.freeDrawingBrush) {
            this.updateColor(this.fabricSettings.color);
            this.updateStrokeWitdh(this.fabricSettings.width);
        }
    }

    public updateStrokeWitdh(width: number, event?: MouseEvent, templateRefContent?: TemplateRef<any>) {
        this.fabricSettings.width = width;

        if ((this.isDrawingMode || this.isMarkingMode) && this.fabricCanvas.freeDrawingBrush) {
            this.fabricCanvas.freeDrawingBrush.width = this.fabricSettings.width;
        }

        if (event && templateRefContent) {
            this.toggleTooltip(event, templateRefContent, 'strokeWidth');
        }
    }

    public updateColor(color: string, event?: MouseEvent, templateRefContent?: TemplateRef<any>) {
        this.fabricSettings.color = color;

        if ((this.isDrawingMode || this.isMarkingMode) && this.fabricCanvas.freeDrawingBrush) {
            this.fabricCanvas.freeDrawingBrush.color = this.fabricSettings.color;
        }

        const activeObjects: FabricObject[] = this.fabricCanvas.getActiveObjects();
        if (activeObjects.length) {
            activeObjects.forEach((activeObject: FabricObject) => {
                switch (activeObject.type) {
                    case 'rect':
                    case 'circle':
                    case 'triangle':
                    case 'path':
                        if ('stroke' in activeObject) {
                            activeObject.set('stroke', this.fabricSettings.color);
                        }
                        break;

                    case 'text':
                    case 'i-text':
                        if ('fill' in activeObject) {
                            activeObject.set('fill', this.fabricSettings.color);
                        }
                        break;

                    default:
                        console.warn(`Farbänderung für Objekttyp '${activeObject.type}' nicht implementiert.`);
                }
            });

            this.fabricCanvas.renderAll();
        }

        if (event && templateRefContent) {
            this.toggleTooltip(event, templateRefContent, 'colorPalette');
        }
    }

    public toggleMarkingMode(): void {
        if (this.isDrawingMode) {
            this.isDrawingMode = false;
            this.fabricCanvas.isDrawingMode = false;
        }

        this.isMarkingMode = !this.isMarkingMode;
        this.fabricCanvas.freeDrawingBrush = new fabric.PencilBrush(this.fabricCanvas);
        this.fabricCanvas.isDrawingMode = this.isMarkingMode;

        if (this.isMarkingMode && this.fabricCanvas.freeDrawingBrush) {
            const brush = this.fabricCanvas.freeDrawingBrush;

            brush.color = 'rgba(241, 196, 15, 0.4)';
            brush.width = 20;
            brush.strokeLineCap = 'round';
        }
    }

    public addRect() {
        const rectangle = new fabric.Rect({
            left: 100,
            top: 100,
            width: 200,
            height: 200,
            fill: 'transparent',
            stroke: this.fabricSettings.color,
            strokeWidth: this.fabricSettings.width,
        });

        this.fabricCanvas.add(rectangle);
    }

    public addCircle() {
        const circle = new fabric.Circle({
            left: 150,
            top: 150,
            radius: 50,
            fill: 'transparent',
            stroke: this.fabricSettings.color,
            strokeWidth: this.fabricSettings.width,
        });

        this.fabricCanvas.add(circle);
    }

    public addArrow(): void {
        const arrow1Path = new fabric.Path(`M -100 0 L 100 0 L 70 -15 M 100 0 L 70 15`, {
            stroke: this.fabricSettings.color,
            strokeWidth: this.fabricSettings.width,
            strokeLineCap: 'round',
            strokeLineJoin: 'round',
            fill: 'transparent',
            left: 10,
            top: 40,
        });

        this.fabricCanvas.add(arrow1Path);
    }

    public onClickOnCancel() {
        this.dialogRef.close({ role: 'cancel', data: {} });
    }

    public toggleTooltip(
        event: MouseEvent,
        templateRefContent: TemplateRef<any>,
        tooltipType: 'colorPalette' | 'strokeWidth'
    ): void {
        const isCurrentlyOpen =
            (tooltipType === 'colorPalette' && this.isColorPaletteTooltipOpen) ||
            (tooltipType === 'strokeWidth' && this.isStrokeWidthTooltipOpen);

        this.hideTooltip();

        if (!isCurrentlyOpen) {
            this.showTooltip(event, templateRefContent);

            if (tooltipType === 'colorPalette') {
                this.isColorPaletteTooltipOpen = true;
            } else if (tooltipType === 'strokeWidth') {
                this.isStrokeWidthTooltipOpen = true;
            }
        }
    }

    public deleteSelectedObject() {
        const activeObject = this.fabricCanvas.getActiveObject();

        if (activeObject) {
            this.fabricCanvas.remove(activeObject);
            this.fabricCanvas.renderAll();
        }
    }

    public toggleObjectVisibility(): void {
        this.areAnnotationsVisible = !this.areAnnotationsVisible;

        this.fabricCanvas.getObjects().forEach((obj) => {
            if (obj.type !== 'image' || obj.selectable) {
                obj.visible = this.areAnnotationsVisible;
            }
        });

        this.fabricCanvas.renderAll();
    }

    public onClickOnSave(): void {
        const canvasWidth = this.fabricCanvas.getWidth();
        const canvasHeight = this.fabricCanvas.getHeight();

        const annotations: ExtendedCanvasJson = {
            version: fabric.version,
            objects: this.fabricCanvas
                .getObjects()
                .filter((obj) => obj.type !== 'image') // Bilder nicht speichern
                .map((obj) => {
                    const relativeObject = obj.toObject() as FabricObject;

                    // Relative Position und Größe berechnen
                    relativeObject.left = (obj.left || 0) / canvasWidth;
                    relativeObject.top = (obj.top || 0) / canvasHeight;

                    if (obj.type === 'rect' || obj.type === 'circle' || obj.type === 'path' || obj.type === 'itext') {
                        if (relativeObject.width) {
                            relativeObject.width = (relativeObject.width * (obj.scaleX || 1)) / canvasWidth;
                        }
                        if (relativeObject.height) {
                            relativeObject.height = (relativeObject.height * (obj.scaleY || 1)) / canvasHeight;
                        }
                    }

                    return relativeObject;
                }),
            originalWidth: canvasWidth,
            originalHeight: canvasHeight,
        };

        this.dialogRef.close({ role: 'save', data: { annotations } });
    }

    private hideTooltip(): void {
        this.tooltipService.hide();
        this.isColorPaletteTooltipOpen = false;
        this.isStrokeWidthTooltipOpen = false;
    }

    private showTooltip(event: MouseEvent, templateRefContent: TemplateRef<any>): void {
        const target = event.target as Element | null;
        if (target) {
            this.tooltipService.show(target, {
                content: templateRefContent,
                context: { $implicit: 'TemplateRef' },
                placement: 'horizontal',
                theme: 'light',
            });
        }
    }
}
