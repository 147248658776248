import vtkDataArray from '@kitware/vtk.js/Common/Core/DataArray';
import vtkImageData from '@kitware/vtk.js/Common/DataModel/ImageData';
import { BaseVolumeViewport, getEnabledElement, Enums, getEnabledElementByIds, cache, utilities, } from '@cornerstonejs/core';
import { getToolGroupForViewport } from '../../store/ToolGroupManager';
import Representations from '../../enums/SegmentationRepresentations';
import * as SegmentationState from '../../stateManagement/segmentation/segmentationState';
import { isVolumeSegmentation } from '../../tools/segmentation/strategies/utils/stackVolumeCheck';
import triggerSegmentationRender from '../../utilities/segmentation/triggerSegmentationRender';
const enable = function (element) {
    const { viewport } = getEnabledElement(element);
    if (viewport instanceof BaseVolumeViewport) {
        return;
    }
    element.addEventListener(Enums.Events.STACK_NEW_IMAGE, _imageChangeEventListener);
    element.addEventListener(Enums.Events.IMAGE_RENDERED, _imageChangeEventListener);
};
const disable = function (element) {
    element.removeEventListener(Enums.Events.STACK_NEW_IMAGE, _imageChangeEventListener);
    element.removeEventListener(Enums.Events.IMAGE_RENDERED, _imageChangeEventListener);
};
const perToolGroupManualTriggers = new Map();
function _imageChangeEventListener(evt) {
    const eventData = evt.detail;
    const { viewportId, renderingEngineId } = eventData;
    const { viewport } = getEnabledElementByIds(viewportId, renderingEngineId);
    const toolGroup = getToolGroupForViewport(viewportId, renderingEngineId);
    if (!toolGroup) {
        return;
    }
    let toolGroupSegmentationRepresentations = SegmentationState.getSegmentationRepresentations(toolGroup.id) || [];
    toolGroupSegmentationRepresentations =
        toolGroupSegmentationRepresentations.filter((representation) => representation.type === Representations.Labelmap);
    if (!toolGroupSegmentationRepresentations?.length) {
        return;
    }
    const segmentationRepresentations = {};
    toolGroupSegmentationRepresentations.forEach((representation) => {
        const segmentation = SegmentationState.getSegmentation(representation.segmentationId);
        if (!segmentation || !segmentation.representationData?.LABELMAP) {
            return;
        }
        const labelmapData = segmentation.representationData.LABELMAP;
        if (isVolumeSegmentation(labelmapData, viewport)) {
            return;
        }
        const { imageIdReferenceMap } = labelmapData;
        segmentationRepresentations[representation.segmentationRepresentationUID] =
            {
                imageIdReferenceMap,
            };
    });
    const representationList = Object.keys(segmentationRepresentations);
    const currentImageId = viewport.getCurrentImageId();
    const actors = viewport.getActors();
    const segmentationFound = actors.find((actor) => {
        if (!representationList.includes(actor.uid)) {
            return false;
        }
        return true;
    });
    if (!segmentationFound) {
        if (!perToolGroupManualTriggers.has(toolGroup.id)) {
            perToolGroupManualTriggers.set(toolGroup.id, true);
            triggerSegmentationRender(toolGroup.id);
        }
        return;
    }
    actors.forEach((actor) => {
        if (!representationList.includes(actor.uid)) {
            return;
        }
        const segmentationActor = actor.actor;
        const { imageIdReferenceMap } = segmentationRepresentations[actor.uid];
        const derivedImageId = imageIdReferenceMap.get(currentImageId);
        const segmentationImageData = segmentationActor.getMapper().getInputData();
        if (!derivedImageId) {
            if (segmentationImageData.setDerivedImage) {
                segmentationImageData.setDerivedImage(null);
                return;
            }
            const scalarArray = vtkDataArray.newInstance({
                name: 'Pixels',
                numberOfComponents: 1,
                values: new Uint8Array(segmentationImageData.getNumberOfPoints()),
            });
            const imageData = vtkImageData.newInstance();
            imageData.getPointData().setScalars(scalarArray);
            segmentationActor.getMapper().setInputData(imageData);
            return;
        }
        const derivedImage = cache.getImage(derivedImageId);
        const { dimensions, spacing, direction } = viewport.getImageDataMetadata(derivedImage);
        const currentImage = cache.getImage(currentImageId) ||
            {
                imageId: currentImageId,
            };
        const { origin: currentOrigin } = viewport.getImageDataMetadata(currentImage);
        const originToUse = currentOrigin;
        segmentationImageData.setOrigin(originToUse);
        segmentationImageData.modified();
        if (segmentationImageData.getDimensions()[0] !== dimensions[0] ||
            segmentationImageData.getDimensions()[1] !== dimensions[1]) {
            viewport.removeActors([actor.uid]);
            viewport.addImages([
                {
                    imageId: derivedImageId,
                    actorUID: actor.uid,
                    callback: ({ imageActor }) => {
                        const scalarArray = vtkDataArray.newInstance({
                            name: 'Pixels',
                            numberOfComponents: 1,
                            values: [...derivedImage.getPixelData()],
                        });
                        const imageData = vtkImageData.newInstance();
                        imageData.setDimensions(dimensions[0], dimensions[1], 1);
                        imageData.setSpacing(spacing);
                        imageData.setDirection(direction);
                        imageData.setOrigin(originToUse);
                        imageData.getPointData().setScalars(scalarArray);
                        imageActor.getMapper().setInputData(imageData);
                    },
                },
            ]);
            triggerSegmentationRender(toolGroup.id);
            return;
        }
        if (segmentationImageData.setDerivedImage) {
            segmentationImageData.setDerivedImage(derivedImage);
        }
        else {
            utilities.updateVTKImageDataWithCornerstoneImage(segmentationImageData, derivedImage);
        }
        viewport.render();
        if (evt.type === Enums.Events.IMAGE_RENDERED) {
            viewport.element.removeEventListener(Enums.Events.IMAGE_RENDERED, _imageChangeEventListener);
        }
    });
}
export default {
    enable,
    disable,
};
